import React, { useEffect, useRef, useState } from "react";
import classNames from 'classnames';
import { v4 as uuidv4 } from "uuid";
import ContentEditable from "react-contenteditable";


const VariableInsertButton = props => (
    <div className="btn-group" role="group">
      <div className="dropdown">
        <button
          className="btn btn-secondary dropdown-toggle"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-plus"></i>
          &nbsp;Insérer variable
        </button>
        <ul
          className="dropdown-menu py-2 pb-3 overflow-auto"
          style={{ width: "220px", maxHeight: "400px" }}
        >
          {props.fields.map((category, index) => {
            return (
              <React.Fragment key={category[0]}>
                <div>
                  <li>
                    <h6 className="dropdown-header">{category[0]}</h6>
                  </li>
                  {category[1].map((field, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => props.onFieldClick(field)}
                        className="pointer dropdown-item"
                      >
                        {field.display_name}
                      </li>
                    );
                  })}
                </div>
                {index !== props.fields.length - 1 && (
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
  </div>
)


const FilenamePatternInput = (props) => {
  const inputRef = useRef(null);
  const [errorSpecialCharacter, setErrorSpecialCharacter] = useState(false);
  const [errorNoChip, setErrorNoChip] = useState(false);
  const [filenamePatternHtml, setFilenamePatternHtml] = useState("");

  useEffect(() => {
    initializeHtmlFromPropsPattern();
  }, []);

  useEffect(() => {
    const container = document.querySelectorAll(".remove-chip");
    const handleRemoveChip = (e) => {
      const chipId = e.target.closest(".chip").id;
      removeChips(chipId);
    };
    container.forEach((removeChipBtn) => {
      removeChipBtn.addEventListener("click", handleRemoveChip);
    });
    return () => {
      container.forEach((removeChipBtn) => {
        removeChipBtn.removeEventListener("click", handleRemoveChip);
      });
    };
  }, [filenamePatternHtml]);

  const chipHtml = (field, id) => {
    if (field) {
      return `
      <span class="chip element" id="_${id}" data-name=${field.id}>
          <span class="badge rounded-pill chip-content" contentEditable=false>
              ${field.display_name}
              <span class="ps-2 pointer remove-chip close-cross" >&times;</span>
          </span>
      </span>
  `;
    }
  };

  const initializeHtmlFromPropsPattern = () => {
    setErrorNoChip(false)
    const filenamePattern = props.filenamePattern || props.filenameDefault;
    const regex = /[^[]+|(\[#.*?#\])/g;
    const resultArray = filenamePattern.match(regex);
    let filenamePatternHtmlString = `<span class="element">&nbsp;</span>`;
    if (resultArray) {
      for (const element of resultArray) {
        if (element[0] === "[") {
          let fieldToAdd;
          props.filenameFields.forEach((category) => {
            category[1].forEach((field) => {
              if (element === `[#${field.id}#]`) {
                fieldToAdd = field;
              }
            });
          });
          filenamePatternHtmlString = filenamePatternHtmlString.concat(
            chipHtml(fieldToAdd, uuidv4()) +
              '<span class="element">&nbsp;</span>'
          );
        } else if (element === '_') {
          filenamePatternHtmlString = filenamePatternHtmlString.concat(
              '<span class="element">&nbsp_&nbsp</span>'
          );
        } else if (element.includes('_')) {
          if (element[0] === '_'){
            filenamePatternHtmlString = filenamePatternHtmlString.concat(
              '<span class="element">&nbsp_&nbsp</span>')
          }
          const splitedElement = element.split('_')
          for (const word of splitedElement) {
            if (word.trim() !== "" ) {
              filenamePatternHtmlString = filenamePatternHtmlString.concat(
                `<span class="element">${word}</span>` +
                  '<span class="element">&nbsp_&nbsp</span>'
              );
            }
          }
        } else {
          filenamePatternHtmlString = filenamePatternHtmlString.concat(
            `<span class="element">${element}</span>` +
              '<span class="element">&nbsp;</span>'
          );
        }
      }
    }
    // Remove '_' if it's the last element
    const container = document.createElement("div");
    container.innerHTML = filenamePatternHtmlString;
    let elements = container.querySelectorAll('span.element');
    elements.forEach((element) => {
      if (element.innerHTML.trim() === '&nbsp;_&nbsp;' && element === element.parentNode.lastElementChild) {
          element.remove();
      }
  });
    setFilenamePatternHtml(container.innerHTML);
  };

  const isChipNode = (node) =>
    node.nodeName === "SPAN" && node.classList.contains("chip");

  const transformHtmlToFilenamePattern = () => {
    const container = document.createElement("div");
    container.innerHTML = filenamePatternHtml;
    const listElements = container.childNodes;
    let concatString = "";
    listElements.forEach((element) => {
      if (isChipNode(element) && element.textContent !== "") {
        const field_name = element.getAttribute("data-name");
        concatString = concatString.concat(`[#${field_name}#]`);
      } else {
        concatString = concatString.concat(element.textContent);
      }
    });
    concatString = concatString.replace(/\s+/g, "").replace(/×/g, "");
    return concatString;
  };

  const transformHtmlToExample = () => {
    const container = document.createElement("div");
    container.innerHTML = filenamePatternHtml;
    const listElements = container.childNodes;
    let concatString = "";
    listElements.forEach((element) => {
      if (isChipNode(element)) {
        let nameFoundInCategory = false;
        const nameToCheck = element.getAttribute("data-name");
        props.filenameFields.forEach((category) => {
          category[1].forEach((field) => {
            if (nameToCheck === field.id) {
              nameFoundInCategory = true;
              if (!field.value) {
                concatString = concatString.concat(
                  `[#${nameToCheck}#]`.toLowerCase()
                );
              } else {
                concatString = concatString.concat(field.value);
                return;
              }
            }
          });
          if (nameFoundInCategory) return;
        });
      } else {
        concatString = concatString.concat(element.textContent);
      }
    });
    concatString = concatString.replace(/\s+/g, "").replace(/×/g, "").slice(0,60);
    return `${concatString}.pdf`;
  };

  const addChips = (field) => {
    setFilenamePatternHtml((prev) =>{
      setErrorNoChip(false)
      const container = document.createElement('div')
      container.innerHTML = prev
      if (!container.textContent.trim() || container.textContent[container.textContent.trim().length - 1] === "_") {
        return (prev.concat(
          '<span class="element">&nbsp;</span>' +
            chipHtml(field, uuidv4()) +
            '<span class="element">&nbsp;</span>'
        ))
      }
      return (prev.concat(
        '<span class="element">_&nbsp;</span>' +
          chipHtml(field, uuidv4()) +
          '<span class="element">&nbsp;</span>'
      ))}
    );
  };

  const removeChips = (id) => {
    const container = document.createElement("div");
    container.innerHTML = filenamePatternHtml;
    const chipToRemove = container.querySelector(`#${id}`);
    if (chipToRemove){
      const previousSibling = chipToRemove.previousElementSibling
      if (previousSibling.textContent.trim() === "_") {
        previousSibling.remove()
      }
      chipToRemove.remove();
      setErrorNoChip(container.querySelector('.chip') === null)
      setFilenamePatternHtml(container.innerHTML);
    }
  };

  const handleChange = (ev) => {
    const container = document.createElement("div");
    container.innerHTML = ev.target.value;
    setErrorNoChip(container.querySelector('.chip') === null)
    const regex = /[^a-zA-Z0-9\s_\-×é°]/
    setErrorSpecialCharacter(regex.test(container.textContent));
    setFilenamePatternHtml(ev.target.value.replace("<br>", ""));
  };

  return (
    <div className="d-flex flex-column gap-2" style={{ width: "797px" }}>
      <div className="d-flex justify-content-between">
        <h3>{props.title}</h3>
        <div className="d-flex align-items-center">
          <div className="btn-group me-2" role="group">
            <div
              className="text-secondary pointer"
              onClick={initializeHtmlFromPropsPattern}
            >
              <i className="fa fa-undo"></i> {trans.t("Réinitialiser")}
            </div>
          </div>
          <VariableInsertButton
            fields={props.filenameFields}
            onFieldClick={addChips}
          />
        </div>
      </div>
      <div className="input input-lg">
        <input
          name={props.nameInput}
          type="text"
          value={transformHtmlToFilenamePattern()}
          hidden
        />

        <ContentEditable
          html={filenamePatternHtml}
          className={classNames(
            "form-control w-100 text-nowrap text-truncate p-1",
            {"is-invalid": errorNoChip || errorSpecialCharacter}
          )}
          onChange={handleChange}
          ref={inputRef}
          spellCheck={false}
        />
      </div>
      {errorSpecialCharacter && (
        <p className="text-danger mb-0">
          {trans.t("Les caractères spéciaux ne sont pas autorisés dans ce champ.")}
        </p>
      )}
      {errorNoChip && (
        <p className="text-danger mb-0">
          {trans.t("Vous devez sélectionner au moins une variable")}
        </p>
      )}
      <div className="d-flex justify-content-between" style={{fontSize: "11px"}}>
        <p>{trans.t("Exemple")} : {transformHtmlToExample()}</p>
        <p>{trans.t("60 caractères max.")}</p>
      </div>
    </div>
  );
};

export default FilenamePatternInput;
