'use strict';

var $ = require('jquery');
import React from 'react';
import { createRoot } from 'react-dom/client';

import { attach_react_helper, getModalWrapper } from './react-base.jsx';

// Load tools commonly used to build them in the file
import FilterModal from 'orfeo/configuration/static/configuration/js/Filters.jsx';
import SearchBar from 'orfeo/search/static/SearchBar.jsx';
import SearchPageView from 'orfeo/search/static/SearchPageView.jsx';
// Object activities & custom fields
import AttachmentsManager from 'orfeo/attachments/jsx/AttachmentsManager.jsx';
import CustomFieldsManager from 'orfeo/custom_fields/jsx/CustomFieldsManager.jsx';
import CustomFieldsList from 'orfeo/custom_fields/jsx/CustomFieldsList.jsx';
import CustomFieldModalForm from 'orfeo/custom_fields/jsx/CustomFieldModalForm.jsx';
import EntityTagForm from 'orfeo/entities/jsx/EntityTagForm.jsx';
import EntityCategoryForm from 'orfeo/reference/static/reference/EntityCategoryForm.jsx'
import LinkedContactManager from 'orfeo/entities/jsx/LinkedContactManager.jsx';
import S3FilesUploader from './S3FilesUploader.jsx';

import CalendarListManager from 'orfeo/calendar/jsx/calendar/CalendarListManager.jsx';
import IcalListManager from 'orfeo/calendar/jsx/calendar/IcalListManager.jsx'

import NotesManager from './react-notes.jsx';
import TaskList from 'orfeo/activities/tasks/static/tasks/js/react-tasks.jsx';
import TasksListManager from 'orfeo/activities/tasks/static/tasks/js/TasksListManager.jsx';
import AccoutingDocumentAddModal from 'orfeo/administration/jsx/accountingdocument/DocumentAddModal.jsx';
import onOpenAddRulesetForm from 'orfeo/administration/jsx/ruleset/AddRulesetForm.jsx';

import EmailManager from 'orfeo/activities/mails/static/mails/EmailManager.jsx';
import EmailInbox from 'orfeo/activities/mails/static/mails/EmailInbox.jsx';
import EmailComposerModal from 'orfeo/activities/mails/static/mails/EmailComposerModal.jsx';

import NotificationsCenter from 'orfeo/activities/notifications/jsx/NotificationsCenter.jsx';
import SnackbarManager from 'orfeo/activities/notifications/jsx/SnackbarManager.jsx';
import UserProfilePhoto from 'orfeo/accounts/jsx/utils/UserProfilePhoto'

import FilenamePatternInput from 'orfeo/configuration/static/configuration/js/FilenamePatternInput.jsx'

import { GroupedOptionsSelector } from 'orfeo_common/Fields';

// No-op function for components that do not need any props
let noop = () => ({});
document.addEventListener('DOMContentLoaded', function () {
    const object_infos_node = document.getElementById('object-infos');
    let object_ct, object_pk, object_is_template;
    if(!!object_infos_node) {
        object_ct = object_infos_node.getAttribute('data-object-ct');
        object_pk = object_infos_node.getAttribute('data-object-pk');
        object_is_template = !!object_infos_node.dataset.objectTemplate;
    }

    document.querySelectorAll('.filters-form').forEach(function(filter_node) {
        var uid = filter_node.dataset.uid;
        var filter_options = window[`FILTER_OPTIONS_${uid}`];
        let filters_root = createRoot(filter_node);
        var loadFilterModal = function () {
            filters_root.render(
                <FilterModal
                    filterable_fields={filter_options.filterableFields}
                    initials={filter_options.initialValue}
                    operators={filter_options.operators}
                    disabled={filter_options.disabled}
                />
            );
        };
        // Depending on which page we are, filters are not initialized the same way
        var modal = filter_node.closest('.dynamic-list-modal');
        if (modal !== null) {
            $(modal).on('show.bs.modal', loadFilterModal);
        } else {
            loadFilterModal();
        }
    })

    // Notes & tasks
    attach_react_helper(NotesManager, 'notes-list', node => ({'object_id': object_pk, 'content_type': object_ct}));
    attach_react_helper(TaskList, 'tasks-list', node => ({
        'object_id': object_pk,
        'content_type': object_ct,
        'is_template': object_is_template,
        'account_types': node.getAttribute('data-account-types') || 'standard', // some pages may allow to assign tasks to guest profiles
    }));
    attach_react_helper(TasksListManager, 'tasks-list-page', () => ({'profiles': PROFILES, 'current_profile': PROFILE_PK}));
    // Search
    attach_react_helper(SearchBar, 'header-search', noop);
    attach_react_helper(SearchPageView, 'search-page-view', noop);

    var cf_node = document.getElementById('custom-fields-edition');
    if(cf_node !== null) {
        let col_size = cf_node.getAttribute('data-column-sizes');
        if(col_size)
            col_size = col_size.split(',').map(x => Number(x));

        const customFieldsroot = createRoot(cf_node);
        customFieldsroot.render(
            <CustomFieldsManager
                obj={window[cf_node.getAttribute('data-obj')]}
                backend_url={cf_node.getAttribute('data-backend-url')}
                isReadOnly={cf_node.getAttribute('data-readonly') == 'true'}
                label_column_sizes={col_size || undefined}
                renderLinesOnly={cf_node.getAttribute('data-render-lines-only') == 'true'}
            />
        );
    }

    // Custom field settings
    attach_react_helper(CustomFieldsList, 'custom-fields-list', () => ({
        'customFields': window.CUSTOM_FIELDS,
        'proxyChoices': window['PROXY_CHOICES'] || null,
        'sectionChoices': window['SECTION_CHOICES'] || null,
        'excludeAlwaysDisplayedField': window.EXCLUDE_ALWAYS_DISPLAYED_FIELD,
    }));
    $('.add-customfield-btn').bind('click', function (ev) {
        const customFieldsAddRoot = createRoot(getModalWrapper());
        customFieldsAddRoot.render(
            <CustomFieldModalForm
                obj={null}
                proxyChoices={window['PROXY_CHOICES'] || null}
                sectionChoices={window['SECTION_CHOICES'] || null}
                excludeAlwaysDisplayedField={window.EXCLUDE_ALWAYS_DISPLAYED_FIELD}
                onModalClose={() => customFieldsAddRoot.unmount()}
            />
        );
    })

    attach_react_helper(EntityTagForm, 'entity-tag-form', () => ({'obj': OBJ}));
    attach_react_helper(EntityCategoryForm, 'entity-category-form', () => ({'obj': OBJ}));

    document.querySelectorAll('.filename-pattern-input').forEach((input) => {
        const filenameFields = window[input.getAttribute('data-fields')]
        const filenamePattern = window[input.getAttribute('data-filename')]
        const filenameDefault = window[input.getAttribute('data-filename-default')]
        const nameInput = window[input.getAttribute('data-name-input')]
        const title = window[input.getAttribute('data-title')]
        attach_react_helper(FilenamePatternInput, input, () => (
            {
                'filenameFields': filenameFields,
                'filenamePattern': filenamePattern,
                'filenameDefault': filenameDefault,
                'nameInput': nameInput,
                'title': title
            }
        ));
    })
    attach_react_helper(FilenamePatternInput, 'filename-contract-input', () => (
        {'filenameFields': FILENAME_FIELDS, 'filenameContractPattern': FILENAME_CONTRACT_PATTERN, type: 'contract'}
    ));
    attach_react_helper(FilenamePatternInput, 'filename-amandment-input', () => (
        {'filenameFields': FILENAME_FIELDS, 'filenameAmendmentPattern': FILENAME_AMENDMENT_PATTERN, type: 'amendment'}
    ));
    attach_react_helper(FilenamePatternInput, 'filename-engagement-input', () => (
        {'filenameFields': FILENAME_FIELDS, 'filenameEngagementPattern': FILENAME_ENGAGEMENT_PATTERN, type: 'engagement'}
    ));
    // attach_react_helper(FilenamePatternInput, 'filename-general-roadmap-input', () => (
    //     {'filenameFields': FILENAME_FIELDS, 'filenameGeneralRoadmapPattern': FILENAME_GENERAL_ROADMAP_PATTERN, type: 'roadmap-general'}
    // ));
    // attach_react_helper(FilenamePatternInput, 'filename-participant-roadmap-input', () => (
    // {'filenameFields': FILENAME_FIELDS, 'filenameParticipantRoadmapPattern': FILENAME_PARTICIPANT_ROADMAP_PATTERN, type: 'roadmap-participant'}
    // ));

    // Calendar related nodes
    attach_react_helper(CalendarListManager, 'calendars-list-manager', function (node) {
        return {
            'calendars': CALENDARS,
            'can_create_internals': CAN_CREATE_INTERNALS,
        };
    });
    attach_react_helper(IcalListManager, 'ical-list-manager', node => ({'ical_links': ICAL_LINKS, 'ical_add_url': ADD_URL}));

    // E-mails
    attach_react_helper(EmailManager, 'emails-list', () => {
        return {
            'default_entity_selected': window.ENTITY.pk,
            'fetchUrl': window.EMAILS_FETCH_URL,
            'initialInfosUrl': window.EMAILS_INITIAL_INFO_URL,
            'composerInfosUrl': "/mails/api/composer/init/",
            'profile_pk': window.PROFILE_PK,
        };
    });
    attach_react_helper(EmailInbox, 'email-inbox', noop);
    attach_react_helper(S3FilesUploader, 's3-files-form', () => ({'aws_data': AWS_DATA, 'attachments': ATTACHMENTS}));

    let attachmentsNode = document.getElementById('inner-attachments');
    if(attachmentsNode !== null) {
        attach_react_helper(
            AttachmentsManager,
            attachmentsNode,
            () => ({
                content_type_id: object_ct,
                object_id: object_pk,
                sharable: attachmentsNode.dataset.sharable == "true",
                shareTitle: attachmentsNode.dataset.shareTitle,
            })
        );
    }

    // Notifications
    if(window['NOTIFICATIONS'] !== undefined) {
        attach_react_helper(NotificationsCenter, 'notifications-center-wrapper', () => ({
            'notifications': NOTIFICATIONS,
            'configUrl': NOTIFICATIONS_CONFIG_URL,
        }))
    }
    if(window['SNACKBARS'] !== undefined) {
        attach_react_helper(SnackbarManager, 'snackbars-container', () => ({
            'snackbars': SNACKBARS,
        }));
    }

    // User avatar
    if(window['USER_PROFILE'] !== undefined) {
        attach_react_helper(UserProfilePhoto, 'user-profile-photo', () => ({
            size: '32px',
            tooltip: null,
            profile: USER_PROFILE,
        }))
    }

    // Others
    attach_react_helper(LinkedContactManager, 'linked-contacts', function (node) {
        return {
            'backend_url': node.getAttribute('data-backend'),
            'parent_id': node.getAttribute('data-parent-id'),
            'isReadOnly': node.getAttribute('data-readonly') == 'true',
            'block_title': node.getAttribute('data-block-title') || 'Contacts liés',
            'parentType': node.getAttribute('data-parent-type'),
            'roles': window['ROLES'] || null
        }
    });

    $('.send-mail-action').bind("click", function (ev) {
        var emailModalRoot = createRoot(getModalWrapper());
        let link = ev.target.closest('a');
        let attachments = [];
        if(link.getAttribute('data-attachment')) {
            let url = link.getAttribute('data-attachment');
            attachments.push({
                'source': 'url',
                'mime_type': 'application/pdf',
                'uploaded': true,
                'filename': url.split('/').pop(),
                'url': url,
                'deletable': false,
            });
        }

        emailModalRoot.render(
            <EmailComposerModal
                attachments={attachments}
                entity={link.getAttribute('data-entity')}
                redirection={link.getAttribute('data-redirection')}
                closeModal={() => emailModalRoot.unmount()}
            />
        );
    });

    $('.add-accountingdocument-btn').bind('click', function (ev) {
        let accountingDocumentModalRoot = createRoot(getModalWrapper());
        let link = ev.target;
        accountingDocumentModalRoot.render(
            <AccoutingDocumentAddModal
                type={link.dataset.type}
                org_profile={link.dataset.orgProfile}
                entity={link.dataset.entity}
                project_contract={link.dataset.project_contract}
                onModalClose={() => accountingDocumentModalRoot.unmount()}
            />
        )
    })

    const getProps = node => ({
        name: node.dataset.name,
        required: node.dataset.required,
        value: node.dataset.value ? node.dataset.value.split(',') : [],
        backendUrl: node.dataset.url,
        verboseName: node.dataset.verbose_name,
        verboseNamePlural: node.dataset.verbose_name_plural,
        availableLabel: node.dataset.available_label,
        selectedLabel: node.dataset.selected_label,
    })
    for (let node of document.querySelectorAll('.grouped_options_selector')) {
        attach_react_helper(GroupedOptionsSelector, node, getProps);
    }

    document.querySelectorAll('.add-ruleset-btn').forEach(
        el => el.addEventListener('click', onOpenAddRulesetForm)
    );

});
