import React, { useId, useState, useRef, useEffect } from "react";
import classNames from 'classnames';
import { ErrorText } from "orfeo_common/FormUtils"
import { MagnifyingGlass } from "@phosphor-icons/react";

import { Button } from './Buttons.jsx';

const GenericInput = (props) => {
    const {label, name, errors, className} = props

    return <>
        <label className={`w-100 ${className}`} name={name}>
            {label && <span>{label}</span>}
            {props.children}
        </label>

        <ErrorText errors={errors} field={name} />
    </>
}

/**
 * Generic Input to get Text
 */
const TextInput = (props) => {
    const {label, value, onChange, name, errors} = props

    return (
    <GenericInput label={label} name={name} errors={errors}>
        <input type="text" className="form-control"
            autoFocus value={value}
            onChange={ev => onChange(ev.target.value)}
        />
    </GenericInput>
    )
}

const Searchbar = (props) => {
    const {
        value = '',
        onChange = () => {},
        name,
        disabled = false,
        retractable = false,
        placeholder = trans.t("Rechercher...")
    } = props

    const [retracted, setRetracted] = useState(retractable);
    const inputId = useId();
    const inputRef = useRef();

    useEffect(() => {
        // Autofocus on un-retract
        if(retractable && !retracted){
            inputRef.current.focus();
        }
    }, [retracted]);

    if(retracted){
        return (
            <Button onClick={() => setRetracted(false)} mainClass="btn-light">
                <MagnifyingGlass size={16} /> {trans.t("Rechercher")}
            </Button>
        )
    }

    return (
        <div className="oo-searchbar">
            <label htmlFor={inputId} className="search-icon"><MagnifyingGlass/></label>
            <input
                id={inputId} type="text" className="form-control"
                ref={inputRef}
                placeholder={placeholder} aria-label="Rechercher"
                value={value} name={name} disabled={disabled} onChange={ev => onChange(ev.target.value)}
                onBlur={() => (retractable && !value) && setRetracted(true)}
            />
        </div>
    )
}

/**
 * Generic checkbox component
 */
const Checkbox = props => {
    const {
        checked, 
        onChecked,
        className,
        label = '',
        labelClass = '',
        onClick,
    } = props
    
    /**
     *  JSX
     */

    const checkbox = (
        <input type="checkbox" className={`${className}`}
            checked={checked}
            onChange={ev => onChecked?.(ev.target.checked)}
            onClick={props.onClick}
        />
    )

    return label == '' ? checkbox : (
        <label className="w-100">
            {checkbox}
            <span className={classNames("ms-1", labelClass)}>{label}</span>
        </label>
    )
}

export { GenericInput, TextInput, Searchbar, Checkbox }
