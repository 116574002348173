import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
    AjaxNotification,
    ColorBlock,
    GenericListStoreFactory,
    useListOrdering
} from 'orfeo_common/react-base.jsx';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { ColorPicker } from 'orfeo_common/Fields';
import Tooltip from 'orfeo_common/Tooltip.jsx';
var CalendarsStore = GenericListStoreFactory();
CalendarsStore.sort_key = 'order_index';

const STATUS_CSS_CLASSES = {
    'enqueuded': 'info',
    'running': 'success',
    'ok': 'success',
    'error': 'danger',
    'paused': 'secondary'
}

const CalendarForm = props => {
    const {values, onChange, onKeyUp, onSubmit} = FormUtils.useForm(
        props.obj || {'name': '', 'color': null, 'notes': ''},
        handleSave,
        props.onClose
    );
    const [errors, setErrors] = FormUtils.useFormErrors();

    function handleSave() {
        let post_data = {
            'name': values.name,
            'color.pk': values.color ? values.color.pk : null,
            'notes': values.notes
        };
        if(!props.obj) {
            post_data['type'] = props.cal_type;
            if(props.cal_type == 'external')
                post_data['ical_link'] = values.ical_link;
        }
        let url = '/backend/calendar/', method = 'POST';
        if(props.obj) {
            url += props.obj.pk + '/';
            method = 'PATCH'
        }

        let notif_id = AjaxNotification.show(trans.t('Sauvegarde...'));
        $.ajax({
            url: url, method: method, data: post_data
        }).success(
            (data, status) => {
                CalendarsStore.update(data);
                props.onClose();
            }
        ).error(
            (data, status) => setErrors(data.responseJSON)
        ).always(
            () => AjaxNotification.hide(notif_id)
        );
    }

    let cal_type = props.obj ? props.obj.type : props.cal_type;
    let delete_btn;
    if(props.obj && props.obj.delete_url) {
        if(props.obj.type == 'external' || CalendarsStore.nb_internals > 1) {
            delete_btn = (
                <a href={props.obj.delete_url} className="btn btn-danger">{trans.t("Supprimer")}</a>
            );
        }
        else {
            delete_btn = (
                <Tooltip title={trans.t("Il n'est pas possible de supprimer cet élément, car vous devez conserver au minimum un calendrier.")}
                         placement="right">
                    <button type="button" className="btn btn-danger disabled">{trans.t("Supprimer")}</button>
                </Tooltip>
            );
        }
    }

    return (
        <form className="form react-inline-form" onSubmit={onSubmit} onKeyUp={onKeyUp}>
            <div className="row">
                <div className={"col-xs-9" + FormUtils.getCSSError(errors, 'name')}>
                    <input
                        type="text" name="name" className="form-control" autoFocus
                        value={values.name} readOnly={props.obj && props.obj.type == 'orfeo'}
                        onChange={onChange} placeholder={trans.t("Nom du calendrier")}
                    />
                    <FormUtils.ErrorText errors={errors} field='name' />
                </div>
                <div className={"col-xs-3" + FormUtils.getCSSError(errors, 'color')}>
                    <ColorPicker
                        value={values.color}
                        required
                        onChange={color => onChange('color', color)}
                    />
                    <FormUtils.ErrorText errors={errors} field='color' />
                </div>
            </div>

            {cal_type == 'external' &&
            <div className="row">
                <div className={"col-xs-12" + FormUtils.getCSSError(errors, 'ical_link')}>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-link"></i></div>
                        <input
                            type="text" name="ical_link" className="form-control"
                            value={values.ical_link} onChange={onChange}
                            placeholder={trans.t("Lien ical")} disabled={!!props.obj}
                        />
                    </div>
                    <FormUtils.ErrorText errors={errors} field='ical_link' />
                </div>
            </div>
            }
            <div className="row">
                <div className={"col-xs-12" + FormUtils.getCSSError(errors, 'notes')}>
                    <textarea
                        name="notes" className="form-control" placeholder={trans.t("Notes")}
                        value={values.notes} onChange={onChange}
                    ></textarea>
                    <FormUtils.ErrorText errors={errors} field='notes' />
                </div>
            </div>

            <div className="actions text-end">
                <div className="float-start">{delete_btn}</div>
                <a className="btn btn-outline-secondary" onClick={() => props.onClose()}>{trans.t("Annuler")}</a>&nbsp;
                <button type="submit" className="btn btn-primary">{trans.t("Sauvegarder")}</button>
            </div>
        </form>
    );
}

const CalendarListHeader = props => {
    return (
        <thead>
            <tr>
                <th>{/* Drag'n Drop handler */}</th>
                <th>{trans.t("Nom")}</th>
                <th>{trans.t("Notes")}</th>
                {props.type == 'external' &&  <th>{trans.t("Synchronisation")}</th>}
                <th>{trans.t("Actions")}</th>
            </tr>
        </thead>
    )
}

const CalendarLine = props => {
    // Should handle both external calendar and internal calendar
    const [editing, setEditing] = useState(false)
    if (editing) {
        return (
            <tr key={props.key}>
                <td colSpan="5">
                    <CalendarForm
                        obj={props.calendar}
                        onClose={() => setEditing(null)}
                    />
                </td>
            </tr>
        )
    }

    let actions = [
        <a className="action-link" role="button" onClick={ev => ev.preventDefault()}>
            {trans.t("Supprimer le calendrier")}
        </a>
    ]

    return (
        <tr
            key={props.calendar.pk + '' + props.calendar.order_index}
            data-id={props.calendar.pk}
            onClick={e => (props.can_edit_line ? setEditing(true) : null)}
            className={props.can_edit_line ? "inline-editable" : "disabled"}
        >
            <td
                className="reorder-icon"
                onClick={e => e.stopPropagation()}
            >
                <i className="fa fa-bars"></i>
            </td>
            <td>
                {props.calendar.color && <ColorBlock color={props.calendar.color} show_label={false} />}
                {props.calendar.name || props.calendar.title}
            </td>
            <td>{props.calendar.notes}</td>
            {props.calendar.type == 'external' &&
                <td>
                <div>
                    <span className={"badge bg-" + (STATUS_CSS_CLASSES[props.calendar.sync_status[0]])}>
                        {props.calendar.sync_status[1]}
                    </span>&nbsp;<small>{
                        props.calendar.last_sync_date && moment(props.calendar.last_sync_date).locale('fr').fromNow()
                    }</small>
                </div>
                </td>
            }
            <td>
                {actions.reduce((acc, elem) => [acc, ' | ', elem])}
            </td>
        </tr>
    );
}

const CalendarListBody = props => {
    // Get DOM ref to allow re-ordering of the list
    const listRef = useListOrdering(
        '/backend/calendar/change_order/',
        null,
        newOrder => {
            // Update related order index in our local store
            var lines = CalendarsStore.getList();
            for(var i = 0; i < lines.length; i++) {
                lines[i].order_index = newOrder.indexOf(lines[i].pk.toString())
            }
            CalendarsStore.setList(lines);
        },
        true
    );

    return (
        <tbody ref={listRef}>
        {props.calendars.map((calendar, idx) => (
            <CalendarLine key={`calendar-${calendar.pk}`}
                can_edit_line={props.can_create_internals || calendar.type == 'external'}
                calendar={calendar}
            />
        ))}
        </tbody>
    )
}

const CalendarTable = props => {
    const [adding, setAdding] = useState(null);

    let can_add = props.can_create_internals || props.cal_type == "external";
    return (
        <React.Fragment>
            <table className="table">
            <CalendarListHeader type={props.cal_type}/>
            <CalendarListBody calendars={props.calendars} can_create_internals={props.can_create_internals} />
            </table>
            {adding ?
                <CalendarForm cal_type={props.cal_type} onClose={() => setAdding(null)}/>
                :
                <p>
                    {can_add &&
                        <a role="button" className="action-link" onClick={() => setAdding(true)}>
                            {props.cal_type == "internal"? trans.t("Ajouter calendrier") : trans.t("Lier calendrier externe")}
                        </a>
                    }
                </p>
            }
        </React.Fragment>
    )
}

const CalendarListManager = props => {
    const [editing, setEditing] = useState(null);
    const [calendars, setCalendars] = useState(props.calendars);

    let internalCalendars = calendars.filter(c => c.type == 'internal');
    let externalCalendars = calendars.filter(c => c.type == 'external');
    // Initialize storage
    useEffect(() => {
        CalendarsStore.addOnListChange(lst => {
            CalendarsStore.nb_internals = lst.filter(x => x.type == 'internal').length;
            setCalendars(lst);
        });
        CalendarsStore.setList(props.calendars);
    }, []);

    return (
        <div>
            {props.can_create_internals &&
            <>
                <h4>{trans.t("Calendriers internes")}</h4>
                <CalendarTable
                    cal_type="internal"
                    can_create_internals={props.can_create_internals}
                    calendars={internalCalendars}
                />
            </>}

            <h4>{trans.t("Calendriers externes")}</h4>
            <CalendarTable cal_type="external" can_create_internals={props.can_create_internals}
                calendars={externalCalendars} />
        </div>
    );
}


export default CalendarListManager;
