/**
 * E-mail form used in Orfeo
 */
'use strict';

import React from 'react';
import PropTypes from 'prop-types';
var createReactClass = require('create-react-class');
import { GenericListStoreFactory } from 'orfeo_common/react-base.jsx';

var FilesStore = GenericListStoreFactory();
FilesStore.identifier = 'filename';
var FILE_UUID = 1000;

var FileLine = createReactClass({

    getInitialState: function() {
        return {
            'uploading': false,
            'progress': null,
        }
    },

    uploadFile: function () {
        var xhr = new window.XMLHttpRequest();
        this.setState({'uploading': true, 'xhr': xhr});
        var s3_url = `https://s3-eu-west-1.amazonaws.com/${this.props.aws_data.bucket}/`;

        var event = new CustomEvent("S3AttachementUploadStarted");
        document.dispatchEvent(event);

        $.ajax({
            url: s3_url, data: this.props.file.data_to_upload,
            type: 'POST', processData: false, cache: false, contentType: false,

            xhr: function() {
                var myXhr = xhr;
                if(myXhr.upload){ // if upload property exists
                    myXhr.upload.addEventListener('progress', function (ev) {
                        this.setState({'progress': parseInt(ev.loaded / ev.total * 100)})
                    }.bind(this), false); // progressbar
                }
                return myXhr;
            }.bind(this),
            // Ajax events
            success: function(data, textStatus, jqXHR) {
                var file_url = decodeURIComponent(jqXHR.getResponseHeader('Location').replace(/\+/g, ' '));
                var key = file_url.split(s3_url)[1];
                this.onFileUploaded(file_url, key);
                this.setState({'uploading': false, 'progress': 100});

                document.dispatchEvent(new CustomEvent("S3AttachementUploadFinished"));
            }.bind(this),
            error: function(ev) {
                document.dispatchEvent(new CustomEvent("S3AttachementUploadFinished"));
            },
        }, 'json');
    },

    onFileUploaded: function (url, key) {
        // Remove temp line in favor on the uploaded one
        FilesStore.delete(this.props.file.filename);
        FilesStore.add({'key': key, 'url': url, 'filename': key.replace(/^.*[\\\/]/, ''),});
    },

    removeFile: function () {
        if(this.state.uploading) {
            this.state.xhr.abort();
        }
        FilesStore.delete(this.props.file.filename);
    },

    componentWillMount: function() {
        if(this.props.file.data_to_upload) {
            this.uploadFile();
        }
    },

    render: function () {
        var verbose_filename;
        if(this.state.uploading) {
            verbose_filename = this.props.file.filename;
        }
        else {
            verbose_filename = (
                <a href={this.props.file.url} target="_blank">{this.props.file.filename}</a>
            );
        }

        return (
            <li>
                <input type="hidden" name="attachments" value={this.props.file.url} />
                <div style={{'display': 'inline-block'}}>{verbose_filename}</div>

                {this.state.uploading &&
                <div style={{'display': 'inline-block', 'marginLeft': '10px'}}>
                    <div className="progress" style={{'width': '125px', 'height': '14px'}}>
                      <div className="progress-bar progress-bar-striped active" role="progressbar"
                           style={{'width': this.state.progress + '%', 'lineHeight': '14px'}}
                           aria-valuenow={this.state.progress} aria-valuemin="0" aria-valuemax="100">
                           <small>{this.state.progress} %</small>
                      </div>
                    </div>
                </div>}
                <a href="javascript:void(0)" onClick={this.removeFile} style={{'display': 'inline-block', 'margin': '0 7px'}}>
                    <i className="fa fa-trash"></i>
                </a>
            </li>
        );
    }
});


var S3FilesManager = createReactClass({

    propsTypes: {
        aws_data: PropTypes.object.isRequired
    },

    getInitialState: function() {
        FilesStore.setList(this.props.attachments);
        FilesStore.addOnListChange(lst => this.setState({'files': lst}))

        return {'files': FilesStore.getList()};
    },

    componentWillMount: function() {
        document.addEventListener('InitializeAttachmentList', function (ev) {
            var lst = ev.detail;
            for(var i = 0; i < lst.length; i++) {
                FilesStore.add(lst[i]);
            }
        });
    },

    addForm: function () {
        this.refs.file_input.click();
    },

    onFileSubmit: function (ev) {
        ev.preventDefault();

        // Construct form data by hand since we can't embed a form inside a form.
        var formdata = new FormData();
        var inputs = this.refs.upload_form.getElementsByTagName('input');
        for(var i = 0; i < inputs.length; i++) {
            if(inputs[i].name == 'file')
                formdata.append(inputs[i].name, inputs[i].files[0]);
            else
                formdata.append(inputs[i].name, inputs[i].value);
        }
        FilesStore.add({'key': FILE_UUID++, 'data_to_upload': formdata});
    },

    render: function () {
        return (
            <div style={{'marginBottom': '20px'}}>
                <div ref="upload_form" style={{'display': 'none'}}>
                  <input type="hidden" name="key" value={this.props.aws_data.directory + "${filename}"} />
                  <input type="hidden" name="AWSAccessKeyId" value={this.props.aws_data.access_key} />
                  <input type="hidden" name="acl" value="private" />
                  <input type="hidden" name="policy" value={this.props.aws_data.policy} />
                  <input type="hidden" name="signature" value={this.props.aws_data.signature} />

                  <input name="file" type="file" ref="file_input" onChange={this.onFileSubmit} />
                </div>

                <ul className="s3-attachments-list">
                {this.state.files.map(
                    f => <FileLine file={f} key={f.filename} aws_data={this.props.aws_data}/>
                )}
                </ul>

                <a className="action-link" onClick={this.addForm}>{trans.t("Ajouter une pièce jointe")}</a>
            </div>
        )
    }
});


export default S3FilesManager;
