import React, { useState } from 'react';

/**
 * Capitalize a string, by uppercasing the first letter.
 *
 * Example:
 * >>> capitalize('michael')
 * "Michael"
 */
const capitalize = str => !!str ? str[0].toUpperCase() + str.slice(1) : '';

/**
 * Truncate a string, adding '...' after if it's longer than the given length
 *
 * Example:
 * >>> truncate('michael is eating apples', 12)
 * "michael is e…"
 * >>> truncate('michael is eating apples', 12, true)
 * "michael is…"
 */
const truncate = (str, maxLength, useWordBoundary) => {
    if(str.length <= maxLength)
        return str;

    let truncatedString = str.slice(0, maxLength);
    if(useWordBoundary)
        truncatedString = truncatedString.slice(0, truncatedString.lastIndexOf(' '))

    return truncatedString + '…';
}

const truncate_middle = (value, maxcount) => {
    if (!value) {
        return "";
    }

    if (value.length < maxcount) {
        return value;
    }

    let nb_chars_per_half = (maxcount - 2) / 2;
    return (
        value.substring(0, nb_chars_per_half)
        + " […] "
        + value.substring(value.length - nb_chars_per_half, value.length)
    )
}

/**
 * Append `plural_suffix` to the text, if the `nb` argument is greater than 1.
 * By default, plural_suffix is equal to "s". This method does not handle yet
 * complex plural such as pony/ponies, foot/feet...
 *
 * Example:
 * >>> pluralize('table', 2)
 * "tables"
 * >>> pluralize('child', 1, 'ren')
 * "child"
 * >>> pluralize('child', 3, 'ren')
 * "children"
 */
const pluralize = function (text, nb, plural_suffix) {
    plural_suffix = plural_suffix || 's';

    if(nb > 1) {
        return text + plural_suffix;
    }

    return text;
}

/**
 * Convert URLs in plain text into clickable links.
 *
 * Example:
 * >>> urlize('This is my own website: https://example.net. Check it out!')
 * "This is my own website: <a href="https://example.net" target="_blank">https://example.net</a>. Check it out!"
 *
 * Part of the link shown in text might be truncated to ease reading
 */
const urlize = (content, linkShownLength = 80) => {
    let partsLength = Math.ceil(linkShownLength * 0.9 / 2);
    return content.replace(
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig, function (x) {
            let x_displayed = x;
            if(x.length > linkShownLength)
                x_displayed = x.substr(0, partsLength) + '\u200B[...]' + x.substr(-partsLength);

            return '<a href="'+x+'" target="_blank">'+x_displayed+'</a>';
        }
    );
}

/**
 * Returns a React component with URLs in plain text converted into clickable links
 * using urlize
 */
const UrlizedContentWithoutEventPropagation = ({content, ...props}) => {
    let urlized = urlize(content);
    return (
        <p
            onClick={e => {
                if(e.target.nodeName == 'A')
                    e.stopPropagation();
            }}
            dangerouslySetInnerHTML={{__html: urlized}}
            {...props}
        ></p>
    )
}

/**
 * Display a toggle link to expand/collapse a long text block
**/
const TruncableContent = props => {
    const [collapsed, setCollapsed] = useState(true);

    const max_lines = props.max_lines || 4;
    const max_chars = props.max_chars || 330;

    // If the state has already been set to false, it means it has been truncable at a moment
    let content = props.content;
    let truncable = !collapsed;
    if(collapsed) {
        if(content.split('\n').length > max_lines) {
            truncable = true;
            content = content.split('\n', max_lines).join('\n').trim() + '...';
        }
        if(content.length > max_chars) {
            truncable = true;
            content = content.substring(0, max_chars).trim() + '...';
        }
    }

    return (
        <div>
            <div style={{'whiteSpace': 'pre-line'}}>{content}</div>
            {truncable &&
                <a
                    className="text-muted"
                    style={{'padding': '12px 10px', 'margin': '-10px', 'display': 'block', 'fontSize': '0.9em'}}
                    onClick={ev => {
                        ev.stopPropagation();
                        setCollapsed(!collapsed);
                    }}
                >
                    {collapsed ?
                        <span>{trans.t("Voir la suite")} <i className="fa fa-caret-down"></i></span>
                    :
                        <span>{trans.t("Replier")} <i className="fa fa-caret-up"></i></span>
                    }
                </a>
            }
        </div>
    )
}

/**
 * Checks if a number's decimal part is round to its n-th digit
 * @param {string|number} number the number or its string representation
 * @param {int} n size of the decimal part you want to check the roundness of
 * @returns {boolean} whether the number is round or not
 */
const isDecimalRound = (number, n=2) => {
    if (typeof number !== 'string' && !(number instanceof String))
    {
        number = String(number)
    }

    let regex = new RegExp(String.raw`\.\d{${n},}[1-9]`)
    return !number.match(regex)
}

/**
 * Case insensitive method to check if a string contains another one
 * @param {string} aString the string to apply the includes to
 * @param {string} substring
 * @returns {boolean}
 */
const caseInsensitiveIncludes = (aString, substring, position = 0) => (
    aString.toLowerCase().includes(substring.toLowerCase(), position)
)

// Placeholder functions in order to ease thea ctual translation when we will implement it
const getTranslatedString = s => s;

const getPluralTranslatedString = (singular, plural, number) => number == 1 ? singular : plural;

export {
    capitalize,
    getTranslatedString,
    getPluralTranslatedString,
    pluralize,
    TruncableContent,
    truncate,
    isDecimalRound,
    caseInsensitiveIncludes,
    truncate_middle,
    urlize,
    UrlizedContentWithoutEventPropagation
}
