import React, { useState, useEffect, useRef, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import Async from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import Select, {components} from 'react-select';
import Creatable from 'react-select/creatable';
import fetch from 'isomorphic-fetch';
var createReactClass = require('create-react-class');
import moment from 'moment';
import { Editor as TinyMCE } from '@tinymce/tinymce-react';
import classNames from 'classnames';

import {DotsThreeOutlineVertical} from '@phosphor-icons/react'

import { AjaxNotification, BloodhoundFactory, fetchCsrfWrapper, isObject } from './react-base.jsx';
import { isReadOnly } from './FormUtils.jsx';
import {getModalWrapper, pluralize} from "orfeo_common/react-base.jsx";
import Tooltip from 'orfeo_common/Tooltip.jsx';
import { jsonDurationToTimeInput } from 'orfeo/calendar/jsx/planning/_Utils.jsx';
import EntityAddForm from 'orfeo/entities/jsx/EntityAddForm.jsx';

let lastId = 0;
function _getUID() {
    return lastId++;
}


const CalendarInput = createReactClass({

    getDefaultProps: function() {
        return {
            multiple: false,
            required: false,
            defaultViewDate: 'today'
        };
    },

    getInitialState: function() {
        return {
            '_id': 'datepicker-' + _getUID(),
            'value': this.props.defaultValue
                ? this.props.multiple
                    ? this.props.defaultValue.map((x) => moment(x, "YYYY-MM-DD").format("DD/MM/YYYY"))
                    : moment(this.props.defaultValue, "YYYY-MM-DD").format("DD/MM/YYYY")
                : '',
            'typing': false,
        };
    },

    /**
     * Update both member of the relation to allow validation of date range
     * (one date of beginning and one of end, that can't be inverted)
     */
    setRelation: function (other_role, other_component) {
        if(other_role == 'end') {
            var start_input = this.refs.date_input;
            var end_input = other_component.refs.date_input;

            // Initialize date limits for current datepicker
            $(end_input).datepicker('setStartDate', $(start_input).datepicker('getDate'));

            // Add change handlers
            $(start_input).on('changeDate', function (ev) {
                let new_date = $(this).datepicker('getDate');
                let other = $(end_input);
                other.datepicker('setStartDate', new_date);
                if(other.val() == '')
                    other.datepicker('setDate', new_date);
                else if(moment(new_date).isAfter(moment(other_component.getValue())))
                    other.datepicker('setDate', new_date);
            });
        }
    },

    // Register a callback for the current datepicker. The user of the widget
    // should not manipulate the datepicker library, so we wrap everything
    // and send the date manually to the callback.
    onChangeDate: function (callback) {
        $(this.refs.date_input).on('changeDate', function () {
            callback($(this).datepicker('getDate'));
        });
    },

    getValue: function () {
        if(this.props.multiple && this.state.value) {
            return this.state.value.map(
                val => moment(val, 'DD/MM/YYYY').format('YYYY-MM-DD')
            );
        }
        else if(this.refs.date_input && this.refs.date_input.value != '')
            return moment(this.refs.date_input.value, 'DD/MM/YYYY').format('YYYY-MM-DD');

        return '';
    },

    // Programmatically set date
    setValue: function(date) {
        $(this.refs.date_input).datepicker('setUTCDate', new Date(date));
    },

    /**
     * Quick buttons format:
     * offset: Number of days to add to the current date (Obligatory)
     * label: displayed text (default: J+offset)
     * colspan: css colspan (default: 1)
     */
    renderQuickButton: function(quick_button) {

        if(!quick_button.offset){
            return "";
        }

        return `
            <th
                ${quick_button.colspan ? "colspan=" + quick_button.colspan : ""}
                data-offset=${quick_button.offset}
            >
                ${quick_button.label || "J+"+quick_button.offset}
            </th>
        `;
    },

    componentDidMount: function() {
        var opts = {
            format: 'dd/mm/yyyy',
            language: 'fr',
            weekStart: '1',
            clearBtn: !this.props.required,
            autoclose: !this.props.multiple,
            multidate: this.props.multiple,
            todayHighlight: true
        };

        let shown_once = false;

        if(this.props.minStartDate) {
            opts['defaultViewDate'] = {
                'year': this.props.minStartDate.year(),
                'month': this.props.minStartDate.month()
            }
            opts['startDate'] = this.props.minStartDate.format('DD/MM/YYYY');
        }
        if(this.props.maxEndDate)
            opts['endDate'] = this.props.maxEndDate.format('DD/MM/YYYY');

        let that = this;
        $(this.refs.date_input)
         .datepicker(opts)
         .on('keydown', function (ev) {
            that.setState({'typing': true})
         })
         .on('focusout', function (ev) {
            if (!that.state.typing)
                return
            // Allow manual editing of the date with the keyboard
            let date_regexp = /^\s*(\d{1,2})\/(\d{1,2})\/(\d{4}|\d{2})\s*$/;
            let match = date_regexp.exec(ev.target.value);
            if (match) {
                let parts = ev.target.value.split('/');
                let year = parts[parts.length - 1]
                if (year.length == 2) {
                    parts[parts.length - 1] = '20' + year;
                }
                let date_repr = parts.join('/');
                that.setState({'value': date_repr});
                $(this).datepicker('setDate', date_repr);
            }
            that.setState({'typing': false});
         })
         .on('changeDate',
            function (datepicker) {
                if(datepicker.dates.length == 0 && this.state.value.length > 0) {
                    this.setState({'value': []});
                }
                else if(this.state.value.length != datepicker.dates.length && this.refs.date_input) {
                    this.setState({
                        'value': $(this.refs.date_input).datepicker('getFormattedDate').split(',')
                    });
                }
            }.bind(this)
        )
        .on('show',
            function(ev){
                if(!shown_once && that.props.quickButtons){
                    shown_once = true;
                    let datepicker = this;
                    $('.datepicker tfoot').append(`
                        <tr class="quick-buttons">
                            ${that.props.quickButtons.map(quick_button => that.renderQuickButton(quick_button))}
                        </tr>
                    `);
                    $('.datepicker tfoot .quick-buttons th').on('click', function(ev){
                        let d = new Date();
                        d.setDate(d.getDate() + Number(ev.target.dataset.offset));
                        $(datepicker).datepicker('setDate', d);

                        if(opts["autoclose"]){
                            $(datepicker).datepicker('hide');
                        }

                    });
                }
            }
        )

        if (this.props.multiple && this.props.minimal) {
            $(this.refs.date_input).datepicker('setDates', this.state.value);
        }
        var self = this;
        if(this.props.onChange) {
            $(this.refs.date_input).on('change', function() {
                let val = $(this).val();
                let new_date = val ? moment(val, 'DD/MM/YYYY') : null;
                if (val && self.props.utc) {
                    new_date = moment.utc(val, 'DD/MM/YYYY');
                }
                self.props.onChange(val ? new_date : null);
            });
        }

        if(this.props.autoFocus) {
            $(this.refs.date_input).focus();
        }
    },

    render: function () {
        var placeholder = this.props.placeholder;
        if (placeholder == null) {
            placeholder = trans.t('Date');
        }

        let input_css = "form-control " + (this.props.input_css !== undefined ? this.props.input_css : "input-sm");
        let field = (
            <input
                className={input_css} ref="date_input"
                autoComplete="off"
                name={this.props.field_name || null}
                id={this.state._id}
                placeholder={placeholder}
                defaultValue={this.state.value}
                disabled={this.props.disabled}
                required={this.props.required}
            />
        )
        if(this.props.no_icon_addon)
            return field;

        else if (this.props.multiple && this.props.minimal)
            return (
                <React.Fragment>
                    {Array.isArray(this.state.value) &&
                        this.state.value.length + " " + pluralize("jour", this.state.value.length)}
                    &nbsp;
                    <button
                        className="btn btn-xs btn-secondary"
                        type="button"
                        disabled={this.props.disabled}
                        name={this.props.field_name || null}
                        id={this.state._id}
                        value={this.state.value}
                        required={this.props.required}
                        ref="date_input"
                    >
                        <i className="fa fa-calendar"></i>
                    </button>
                </React.Fragment>
            );

        return (
            <div className="input-group">
                <div
                    className="input-group-text" htmlFor={this.state._id}
                >
                    <i className="fa fa-calendar"></i>
                    {this.props.multiple &&
                        <sub>{(Array.isArray(this.state.value) && this.state.value.length > 0) ?
                            <span> &times;{this.state.value.length}</span>
                        :
                            " "
                        }</sub>
                    }
                </div>
                {field}
            </div>
        );
    }
});

const CalendarPeriodInput = (props) => {
    useEffect(() => {
        $(props.calendar_ref.current).datepicker({
            format: "dd/mm/yyyy",
            language: "fr",
        });
        if (props.start_input_ref.current && props.end_input_ref.current) {
            props.start_input_ref.current.setRelation("end", props.end_input_ref.current);
        }
    }, []);
    return (
        <div className="input-group input-daterange" ref={props.calendar_ref}>
            <Trans>
                <div className="input-group-text" style={{borderLeftWidth: "1px"}}>
                    Du
                </div>
                <CalendarInput
                    ref={props.start_input_ref}
                    placeholder={trans.t("Date de début")}
                    defaultValue={props.defaultStart}
                    onChange={props.onChangeStart}
                    no_icon_addon
                />
                <div className="input-group-text">au</div>
                <CalendarInput
                    ref={props.end_input_ref}
                    placeholder={trans.t("Date de fin")}
                    defaultValue={props.defaultEnd}
                    onChange={props.onChangeEnd}
                    no_icon_addon
                />
            </Trans>
        </div>
    );
};


// Try to match hour, a possible separator, then minutes (optional)
const time_regex = /^\s*(0?[0-9]|1[0-9]|2[0-3])(\s*\D?\s*([0-5][0-9])?)?\s*$/;
const TimeInput = createReactClass({

    propTypes: {
        'defaultValue': PropTypes.string,
        'placeholder': PropTypes.string,
        'tabIndex': PropTypes.string,
        'onInputLeave': PropTypes.func,
        'onKeyUp': PropTypes.func
    },

    getInitialState: function () {
        var initial_value = this.props.value || this.props.defaultValue || "";
        if(initial_value.length == 8)
            initial_value = initial_value.slice(0, 5) // Removes unwanted seconds

        return {'value': initial_value, 'has_error': false};
    },

    getValue: function () {
        return this.state.value;
    },

    // In case of props-controlled value
    componentDidUpdate(prevProps, prevState) {
        if(this.props.value !== undefined && this.props.value != prevProps.value)
            this.setValue(this.props.value);
    },

    setValue: function (value) {
        if(value){
            value = jsonDurationToTimeInput(value);
        }

        this.setState({'value': value});
    },

    onInputChange: function (ev) {
        this.setState({'value': ev.target.value});
        if(this.props.onChange)
            this.props.onChange(ev.target.value)
    },

    unifyFormat: function() {
        if(this.state.value == "") {
            this.setState({'has_error': false});
            return;
        }

        var matches = time_regex.exec(this.state.value);
        if(matches === null || matches[1] === undefined) {
            this.setState({'has_error': true});
            return;
        }
        // Normalize input
        var hours = matches[1], minutes = matches[3] || "00";
        if(hours.length == 1)    hours = "0" + hours;
        if(minutes.length == 1)  minutes = "0" + minutes;

        this.setState(
            {'has_error': false, 'value': hours + ':' + minutes},
            () => {
                if(this.props.onChange) {
                    this.props.onChange(this.state.value)
                }
                if(this.props.onBlur) {
                    setTimeout(() => { this.props.onBlur() }, 0)
                }
            }
        );
    },

    onKeyDown(ev) {
        if(ev.key == 'Enter') {
            this.unifyFormat();
        }
    },

    onInputLeave: function() {
        this.unifyFormat();
    },

    render: function () {
        return (
            <input
                type={this.props.type || "text"}
                name={this.props.name}
                className={"form-control input-sm" + (this.state.has_error ? " is-invalid" : "")}
                maxLength="7" list="hours" ref="time_input"
                onBlur={this.onInputLeave} onChange={this.onInputChange} value={this.state.value}
                placeholder={this.props.placeholder || "Heure"} onKeyUp={this.props.onKeyUp || null}
                onKeyDown={this.onKeyDown} disabled={this.props.disabled} autoFocus={this.props.autoFocus}
                style={this.props.style}
            />
        );
    }
});


/**
 * Field suggesting the user with existing entry but do not create any link with it
 *
 * onChange handler given in parameter should be a function with (field_name, value)
 * as arguments.
 */
const SuggestionTypeaheadField = props => {
    const field = useRef();

    useEffect(() => {
        const suggestions = BloodhoundFactory(props.backend_url);

        let input = $(field.current);
        input.typeahead(
            {
                hint: true, items: 10, highlight: true, minLength: 1,
            },
            {
                source: suggestions,
                name: 'suggestions',
                display: props.objectRepresentation || props.displayKey || 'name',
                limit: props.resultsLimit || 20,
            }
        );

        // Manually set the focus because typeahead doesn't naturally handle it
        if(props.autoFocus) {
            input.focus();
        }

        input.on('typeahead:selected', (evt, item) => {
            if(item) {
                props.onChange(props.name, item[props.displayKey]);
            }
            return "";
        });
    }, []);

    return (
        <input
            type="text" className={props.className || "form-control input-sm"}
            value={props.value} name={props.name}
            ref={field} placeholder={props.placeholder}
            onKeyUp={props.onKeyUp} onBlur={props.onBlur}
            onChange={ev => props.onChange(props.name, ev.target.value)}
            disabled={props.disabled}
        />
    );
}

const TypeaheadField = createReactClass({

    propTypes: {
        'placeholder': PropTypes.string,
        'show_add': PropTypes.bool,
        'onAddClick': PropTypes.func,
        'onKeyUp': PropTypes.func,
        'defaultObject': PropTypes.object,
        'suggestions': PropTypes.func.isRequired
    },

    getDefaultProps: function() {
        return {
            'onAddClick': null,
            'placeholder': '',
            'displayKey': 'name',
            'customTemplate': null
        };
    },

    getInitialState: function() {
        return {'obj_selected': this.props.defaultObject};
    },

    componentDidMount: function () {
        var data_options = {
            source: this.props.suggestions,
            name: 'suggestions',
            display: this.props.objectRepresentation || this.props.displayKey || 'name',
            limit: 30,
        };
        if(this.props.customTemplate !== null) {
            data_options['templates'] = {
                'suggestion': this.props.customTemplate || null
            }
        }

        var input = $(this.refs.field);
        input.typeahead({hint: true, items: 10, highlight: true, minLength: 1}, data_options);
        input.on('typeahead:selected', function(evt, item) {
            this.setState({'obj_selected': item});
            if(this.props.onChange)
                this.props.onChange(item, this);
        }.bind(this));

        if(this.props.autoFocus)
            this.focus();
    },

    onChange: function (ev) {
        if(ev.target.value.trim() == '') {
            this.setState({'obj_selected': null});
            if(this.props.onChange)
                this.props.onChange(null);
        }
    },

    getValue: function() {
        return this.state.obj_selected || {};
    },

    getTextSubmitted: function () {
        return this.refs.field.value;
    },

    // Used to refresh the component on new item for example
    setValue: function(value) {
        this.setState({'obj_selected': value});
        $(this.refs.field).typeahead('val', (value !== null) ? value[this.props.displayKey] : '');
    },

    focus: function () {
        this.refs.field.focus();
    },

    render: function () {
        var defaultRepr;
        if(this.state.obj_selected) {
            if(this.props.objectRepresentation)
                defaultRepr = this.props.objectRepresentation(this.state.obj_selected)
            else
                defaultRepr = this.state.obj_selected[this.props.displayKey];
        }

        let className = this.props.className || "form-control input-sm";
        var input = (
            <input
                type="text" ref="field" className={className} onKeyUp={this.props.onKeyUp}
                placeholder={this.props.placeholder} tabIndex={this.props.tabIndex} onChange={this.onChange}
                defaultValue={defaultRepr} name={this.props.name} autoFocus={this.props.autoFocus}
            />
        );

        if(this.props.show_add) {
            return (
                <div className="input-group">
                    {this.props.pre_addon &&
                        <span className="input-group-text">{this.props.pre_addon}</span>
                    }
                    <label style={{'flex': '1'}}>{input}</label>
                    <div className="input-group-text add-sign" onClick={this.props.onAddClick}>
                        <i className="fa fa-plus"></i>
                    </div>
                </div>
            );
        }
        return <div>{input}</div>;
    }
});

var YESNO_ID = 42;
const YesNoChoice = props => {
    const [uniqId, _setUniqId] = useState(() => ('yesno' + (YESNO_ID++)));

    const onChange = ev => {
        let new_val = (ev.target.name == 'yes');
        if(ev.target.id == uniqId + "_none") {
            new_val = null;
        }
        else {
            new_val = (ev.target.id == uniqId + "_yes");
        }

        props.onChange(new_val);
    }

    return (
        <div>
            <label htmlFor={uniqId + "_yes"}>
                <input type="radio" id={uniqId + "_yes"} onChange={onChange}
                       checked={props.value == true} />
                &nbsp;{trans.t("Oui")}
            </label>
            <label htmlFor={uniqId + "_no"} style={{'marginLeft': '35px'}}>
                <input type="radio" id={uniqId + "_no"} onChange={onChange}
                       checked={props.value == false} />
                &nbsp;{trans.t("Non")}
            </label>
            {props.nullable &&
            <label htmlFor={uniqId + "_none"} style={{'marginLeft': '35px'}}>
                <input type="radio" id={uniqId + "_none"} onChange={onChange}
                       checked={props.value == null} />
                &nbsp;{trans.t("Non renseigné")}
            </label>}
        </div>
    );
}


var _fks_registry = {};
const ForeignKeyField = createReactClass({

    propTypes: {
        'show_add': PropTypes.bool,
        'onAddClick': PropTypes.func,
        'onKeyUp': PropTypes.func,
    },

    getInitialState: function() {
        return {
            'value': this.props.defaultValue ? this.props.defaultValue.pk : '',
            'elements': this.props.options || _fks_registry[this.props.backend_url] || (this.props.defaultValue && [this.props.defaultValue]) || [],
        };
    },

    getDefaultProps: function() {
        return {
            'onChange': null,
            'objectRepresentation': null,
            'required': false,
            'blank_choice_label': '',
            'input_css': 'input-sm',
        };
    },

    getValue: function () {
        if(this.props.multiple)
            return this.state.value;

        if(this.props.required && !this.state.value)
            return this.state.elements[0].pk;

        return this.state.value;
    },

    setValue: function(value) {
        // Check if the value is in the list of elements, otherwise with need to add it
        var pks = this.state.elements.filter(function (elem) { return elem.pk });
        var new_state = {'value': value.pk || ''};
        if(pks.indexOf(value.pk) === -1) {
            var new_elements = this.state.elements.slice();
            new_elements.push(value);
            new_state['elements'] = new_elements;
            _fks_registry[this.props.backend_url] = new_elements;
        }
        this.setState(new_state);
    },

    onValueChange: function (ev) {
        this.setState({'value': ev.target.value});
        if(this.props.onHTMLChange)
            this.props.onHTMLChange(ev);

        if(this.props.onChange != null) {
            // Get item selected
            var elements = this.state.elements.filter(x => x.pk == ev.target.value);
            this.props.onChange(ev.target.value, elements[0]);
        }
    },

    getObjectRepresentation: function (obj) {
        // Useful when an object does not have .name attr
        if(this.props.objectRepresentation !== null)
            return this.props.objectRepresentation(obj);

        return obj.name;
    },

    componentDidMount: function() {
        // If data already loadup, set default to first line
        if(_fks_registry[this.props.backend_url] && this.props.required && !this.state.value) {
            var first_line = _fks_registry[this.props.backend_url][0];
            this.setState({'value': first_line.pk});
            if(this.props.onChange)
                this.props.onChange(first_line.pk, first_line);
        }
    },

    componentWillMount: function() {
        if(!this.props.disabled && this.props.backend_url && _fks_registry[this.props.backend_url] === undefined) {
            $.ajax(
                {url: this.props.backend_url, method: "GET"}
            ).success(function(data, status, headers, config) {
                _fks_registry[this.props.backend_url] = data;

                var new_state = {'elements': data};
                if(this.props.required && !this.state.value) {
                    new_state['value'] = data[0].pk;
                    if(this.props.onChange)
                        this.props.onChange(data[0].pk, data[0]);
                }

                this.setState(new_state);
            }.bind(this)).error(
                (data, status) => {
                    if(data.status != 0)
                        alert(trans.t("Une erreur s'est produite lors de la récupération des éléments."));
                }
            );
        }
    },

    focus: function() {
        this.refs.select_input.focus();
    },

    render: function () {
        // In case a default value is given but not in the loaded list, we might need to add an extra <option> tag
        let additional_option;
        if(
            this.props.allowValueOutOfList
            && this.props.defaultValue
            && this.state.elements.filter(x => x.pk == this.state.value).length == 0
        )
            additional_option = (
                <option value={this.props.defaultValue.pk}>{this.getObjectRepresentation(this.props.defaultValue)}</option>
            );

        var input = (
            <select
                className={"form-select " + this.props.input_css} style={{'width': '100%'}}
                ref="select_input" disabled={!!this.props.disabled}
                key={'slct'+this.state.elements.length} value={this.state.value} onChange={this.onValueChange}
            >
                {(!this.props.multiple && !this.props.required) && <option value="">{this.props.blank_choice_label}</option>}
                {this.state.elements.map(
                    item => <option value={item.pk} key={'o'+item.pk}>{this.getObjectRepresentation(item)}</option>
                )}
                {additional_option}
            </select>
        );

        if(this.props.show_add) {
            return (
                <div className="input-group">
                    <div style={{'flex': '1 1 0'}}>{input}</div>
                    <div className={"input-group-text add-sign" + (this.props.disabled ? " disabled": "")}
                         onClick={!this.props.disabled ? this.props.onAddClick : undefined}>
                        <i className="fa fa-plus"></i>
                    </div>
                </div>
            );
        }
        return input;
    }
});


const OrderableValueContainer = props => {
    const containerRef = useRef();

    useEffect(() => {
        const dom = $(containerRef.current);

        dom.sortable({
            items: ' .draggable-multi-value',
            tolerance: 'pointer',
            start: (event, ui) => {
                document.body.style.cursor = 'move';
            },
            stop: (event, ui) => {
                const order = dom.sortable("toArray", {attribute: 'data-id'});
                dom.sortable("cancel");
                document.body.style.cursor = 'default';

                props.setValue(
                    props.getValue()
                        .slice()
                        .sort((a, b) => (
                            order.indexOf(a.pk.toString()) - order.indexOf(b.pk.toString()))
                        )
                );
            }
        })

        return () => dom.sortable('destroy');
    }, []);

    return (
        <div ref={containerRef}>
            <components.ValueContainer {...props} />
        </div>
    );
}

const OrderableMultiValue = props => {
    return (
        <div className="draggable-multi-value" data-id={props.data.pk}>
            <components.MultiValue {...props} />
        </div>
    )
}

const SELECT_INPUT_STYLES = {
    control: (provided, state) => ({...provided,
        minHeight: undefined,
        lineHeight: "1.5", // Equivalent to bootstrap inputs height
        padding: ".31rem .1rem",
    }),
    valueContainer: (provided, state) => ({...provided, padding: "0 8px"}),
    multiValueLabel: (provided, state) => ({...provided, padding: undefined,}),
    input: (provided, state) => ({...provided, margin: "0 2px", paddingTop: 0}),
    indicatorSeparator: (provided, state) => ({display: "none"}),
    clearIndicator: (provided, state) => ({...provided, padding: "0 2px"}),
    dropdownIndicator: (provided, state) => ({...provided, padding: "0 2px"}),
    menu: (provided, state) => ({...provided, marginTop: "1px", zIndex: 100}),
    placeholder: (provided, state) => ({...provided, whiteSpace: 'nowrap'}),
    menuPortal: (provided, state) => ({...provided, zIndex: 9999 }),
}

/**
 * Abstraction of react-select, used to select foreign objects inside
 * a form.
 */
const SelectInput = props => {
    const valueKey = props.optionValueKey || 'pk';
    const labelKey = props.labelKey || 'name';

    /**
     * Get object option value whenever possible.
     * - if the value is already an object, keep as it,
     * - if it's an integer, infer it as a PK and retrieve it from the JSON loaded
     * - also handle multiple field
     */
    const _getOptionValue = (json, val) => {
        // For a multiple value apply the same logic but on each item
        if(Array.isArray(val)) {
            return val.map(x => _getOptionValue(json, x));
        }

        // If no data is loaded, return the raw item, we won't be able to transform it anyway
        if (json.length == 0 || isObject(val)) {
            return val;
        }

        if(val && typeof val !== "number" && !props.options)
            console.error("Value expected to be a primary key, received:", val)

        // Let non-strict equality to allow passing PKs as string, like "1"
        let option = json.filter(x => x[valueKey] == val);
        return option.length == 1 ? option[0] : null;
    }

    const [loadedOptions, setLoadedOptions] = useState(props.options || []);
    const [_value, _setValue] = useState(props.defaultValue);
    const [xhrController, setXhrController] = useState(null)
    const input = useRef();

    const initialLoad = props.initialLoad === undefined ? true : props.initialLoad;
    const className = props.className === undefined ? "size-sm" : props.className
    const value = _getOptionValue(loadedOptions, props.value || _value)

    useEffect(() => {
        if(props.options){
            setLoadedOptions(props.options);
        }
    }, [props.options]);

    const setValue = val => {
        _setValue(val);
        if (props.onChange){
            props.onChange(val);
        }
    }
    const onInputChange = (value, action) => {
        setValue(value);

        if (props.onClear && action.action === "clear") {
            props.onClear();
        }
    }

    const onKeyUp = ev => ev.stopPropagation();

    const getBackendData = url => {
        let controller;
        if (xhrController) {
            xhrController.abort();
        }

        try {
            controller = new AbortController();
        } catch(err) {
            // Make a fake object so browsers not supporting it does not fail on it
            controller = {'signal': null, abort: function() {}};
        }
        setXhrController(controller)

        // Pass the initial value to the XHR if it's set as Number, so it will be available
        // in the result and we will be able to retrieve the label through _getOptionValue
        let initial_val = '';
        if(Array.isArray(value) && Number.isInteger(value[0])) {
            initial_val = value.join(',');
        }
        else if(Number.isInteger(value))
            initial_val = value;

        return fetch(url, {
            headers: {
                'Accept': 'application/json',
                'X-Orfeo-Source': 'SelectInput',
                'X-Orfeo-Value': initial_val,
                'X-Requested-With': 'XMLHttpRequest'
            },
            credentials: "same-origin",
            signal: controller.signal
        }).then(
            response => response.json()
        ).then(
            json => {
                // Handle pagination results naively
                if(json['results'] !== undefined && json['count'] !== undefined) {
                    json = json['results'];
                }
                if(props.postProcessJSON) {
                    json = props.postProcessJSON(json);
                }

                // Post process item after retrieving if needed
                if(props.postProcessItem) {
                    json = json.map(x => props.postProcessItem(x))
                }
                setLoadedOptions(json)

                let val = _getOptionValue(json, value);
                // If the field is required but no default value has been given, we use
                // the first one loaded as the initial value
                if(props.required && !val) {
                    let defaultValue = json[0];
                    if(props.getDefaultValue){
                        defaultValue = props.getDefaultValue(json);
                    }
                    onInputChange(defaultValue);
                } else if (value != val) {
                    setValue(val);
                }
                return json
            }
        );
    }

    const getUrlWithTerm = input => {
        let term = input || '';
        let url = props.backendURL;
        const val = +props.value;
        url += url.indexOf('?') > -1 ? '&' : '?';
        if (val) {
            url += 'pk=' + props.value
        } else {
            url += (props.termQueryParam || 'term') + '=' + term;
        }
        return url;
    }
    const getOptionsData = input => {
        // Returns an empty array when we should not trigger the backend on empty input
        if (!input && !initialLoad) {
            if (!props.mostCommonUrl){
                return Promise.resolve([]);
            } else {
                return getBackendData(props.mostCommonUrl)
            }
        }

        // Already loaded options can directly be returned
        if (props.options && !props.backendURL) {
            return Promise.resolve(props.options);
        }

        if (input.length < (props.minSearchLength || 0)) {
            return Promise.resolve([]);
        }
        return getBackendData(getUrlWithTerm(input));
    }

    const onCreateOption = input => {
        let creationData = props.creationData || {};
        creationData[labelKey] = input;
        const onValueAdded = newVal => {
            if (props.multiple) {
                newVal = [...value, newVal];
            }
            setValue(newVal);
        }
        if(props.createURL || props.backendURL){
            fetchCsrfWrapper(props.createURL || props.backendURL, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                body: creationData,
                signal: xhrController.signal
            }).then(
                new_val => {
                    onValueAdded(new_val);
                    return new_val;
                },
                error => alert(error)
            );
        }
        else {
            onValueAdded(creationData);
        }
    }

    let select_props = {
        ref: input,
        value: value,
        name: props.name,
        onChange: onInputChange,
        options: props.options || [],
        defaultOptions: !!(props.backendURL || props.mostCommonUrl),
        loadOptions: getOptionsData,
        loadingPlaceholder: trans.t('Chargement...'),
        noOptionsMessage: () => trans.t('Pas de résultats'),
        isMulti: props.multiple,
        isSearchable: !(props.noSearch || false),
        getOptionLabel: option => {
            // Handle complex representation of labels while keeping simple labelKey
            if (props.objectRepresentation) {
                return props.objectRepresentation(option);
            }
            let label = option.__isNew__ ? option.label : option[labelKey]
            return label;
        },
        getOptionValue: option => {
            /* If a pk is given as a value an initial render will be done with it.
             * To avoid the react key used by react-select to be undefined, use the pk
             * directly as a value, asserting that the key is unique.
             * As soon as the options are loaded, this temporary option disappear.
             */
            if(Number.isInteger(option)){
                return option;
            }
            return option[valueKey]
        },
        className: className,
        autoFocus: props.autoFocus,
        placeholder: props.placeholder || "",
        isClearable: !props.required,
        isDisabled: props.disabled,
        menuPlacement: props.menuPlacement,

        styles: props.styles || SELECT_INPUT_STYLES,

        // Recreate component upon changing backendURL
        key: props.backendURL + props.mostCommonUrl,

        // Use this props when options can't be selected when overflowing on subsequent table lines
        menuPortalTarget: props.menuPortalTarget || null,
        menuShouldBlockScroll: !!props.menuPortalTarget, // Added to avoid options being fixed on scroll

        components: {},
    }
    // let the backend do the filtering when loading data async
    if (props.filterOptions) {
        select_props['filterOption'] = props.filterOptions;
    } else if(!initialLoad || props.disableClientFiltering) {
        select_props['filterOption'] = (option, inputValue) => true;
    }

    if(props.multiple && props.ordered) {
        select_props['components']['ValueContainer'] = OrderableValueContainer;
        select_props['components']['MultiValue'] = OrderableMultiValue;
    }

    let component = Select;
    const creatableProps = {
        createOptionPosition: 'last',
        formatCreateLabel: (props.formatCreateLabel ?
            props.formatCreateLabel
            :
            inputValue => <a className="action-link">{trans.t("Créer {{object_type}}", {object_type: inputValue})}</a>
        ),
        onCreateOption: onCreateOption,
        isValidNewOption: input => input.length >= (props.minSearchLength || 1),
        allowCreateWhileLoading: true,
    }
    if (props.backendURL) {
        component = Async;
        if (props.creatable) {
            component = AsyncCreatable;
            select_props = Object.assign({}, select_props, creatableProps);
        }
    } else if(props.creatable){
        component = Creatable;
        select_props = Object.assign({}, select_props, creatableProps);
    }

    if(props.show_add || props.pre_addon) {
        return (
            <div className="input-group" onKeyUp={onKeyUp}>
                {props.pre_addon &&
                    <span className="input-group-text">{props.pre_addon}</span>
                }
                <div style={{"position": "relative", "flex": "1 1 auto", "width": "1%", "minWidth": "0"}}>
                    {React.createElement(component, select_props)}
                </div>
                {props.show_add &&
                <div className="input-group-text add-sign" onClick={props.onAddClick}>
                    <i className="fa fa-plus"></i>
                </div>}
            </div>
        );
    }
    return (
        <div onKeyUp={onKeyUp}>
            {React.createElement(component, select_props)}
        </div>
    );
}


/**
 * Component to allows editon of fields without any post-processing:
 * the value stored in database is displayed and kept in state.
 */

const SimpleFieldEdition = props => {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(props.initial_value);
    const isEditable = () => (props.editable == undefined || props.editable) && !isReadOnly();

    const onSubmit = new_value => {
        // Do not trigger saving if the value has not been touched
        if(value == new_value) {
            setEditing(false);
            return;
        }

        let notif_id = AjaxNotification.show(trans.t('Sauvegarde...'));
        $.ajax({
            url: props.backend_url, method: "PATCH",
            data: {[props.field_name]: new_value}
        }).success((data, status) => {
            setValue(new_value);
            setEditing(false);
        }).error((jqXHR, status) => {
            // Check if an error message has been given by the server
            var error_data = jqXHR.responseJSON;
            if(error_data !== undefined && error_data[props.field_name]) {
                var errors = error_data[props.field_name];
                if(typeof errors == "object") {
                    errors = errors.join('\n');
                }
                alert(trans.t('Erreur lors de la sauvegarde') + ' : ' + errors);
            }
            else {
                alert(trans.t("Une erreur est survenue lors de l'édition"));
            }
        }).always(
            () => AjaxNotification.hide(notif_id)
        );
    }

    if(editing) {
        const formProps = {
            value: value,
            onCancel: () => setEditing(false),
            onSubmit: onSubmit,
        };

        if(props.html === false) {
            return <SimpleTextareaForm {...formProps} />;
        }

        return <RichHTMLForm {...formProps} />;
    }

    const onClick = ev => {
        if(!isEditable())
            return false;

        if(ev.target.href)
            return;

        setEditing(true);
    }

    return (
        <div onClick={onClick} className={isEditable() ? "inline-editable editzone" : ""}>
            {props.html === false ?
                <div style={{'whiteSpace': 'pre-line'}}>{value}</div>
            :
                <div dangerouslySetInnerHTML={{__html: value || "<em>" + (props.placeholder || '') + "</em>"}}></div>
            }
        </div>
    );
}



const RichHTMLForm = props => {
    const [editorValue, setEditorValue] = useState(props.value);

    const inputRef = useRef(null);
    const tinymce_config = {
        forced_root_block: false,
        branding: false,
        browser_spellcheck: true,
        contextmenu: false,  // to allow usage of right click for spellcheck
        menubar: false,
        statusbar: false,
        language: 'fr_FR',
        plugins: 'autolink link image paste autoresize tabfocus lists',
        toolbar: 'bold italic underline strikethrough | link | forecolor backcolor | bullist numlist',
        valid_styles: {'*': 'border,font-size,text-decoration,color,background-color', 'div': 'width,height'},
        invalid_elements: 'table,tr,td',
        link_assume_external_targets: 'https',
        relative_urls: false
    }
    useEffect(() => {
        setTimeout(() => {
            try {
                tinymce.execCommand('mceFocus', false, inputRef.current);
            }
            catch(err) {}
        }, 300);
    }, [])

    const onSubmit = ev => {
        ev.preventDefault();
        props.onSubmit(editorValue);
    }

    return (
        <form className="form react-inline-form simple-field" onSubmit={onSubmit}>
            <TinyMCE
                value={editorValue} init={tinymce_config} ref={inputRef}
                onEditorChange={content => setEditorValue(content)}
            />

            <div className="actions text-end">
                <a className="btn btn-outline-secondary" onClick={props.onCancel}>{trans.t("Annuler")}</a>&nbsp;
                <button type="submit" className="btn btn-primary">{trans.t("Sauvegarder")}</button>
            </div>
        </form>
    );
}


const SimpleTextareaForm = props => {
    const [value, setValue] = useState(props.value);

    const onSubmit = ev => {
        ev.preventDefault();
        props.onSubmit(value);
    }

    return (
        <form className="form react-inline-form simple-field" onSubmit={onSubmit}>
            <AutosizeTextarea className="form-control" value={value} onChange={ev => setValue(ev.target.value)}></AutosizeTextarea>

            <div className="actions text-end">
                <a className="btn btn-sm btn-outline-secondary" onClick={props.onCancel}>{trans.t("Annuler")}</a>&nbsp;
                <button type="submit" className="btn btn-sm btn-primary">{trans.t("Sauvegarder")}</button>
            </div>
        </form>
    );
}

/**
 * Display a list of buttons horizontally, to select the date offset in spectacle event
 */


const TimelineInput = props => {
    const [position, setPosition] = useState(0);
    const length = props.length || 5
    const setValue = val => {
        val = +val;
        let newValue = [val]
        if (props.multiselection) {
            newValue = props.value.slice();
            if(newValue.indexOf(val) === -1)
                newValue.push(val)
            else
                newValue.splice(newValue.indexOf(val), 1)
        }
        props.onChange(newValue)
    }

    const movePosition = offset => {
        if(!props.multiselection)
            props.onChange([props.value[0] + offset])
        setPosition(position + offset)
    }

    let button_width, caret_width;
        // mutiline selector
        if(length > 5) {
            button_width = '20%';
            caret_width = '20%';
        }
        // single line selector
        else {
            caret_width = '7%';
            button_width = (84 / length) + '%';
        }

        // Generate the list of button according to the length given in props
        let buttons = [];
        let classes = "btn btn-sm";

        for (let i = 0; i < length; i++) {
            let button_value = position + i;
            classes = "btn btn-sm btn-more-contrast"
            if(props.value.indexOf(button_value) !== -1) {
                classes += " btn-primary";
            } else {
                classes += " btn-light";
                if (props.dayHighlight == button_value) {
                    classes += " btn-highlight";
                }
            }
            let displayed_value = button_value;
            if (button_value > 0) {
                displayed_value = `+${button_value}`
            }
            buttons.push(
                <button type="button" className={classes} style={{'width': button_width}}
                        key={'p' + position + 'v' + button_value} value={button_value}
                        onClick={e => setValue(e.target.value)}
                >
                    J{displayed_value}
                </button>
            );
        }
        const offset = length - 1
        // Display the buttons list with two caret to move the timeline to the past/future
        return (
            <div className="btn-group" style={{'width': '100%'}}>
                <button type="button" className="btn btn-light btn-sm"
                onClick={e => movePosition(-offset)}
                style={{'width': caret_width}}>
                    <i className="fa fa-caret-left"></i>
                </button>
                {buttons}
                <button type="button" className="btn btn-light btn-sm"
                onClick={e => movePosition(offset)}
                style={{'width': caret_width}}>
                    <i className="fa fa-caret-right"></i>
                </button>
            </div>
        )
}
TimelineInput.propTypes = {
    'value': PropTypes.arrayOf(PropTypes.number).isRequired,
    'multiselection': PropTypes.bool,
    'length': PropTypes.number,
    'onChange': PropTypes.func.isRequired,
}

const _autosizeDOMTextarea = (node, min_size, max_size) => {
    node.style.overflow = 'hidden';
    // we're never against a little browser hack to get the proper height of the textarea content,
    // manipulating the scroll size: https://stackoverflow.com/a/3341669/1433392
    node.style.height = '1px';
    var height = Math.max(min_size || 32, node.scrollHeight);
    node.style.height = Math.min(height, max_size || Infinity) + 'px';
}


const AutosizeTextarea = (props) => {
    const nodeRef = useRef(null);

    // Set height of the textarea on initial mount
    useEffect(() => {
        _autosizeDOMTextarea(nodeRef.current, props.min_size, props.max_size);
    })
    // Update height on any keystroke
    const onKeyUp = ev => {
        _autosizeDOMTextarea(nodeRef.current, props.min_size, props.max_size);
        if(props.onKeyUp)
            props.onKeyUp(ev)
    }

    // Copy any props given by the parent and override some of them to include
    // our custom behaviour
    let subprops = Object.assign({}, props, {ref: nodeRef, onKeyUp: onKeyUp});
    return React.createElement('textarea', subprops);
}


const _get_structure_and_person_names = obj => {
    if(obj.type == 'structure')
        return [obj.name, obj.structure_person.name];
    return [obj.structure_person.name, obj.name];
}


class EntityComplexTypeahead extends React.Component {

    focus() {
        this.refs.field.focus();
    }

    setValue(val) {
        this.refs.field.setValue(val);
    }

    onAddEntity() {
        const closeModal = data => {
            if(data != null){
                this.setValue(data);
                this.props.onChange(data);
            }
            return true;
        };

        var wrapper = getModalWrapper();
        ReactDOM.render(
            <EntityAddForm entityType="contact" onModalClose={data => closeModal(data)} />,
            wrapper
        );
    }

    render() {
        let url = "/backend/entity/search_with_related/";
        if(this.props.exclude_structures)
            url += "?exclude_structures=true";
        else if(this.props.include_only_structures)
            url += "?include_only_structures=true";

        let entity_suggs =  BloodhoundFactory(url);
        const tt_representation = obj => {
            if(obj.structure_person) {
                let names = _get_structure_and_person_names(obj);
                return names[1] + ' (' + names[0] + ')';
            }
            return obj.name;
        }
        const tt_sugg_template = obj => {
            let content = obj.name;
            if(obj.structure_person) {
                let names = _get_structure_and_person_names(obj);
                content = names[1] + '<br /><small>' + names[0] + '</small>';
            }
            return '<div class="tt-suggestion" style="min-height: 35px; border-bottom: 1px solid #ddd"><p>'+content+'</p></div>';
        }

        return (
            <TypeaheadField
                suggestions={entity_suggs.ttAdapter()} ref="field"
                placeholder={this.props.placeholder || (trans.t("Contact") +  " / " + trans.t("Structure"))}
                className={this.props.className}
                defaultObject={this.props.entity}
                objectRepresentation={tt_representation} customTemplate={tt_sugg_template}
                onChange={this.props.onChange} name={this.props.name}
                show_add={this.props.show_add_entity || this.props.show_add}
                onAddClick={data => this.props.show_add_entity ? this.onAddEntity(data) : this.props.onAddClick(data)}
            />
        )
    }
}


const SelectorList = props => {
    // Specifies if the options list is displayed or not
    const [expanded, setExpanded] = useState(false);
    // Store the current text search fragment submitted by the user, without alteration
    const [filter_text, setFilterText] = useState('');
    // Node reference to the main node of the component to track event outside it
    const wrapperRef = useRef(null);

    // Track all click event happening in the page to close the expanded area if it's opened
    // and the user has clicked anywhere else
    const handleClickOutside = event => {
        if(wrapperRef.current && !wrapperRef.current.contains(event.target)) {
           setExpanded(false);
           setFilterText('');
        }
    }
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => { document.removeEventListener("mousedown", handleClickOutside); };
    }, []);

    // Handler for click on the checkboxes
    const onCheckAll = ev => props.onChange(props.choices.filter(x => !x.isParent).slice());
    const onCheckNone = ev => props.onChange([]);

    // Filter the list of choices to display according to the text filter, if relevant.
    let choices_displayed = props.choices;
    if(filter_text.length > 0) {
        let filter_text_lowered = filter_text.toLowerCase();
        choices_displayed = choices_displayed.filter(
            x => (
                x.label.toLowerCase().indexOf(filter_text_lowered) !== -1
                || (x.parent && (x.parent.label.toLowerCase().indexOf(filter_text_lowered) !== -1))
            )
        )
    }

    return (
        <div className={classNames("multi-checkbox-selector", {'disabled': !!props.disabled})} ref={wrapperRef}>
            <div className="input-summary" onClick={ev => !props.disabled && setExpanded(!expanded)}>
                {props.summary}
            </div>
            {expanded &&
            <div className="inner-selector">
                {props.show_filtering &&
                <div className="input-group input-group-sm">
                    <span className="input-group-text"><i className="fa fa-search"></i></span>
                    <input
                        type="text" className="form-control input-sm" placeholder={trans.t("Filtrer...")} autoFocus
                        value={filter_text} onChange={ev => setFilterText(ev.target.value)}
                    />
                </div>}

                {props.show_filtering && filter_text.length == 0 &&
                    <div>
                        <a className="action-link" onClick={onCheckAll}>Tout cocher</a> - <a className="action-link" onClick={onCheckNone}>{trans.t("Tout décocher")}</a>
                    </div>
                }

                <ul className="items">
                    {choices_displayed.map(choice => props.render_choice(choice))}
                    {choices_displayed.length == 0 &&
                        <li className="text-muted text-center" style={{'marginTop': '20px'}}><em>{trans.t("Aucun résultat")}</em></li>
                    }
                </ul>
            </div>}
        </div>
    )
}


const MultiCheckboxSelector = props => {
    const values_selected = props.value || [];

    /**
     * When using, grouped_choices, reduce it to a single list and add attributes to choices
     * so we can manipulate them
     */
    const choices = useMemo(() => {
        if(props.grouped_choices){
            return props.grouped_choices.reduce((acc, group) => {
                if(!group.children || group.children.length === 0){
                    return acc.concat([group.parent]);
                }

                let grouped_items = [{isParent: true, label: group.parent.label, key: `${group.parent.key}_parent`}];
                grouped_items = grouped_items.concat(group.children.map(child => (
                    Object.assign({parent: group.parent}, child)
                )));
                grouped_items.push({
                    'label': `sans ${props.child_name}`,
                    'key': group.parent.key,
                    'parent': group.parent,
                    'style': 'em'
                })

                return acc.concat(grouped_items);
            }, []);
        }
        return props.choices;
    }, [props.choices, props.grouped_choices]);

    function addFilter(values, element){
        if(!(element.label instanceof String))
            element.label = "";
        values.push(element)
        return values;
    }

    function removeFilter(values, element){
        if(!(element.label instanceof String))
            element.label = "";
        return values.filter(x => x.key != element.key)
    }

    const onCheckboxClick = ev => {
        let new_selection = values_selected.slice();

        let element = choices.filter(x => x.key == ev.target.value)
        if(!element.length)
            return;

        const el = element[0];
        if(el.isParent){
            const children = choices.filter(x => x.parent && (x.parent.key == ev.target.value.replace('_parent', '')))
            if(ev.target.checked){
                children.forEach(x => {
                    new_selection = addFilter(new_selection, x)
                });
            } else {
                children.forEach(x => {
                    new_selection = removeFilter(new_selection, x)
                });
            }
        } else {
            if(ev.target.checked) {
                new_selection = addFilter(new_selection, el);
            }
            else {
                new_selection = removeFilter(new_selection, el);
            }
        }

        props.onChange(new_selection);
    }

    // Compute the text displayed in the fake input that summarized the selection of the user
    let summary;
    switch(values_selected.length) {
        case 0:
        case choices.filter(x => !x.isParent).length:
            let prefix = props.female_label ? trans.t("Toutes") : trans.t("Tous");
            summary = prefix + " les " + props.items_name;
            break;
        case 1:
            summary = choices.find(x => x.key == props.value[0].key)?.label;
            break;
        default:
            summary = props.value.length + " " + props.items_name;
            break;
    }

    const keys_selected = (props.value || []).map(x => x.key);

    return (
        <SelectorList
            summary={summary}
            show_filtering
            choices={choices}
            value={values_selected}
            onChange={props.onChange}
            disabled={props.disabled}
            render_choice={
                choice => {
                    let checked = keys_selected.includes(choice.key);
                    if(choice.isParent){
                        const parentKey = choice.key.replace('_parent', '')
                        checked = (
                            (props.value || []).filter(x => x.parent && (x.parent.key == parentKey)).length
                            === choices.filter(x => x.parent && (x.parent.key == parentKey)).length
                        );
                    }
                    return (
                        <li key={choice.key} style={choice.parent && {'marginLeft': '-18px'}}>
                            <input
                                type="checkbox" id={choice.key} onChange={onCheckboxClick}
                                value={choice.key} checked={checked}
                            />
                            <label htmlFor={choice.key} style={
                                choice.style == 'em' ? {'fontStyle': 'italic'} : {}
                            }
                            >{choice.label}</label>
                        </li>
                    )
                }
            }
        >
        </SelectorList>
    )
}

const ProxyRestrictionField = ({proxy_restriction, proxy_choices, onChange}) => {
    proxy_restriction = proxy_restriction || [];

    const onProxyRestrictionChange = ev => {
        let lst = proxy_restriction.slice();

        if(ev.target.checked)
            lst.push(ev.target.value);
        else
            lst.splice(lst.indexOf(ev.target.value), 1);

        onChange(lst);
    }

    return (
        <div className="row">
            <label className="col-3 control-label">{trans.t("Applicable sur")}</label>
            <div className="col-9">
                {proxy_choices && proxy_choices.map(
                    x => (
                        <label className="d-block checkbox" key={x[0]}>
                            <input
                                type="checkbox" value={x[0]}
                                onChange={onProxyRestrictionChange}
                                checked={proxy_restriction.indexOf(x[0]) !== -1}
                            /> {x[1]}
                       </label>
                    )
                )}
                <input type="hidden" name="proxy_restriction" value={proxy_restriction.join(',')} />
            </div>
        </div>
    )
}

const OrderingIcon = props => {
    if(![props.field, '-' + props.field].includes(props.ordering))
        return null;

    const getOrderingIcon = (criteria, ordering) => {
        return '-' + criteria == ordering ? 'down' : 'up';
    };
    return <i className={'fa fa-angle-' + getOrderingIcon(props.field, props.ordering)} />;
};

const BootstrapSwitch = props => {
    let htmlID = props.htmlID || ("switch"+_getUID());

    return (
        <div className="d-inline-block form-switch">
            <input
                type="checkbox" className="form-check-input" id={htmlID}
                checked={props.value}
                onChange={ev => props.onChange(ev.target.checked)}
                disabled={props.disabled === true}
            />
            {props.label &&
                <label className={classNames("form-check-label ps-2", {"d-inline": props.labelInline})}
                    htmlFor={htmlID}
                >
                    {props.label}
                </label>
            }
        </div>
    );
}
BootstrapSwitch.propTypes = {
    value: PropTypes.bool,
    label: PropTypes.string,
    htmlID: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    labelInline: PropTypes.bool
}

const EmailRecipientField = props => {
    const getQueryParams = val => {
        let params = {query: val};
        if (props.entities) {
            params.entities = props.entities
        }
        if (props.contract) {
            params.contract = props.contract
        }
        return params
    }
    const autocompleteOptions = val => (
        fetchCsrfWrapper(
            "/entities/email-autocomplete/",
            {body: getQueryParams(val)},
            {noPendingMessage: true}
        ).then(
            data => data.results.map(x => ({'value': x.id, 'label': x.text}))
        )
    )
    return (
        <div className="input-group">
            <div className="input-group-text" >
                {props.prefix || 'À'}
            </div>
            <AsyncCreatable
                placeholder=""
                isMulti
                cacheOptions    // Cache queries done by the component
                defaultOptions  // Trigger an empty load per default to display suggestions
                loadOptions={autocompleteOptions}
                isValidNewOption={x => x.indexOf('@') !== -1}
                onChange={val => props.onValueChange(val.map(x => x.value.trim()))}
                value={props.values.map(x => ({'value': x, 'label': x}))}
                noOptionsMessage={() => trans.t('Pas de résultats')}
                formatCreateLabel={inputValue => trans.t(`Ajouter "{{object_type}}"`, {object_type: inputValue})}
                className="email-recipient-container"
                classNamePrefix="email-recipient"
                onFocus={ev => props.onFocus && props.onFocus(ev)}
                onBlur={ev => props.onBlur && props.onBlur(ev)}
              />
        </div>
    );
}

const EllipsisOptions = ({ options, disabled, categories=false }) => {
    // options should be an array of objects [{label:<str>, callback:<function>}]
    if (categories) {
        categories = options.reduce((acc, option) => {
            if (option.category) {
                if (!acc[option.category]) {
                    acc[option.category] = [];
                }
                acc[option.category].push(option);
            }
            return acc;
        }, {});
    }

    return (
        <div className="dropdown ellipsis-options">
            <a
                className={classNames("px-2 float-end", {
                    disabled: !!disabled
                })}
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <DotsThreeOutlineVertical size={16} weight="bold" color='black' />
            </a>
            <ul className="dropdown-menu">
                {!categories ? (
                    options.map((option_obj, idx) => (
                        <li key={idx}>
                            <a
                                role="button"
                                className="dropdown-item d-flex align-items-center gap-2"
                                onClick={option_obj.callback}
                            >
                                {option_obj.icon && option_obj.icon}
                                {option_obj.label}
                            </a>
                        </li>
                    ))
                ) : (
                    <>
                        {Object.entries(categories).map(
                            ([category, options], idx) => (
                                <React.Fragment key={idx}>
                                    <li className="dropdown-header">
                                        {category}
                                    </li>
                                    {options.map((option_obj, optionIdx) => (
                                        <li key={`${idx}-${optionIdx}`}>
                                            <a
                                                role="button"
                                                className="dropdown-item d-flex align-items-center gap-2"
                                                onClick={option_obj.callback}
                                            >
                                                {option_obj.icon &&
                                                    option_obj.icon}
                                                {option_obj.label}
                                            </a>
                                        </li>
                                    ))}
                                    {idx < Object.entries(categories).length - 1 && (
                                        <li>
                                            <hr className="dropdown-divider" />
                                        </li>
                                    )}
                                </React.Fragment>
                            )
                        )}
                    </>
                )}
            </ul>
        </div>
    );
}
EllipsisOptions.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        callback: PropTypes.func,
        label: PropTypes.string,
    }))
}

const ActionButton = props => {
    let content = <>
        {props.icon}<br/>
        {props.label}
    </>;
    let link_props = {}
    if (props.href) {
        link_props = {href: props.href}
    } else if (props.onClick) {
        link_props = {role: 'button', onClick: props.onClick}
    }
    if (props.disabled) {
        link_props = {role: 'button', onClick: () => {}}
    }
    return <a className={classNames("action-link", {disabled: props.disabled})} {...link_props}>
        {props.title ?
            <Tooltip title={props.title}>{content}</Tooltip>
            : content
        }
    </a>
}
ActionButton.propTypes = {
    onClick: PropTypes.func,
    href: PropTypes.string,
    title: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.node,
    disabled: PropTypes.bool,
}

export {
    ActionButton,
    AutosizeTextarea,
    BootstrapSwitch,
    CalendarInput,
    CalendarPeriodInput,
    EllipsisOptions,
    EmailRecipientField,
    EntityComplexTypeahead,
    ForeignKeyField,
    MultiCheckboxSelector,
    OrderingIcon,
    ProxyRestrictionField,
    SELECT_INPUT_STYLES,
    SelectInput,
    SimpleFieldEdition,
    SuggestionTypeaheadField,
    TimeInput,
    TimelineInput,
    TypeaheadField,
    YesNoChoice,
}
