import classNames from "classnames"
import React, { useState, createContext, useContext } from "react"
import { X } from "@phosphor-icons/react";



const SelectionModeContext = createContext({})

const GlobalSelection = props => {
    const {
        children,
    } = props

    const [isGlobalSelectOn, setGlobalSelect] = useState(false)
    const [selection, setSelection] = useState(new Set())

    /**
     *  LOGIC
     */

    const enableGlobalSelect = () => {
        setGlobalSelect(true)
    }

    const disableGlobalSelect = () => {
        setSelection(new Set())
        setGlobalSelect(false)
    }

    const addToGlobalSelection = newItems => {
        setSelection(prevSelection => {
            const updatedSet = new Set(prevSelection)
            newItems.forEach(item => updatedSet.add(item))
            return updatedSet
        });
    }

    const removeFromGlobalSelection = deletedItems => {
        setSelection(prevSelection => {
            const updatedSet = new Set(prevSelection)
            deletedItems.forEach(item => updatedSet.delete(item))
            return updatedSet
        })
    }

    const selectionIncludes = items => items.every(item => selection.has(item))

    const contextValue = {
        selection: Array.from(selection),
        isGlobalSelectOn,
        enableGlobalSelect,
        disableGlobalSelect,
        addToGlobalSelection,
        removeFromGlobalSelection,
        selectionIncludes
    }

    /**
     *  JSX
     */

    return (
        <SelectionModeContext.Provider value={contextValue}>
            {children}
        </SelectionModeContext.Provider>
    )
}

GlobalSelection.Toast = (props) => {
    const {
        children,
    } = props;

    const {isGlobalSelectOn, disableGlobalSelect, selection} = useContext(SelectionModeContext)
    const label = selection.length <= 1 ?
        trans.t('{{nb}} élément sélectionné', {nb: selection.length})
        : trans.t('{{nb}} éléments sélectionnés', {nb: selection.length})

    /**
     *  JSX
     */

    return (
        <div className={classNames("oo-selection-toast", {show: isGlobalSelectOn})}>
            <label>{label}</label>
            <div className="vr mx-1 my-2"/>
            <div>
                {children}
            </div>
            <div className="vr mx-1 my-2"/>
            <button type="button" className="btn btn-sm btn-dark" onClick={() => disableGlobalSelect()}><X/></button>
        </div>
    );
};



export default GlobalSelection
export { SelectionModeContext }