import { CaretDown, User } from "@phosphor-icons/react"
import React, { useId, useState } from "react"
import { Checkbox, Searchbar } from "orfeo_common/components/forms/Inputs"
import classNames from "classnames"
import {caseInsensitiveIncludes} from "orfeo_common/utils/text"



/**
 * This React component is used to display a dropdown menu for the user to select several items
 * among a list of different options. It displays a search bar if there is too many elements
 * 
 * @param name the name of the dropdown menu
 * @param optionsData a list of options displayed to the user as data objects list
 * @param quickOptionsIds The list of priority options ids, they will be displayed on top of the menu
 * @param initialSelection The list of options ids selected by default
 * @param getOptionLabel a function taking an option as a parameter and returns its string representation
 * @param getOptionId a function taking an option as a parameter and returns its identifier
 * @param onChange a callback to execute everytime the selection is changed
 * @param optionsTypeName a string to define the type name of options, used in header when several options are selected
 * @param searchbarOptionsThreshold number of options above which a search bar is displayed in the menu
 */
const FiltersAction = props => {
    const {
        name = '',
        optionsData = [],
        quickOptionsIds = [],
        initialSelection = [],
        getOptionLabel = option => String(option),
        getOptionId = option => option,
        onChange = () => {},
        optionsTypeName = trans.t('options'),
        searchbarOptionsThreshold = 15,
        disabled = false
    } = props

    const [selectedIds, setSelectedIds] = useState(initialSelection)
    const [searchTerm, setSearchTerm] = useState('')
    const buttonId = useId()
    const isSearchbarDisplayed = optionsData.length >= searchbarOptionsThreshold

    /**
     *  LOGIC
     */

    const findOption = id => (optionsData.find(option => getOptionId(option) === id))

    let headerName = name
    if (selectedIds.length == 1) headerName += ` ${getOptionLabel(findOption(selectedIds[0]))}`
    if (selectedIds.length > 1) headerName += ` ${selectedIds.length} ${optionsTypeName}`

    const optionsFiltered = optionsData.filter(option => 
        searchTerm == '' ||
        caseInsensitiveIncludes(getOptionLabel(option), searchTerm)
    )

    const quickOptions = optionsFiltered.filter(option => quickOptionsIds.includes(getOptionId(option)))
    const displayedOptions = optionsFiltered.filter(option => !quickOptions.includes(option))

    const handleOptionCheck = (checked, option) => {
        const optionId = getOptionId(option)

        const newSelection = checked ?
            [...selectedIds, optionId]
        :
            selectedIds.filter(item => item !== optionId)

        setSelectedIds(newSelection)
        onChange(newSelection)
    }

    const handleClearSelection = () => {
        setSelectedIds([])
        onChange([])
    }

    const isSelected = option => (
        selectedIds.some(selected => selected === getOptionId(option))
    )

    /**
     *  JSX
     */

    return (
    <div className={classNames("dropdown btn-group oo-filters-action", {'values-selected': selectedIds.length > 0})}>
        <button type="button" disabled={disabled} data-bs-toggle="dropdown" data-bs-reference={buttonId} data-bs-auto-close="outside" aria-expanded="false"
        className="btn btn-light d-flex align-items-center gap-1 dropdown-toggle">
            <User />
            <span>{headerName}</span>
            <CaretDown />
        </button>

        <div id={buttonId} className={classNames("dropdown-menu", {'disabled': disabled})}>
            <div className="dropdown-header fs-6 column-gap-1 d-flex align-items-baseline justify-content-between">
                <span>{headerName}</span>
                
                {selectedIds.length > 0 &&
                    <button className='btn p-0 btn-link' onClick={handleClearSelection}>{trans.t('Effacer')}</button>
                }
            </div>

            { isSearchbarDisplayed &&
                <div className="dropdown-item oo-dropdown-search">
                    <Searchbar
                        value={searchTerm}
                        onChange={value => setSearchTerm(value)}/>
                </div>
            }

            <ul className="m-0 p-0">
                {quickOptions.map(option => (
                    <li key={getOptionId(option)}>
                        <div className="dropdown-item">
                            <Checkbox
                                label={getOptionLabel(option)}
                                checked={isSelected(option)}
                                onChecked={checked => handleOptionCheck(checked, option)}/>
                        </div>
                    </li>
                ))}
            </ul>

            {quickOptions.length > 0 && displayedOptions.length > 0 &&
                <hr className="dropdown-divider" />
            }

            <ul className="overflow-scroll m-0 p-0">
                {displayedOptions.map(option => (
                    <li key={getOptionId(option)}>
                        <div className="dropdown-item">
                            <Checkbox
                                label={getOptionLabel(option)}
                                checked={isSelected(option)}
                                onChecked={checked => handleOptionCheck(checked, option)}/>
                        </div>
                    </li>
                ))}
            </ul>

            {optionsFiltered == 0 &&
                <span className="d-inline-block w-100 text-center py-4">
                    {trans.t('Aucun résultat')}
                </span>
            }
        </div>
    </div>
    )
}



export default FiltersAction
