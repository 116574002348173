import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import SearchStore from './SearchStore.jsx';

/** Indexes and results are keep as global variables of the current file **/
var urlParams = {};

const DirectoryResultItem = (props) => {
    var item = props.item;
    var item_type = item.field_type || '?';

    var entity_type = item_type.charAt(0).toUpperCase() + item_type.slice(1);
    return (
        <div className="result-item">
            <a href={item._absolute_url}>
                {item_type == 'structure' ?
                    <i className="fa fa-fw fa-building-o"></i>
                    :
                    <i className="fa fa-fw fa-user-circle-o"></i>
                }

                <h4>{item._abstract_title}</h4>
                <p className="entity-type">{entity_type}</p>

                {item.field_structures &&
                    <span className="text-muted">
                        {item.field_structures.map(info => info.name).join(', ')}
                    </span>
                }

                {item.field_contacts &&
                    <span className="text-muted">
                        {item.field_contacts.map(info => info.first_name + ' ' + info.last_name).join(', ')}
                    </span>
                }

            </a>
        </div>
    );
}


const ProjectResultItem = (props) => {
    return (
        <div className="result-item">
            <a href={props.item._absolute_url}>
                <i className="fa fa-fw fa-calendar-check-o"></i>
                <h4>{props.item.field_title}</h4>

                <span className="text-muted">{props.item.field_pretty_dates}</span>
            </a>
        </div>
    );
}


const EngagementResultItem = (props) => {
    return (
        <div className="result-item">
            <a href={props.item._absolute_url}>
                <i className="fa fa-fw fa-calendar-check-o"></i>
                <h4>{props.item.field_title}</h4>
                <p className="entity-type">{props.item.field_artist}</p>
                <span className="text-muted">{props.item.field_pretty_dates}</span>
            </a>
        </div>
    );
}

const ComposerResultItem = (props) => {
    return (
        <div className="result-item">
            <a href={props.item._absolute_url}>
                <i className="fa fa-fw fa-user-circle-o"></i>
                <h4>{props.item._abstract_title}</h4>
            </a>
        </div>
    );
}

const WorkResultItem = (props) => {
    return (
        <div className="result-item">
            <a href={props.item._absolute_url}>
                <i className="fa fa-fw fa-book"></i>
                <h4>{props.item.field_title}</h4>
                <span className="text-muted">de {props.item.field_composer}</span>
            </a>
        </div>
    );
}



const DefaultResultItem = (props) => {
    return (
        <div className="result-item">
            <a href={props.item._absolute_url}>
                <i className="fa fa-fw fa-question"></i>
                <h4>{props.item._abstract_title}</h4>
            </a>
        </div>
    );
}

var _RESULT_COMPONENTS = {
    'directory': DirectoryResultItem,
    'production': ProjectResultItem,
    'engagement': EngagementResultItem,
    'composer': ComposerResultItem,
    'work': WorkResultItem
}


const SearchPageView = props => {
    const [loading, setLoading] = useState(true);
    const [term, setTerm] = useState('');
    const [results, setResults] = useState({});
    const [selectedIndex, setSelectedIndex] = useState('directory');

    useEffect(() => {
        // Listen to every new search queries made asynchrounsly
        SearchStore.addOnChangeListener(data => {
            setResults(data['results']);
            setLoading(false);
        })

        // Parse url params, to check if there is a `q` param pre-submitted
        var match,
            pl     = /\+/g,  // Regex for replacing addition symbol with a space
            search = /([^&=]+)=?([^&]*)/g,
            decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
            query  = window.location.search.substring(1);

        while (match = search.exec(query))
            urlParams[decode(match[1])] = decode(match[2]);

        // Initialize state with URL params, if any given
        setTerm(urlParams['q'] || '')
        setSelectedIndex(urlParams['index'] || 'directory');
        // Trigger first results if an initial state is set
        if(urlParams['q'])
            SearchStore.search(urlParams['q'], 25);

    }, []);

    // Whenever the term is updated, trigger a new search
    useEffect(() => {
        if(!!term) {
            SearchStore.search(term, 25);
        }
    }, [term]);

    let globalCount = 0;
    for(let key in results) {
        globalCount += results[key].length;
    }

    let main_block;
    if(loading) {
        main_block = (
            <p className="empty-list-placeholder text-center">
                <i className="fa fa-spin fa-spinner"></i> {trans.t("Recherche en cours")}&hellip;
            </p>
        )
    }
    else if(globalCount == 0 || (selectedIndex != '' && results[selectedIndex].length === 0)) {
        main_block = <p className="empty-results">{trans.t("Votre recherche n'a donné aucun résultat")}</p>;
    }
    else {
        let ResultComponent = _RESULT_COMPONENTS[selectedIndex] || DefaultResultItem;
        main_block = (
            <div className="search-results">
                {results[selectedIndex].map(
                    (result, i) => <ResultComponent item={result} key={selectedIndex + i} />
                )}
            </div>
        );
    }

    return (
        <React.Fragment>
            <form className="main-form">
                <div className="search-input">
                    <input
                        type="text" className="form-control" placeholder="Recherche"
                        onChange={ev => setTerm(ev.target.value)} value={term}
                    />
                    <i className="fa fa-search"></i>
                </div>

                <div className="search-indices">
                {SUPPORTED_INDEXES.map(([index, label]) => {
                    let nb_results = (results[index] || []).length;
                    if(nb_results > 0)
                        label += ' (' + nb_results + ')';

                    return (
                        <div className={
                            classNames("search-filter", {"selected": (selectedIndex == index), "disabled": nb_results == 0})
                        }>
                            <a className="btn-link" onClick={() => setSelectedIndex(index)}>{label}</a>
                        </div>
                    );
                })}
                </div>
            </form>

            {main_block}
        </React.Fragment>
    );
}

export default SearchPageView;
