import React from "react";
import classNames from 'classnames';

import Tooltip from "orfeo_common/Tooltip"


const Button = props => {
    const { children, onClick, mainClass = "btn-light", ...otherProps } = props

    return (
        <button type="button" {...otherProps} onClick={() => onClick()} className={classNames("btn", mainClass)}>
            {children}
        </button>
    )
}

const PrimaryButton = props => {
    const {
        children,
        onClick = () => {},
        type='button',
        disabled=false,
        mainClass="btn-primary",
        otherClasses=[]
    } = props

    return (
        <button type={type} disabled={disabled} onClick={onClick} className={classNames("btn d-flex align-items-center gap-1", mainClass, otherClasses)}>
            {children}
        </button>
    )
};

const SecondaryButton = props => {
    const {
        children,
        onClick  = () => {},
        type='button',
        disabled=false,
        mainClass="btn-outline-primary",
        otherClasses=[]
    } = props;

    return (
        <button type={type} disabled={disabled} onClick={onClick} className={classNames("btn border-0", mainClass, otherClasses)}>
            {children}
        </button>
    );
}

const IconButton = props => {
    const {
        icon : Icon,
        onClick,
        size = '16px',
        weight = 'bold',
        alt = '',
        mirrored = false,
        color = '',
        className = 'rounded-circle p-1',
        disabled = false
    } = props

    const innerButton = <button type="button" disabled={disabled} className={`btn btn-light oo-icon-button ${className}`} onClick={onClick}>
        {Icon ? 
            <Icon
                weight={weight}
                size={size}
                color={color}
                mirrored={mirrored}
            />
        :
            <span className="text-truncate" style={{height: '1em'}}>{alt}</span>
        }
    </button>

    return (alt && !disabled) ?
            <Tooltip title={alt}>
                {innerButton}
            </Tooltip>
        :
            innerButton
}

export { Button, PrimaryButton, SecondaryButton, IconButton }
