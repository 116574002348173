import React, { useReducer } from 'react'

import { ProxyRestrictionField } from 'orfeo_common/Widgets.jsx';

function reducer(state, action){
	return Object.assign(
		{},
		state,
		{[action.field]: action.value}
	)
}

const EntityCategoryForm = props => {
	const [state, dispatch] = useReducer(reducer, props.obj || {});

	return (
		<div className="form-horizontal">
			{!!state.pk ?
				<h2>Édition de la catégorie « {state.name} »</h2>
			:
				<h2>Création d'une catégorie</h2>
			}

			<div className="row my-2">
				<label className="col-3 control-label">Nom</label>
				<div className="col-9">
					<input
						type="text" name="name" className="form-control" autoFocus required
						value={state.name || ""} onChange={ev => dispatch({'field': 'name', 'value': ev.target.value})}
					/> 
				</div>
			</div>

			<ProxyRestrictionField
				proxy_restriction={state.proxy_restriction || []}
                proxy_choices={PROXY_CHOICES}
                onChange={lst => dispatch({'field': 'proxy_restriction', 'value': lst})}
			/>
		</div>
	)
}

export default EntityCategoryForm
