import React, { useState, useEffect, useRef } from 'react';
import * as FormUtils from "orfeo_common/FormUtils.jsx";
import { AjaxNotification } from "orfeo_common/react-base.jsx";
import Modal from "orfeo_common/Modal.jsx";
import { CustomFieldStore } from './Utils.jsx';

const CustomFieldChoiceList = props => {
    const [nextUniqueId, setNextUniqueId] = useState(props.choices.length);
    const listRef = useRef();

    useEffect(() => {
        const dom = $(listRef.current);
        dom.sortable({
            handle: '.fa-bars',
            items: '.draggable',
            start: (event, ui) => document.body.style.cursor = 'move',
            stop: (event, ui) => {
                const order = dom.sortable("toArray", {attribute: 'data-id'});
                dom.sortable('cancel');
                document.body.style.cursor = 'default';
                props.onChange(order);
            },
        });

        return () => dom.sortable('destroy');
    }, [])

    const onChange = (idx, value) => {
        let choices = props.choices.slice();
        choices[idx] = value;
        props.onChange(choices);
    };

    const onDeleteItem = (idx) => {
        let choices = props.choices.slice();
        choices.splice(idx, 1);
        props.onChange(choices);
    };

    const addNewChoice = () => {
        let choices = props.choices.slice();
        setNextUniqueId(nextUniqueId + 1);
        choices.push('');
        props.onChange(choices);
    };

    let choices = props.choices;
    if(choices.length < 2) {
        for(let i = 2 - choices.length; i > 0; i--) {
            choices.push('');
        }
    }
    return (
        <ul className="custom-field-choices-list" style={{'marginLeft': '8px'}} ref={listRef}>
            {choices.map((elem, idx) => (
                <li key={'choice-' + idx} data-id={elem} className="draggable">
                    <span className="reorder-icon" style={{position: 'absolute'}}>
                        <i className="fa fa-bars"></i>
                    </span>
                    <div className="input-container">
                        <input
                            type="text" onChange={ev => onChange(idx, ev.target.value)}
                            className="form-control input-sm"
                            value={elem} required={idx < 2}
                        />
                    </div>
                    {choices.length > 2 && // We requires at least two choices on save
                        <i className="fa fa-trash" onClick={() => onDeleteItem(idx)}/>
                    }
                </li>
            ))}

            <li style={{'margin': '4px 0 0 -35px'}}>
                <a className="action-link pointer" onClick={addNewChoice}>
                    {trans.t("Ajouter un nouveau champ")}
                </a>
            </li>
        </ul>
    );
};


const CustomFieldModalForm = props => {

    let initial_values = (
        props.obj || {
            'label': '', 'field_type': 'text', 'choices': [],
            'always_displayed': false,
            'proxy_restriction': (props.proxyChoices || []).map(x => x[0])
        }
    )
    if(!initial_values.proxy_restriction)
        initial_values.proxy_restriction = [];
    if(props.sectionChoices && !initial_values.section)
        initial_values.section = props.sectionChoices[0][0];

    const {values, onChange, onKeyUp, onSubmit} = FormUtils.useForm(
        initial_values, handleSave, () => props.onModalClose()
    );
    const [errors, setErrors] = FormUtils.useFormErrors();

    const [choicesUpdated, setChoicesUpdated] = useState(false);

    const [occurrences, setOccurrences] = useState([]);
    const [loadingOccurrences, setLoadingOccurrences] = useState(false);

    useEffect(() => {
        if(props.obj && props.obj.pk) {
            setLoadingOccurrences(true);
            $.ajax({
                url: `/backend/customfield/${props.obj.pk}/get_occurrences/`,
            }).success((data, status) => {
                setOccurrences(data.occurrences);
                setLoadingOccurrences(false);
            });
        }
    }, [])

    function handleSave() {
        let method = 'POST', url = '/backend/customfield/';
        if(!!props.obj){
            method = 'PATCH';
            url += props.obj.pk + '/';
        }

        const data = {
            'label': values.label,
            'field_type': values.field_type,
            'always_displayed': values.always_displayed,
            'content_type': CONTENT_TYPE
        };
        if(values.field_type.indexOf('choices') !== -1)
            data['choices'] = values.choices;
        else
            data['choices'] = [];

        if(props.proxyChoices)
            if(!values.proxy_restriction.length){
                setErrors({
                    'proxy_restriction': trans.t("Veuillez sélectionner au moins un type sur lequel appliquer le champs personnalisé")
                });
                return;
            }
            data['proxy_restriction'] = values.proxy_restriction;

        if(props.sectionChoices)
            data['section'] = values.section;

        if(choicesUpdated && !confirm(trans.t('Les choix modifiés seront perdus sur les fiches les utilisant. Êtes-vous sûr de vouloir continuer ?'))){
            return;
        }

        $.ajax({
            url: url,
            method: method,
            data: data
        }).success((data, status) => {
            CustomFieldStore.update(data);
            props.onModalClose();
        }).error((data, status) => {
            setErrors(data.responseJSON);
        });
    }

    const onChoicesChange = new_choices => {
        onChange('choices', new_choices);
        if(values.choices.slice().sort().toString() != new_choices.slice().sort().toString()){
           setChoicesUpdated(true);
        }
    };

    const onProxyRestrictionChange = ev => {
        let lst = (values.proxy_restriction || []).slice();
        if(ev.target.checked)
            lst.push(ev.target.value);
        else
            lst.splice(lst.indexOf(ev.target.value), 1);

        onChange('proxy_restriction', lst);
    };

    const displayOccurrences = props.obj && props.obj.pk;

    return (
    <Modal show onHide={() => props.onModalClose(null)}>
        <form className="form-horizontal" onSubmit={onSubmit} onKeyUp={onKeyUp}>
            <Modal.Header>{props.obj ?
                trans.t("Édition de {{obj}}", {obj: props.obj.label})
            :
                trans.t("Création d'un champ personnalisé")
            }</Modal.Header>

            <Modal.Body>
            {Object.keys(errors).length > 0 &&
                <p className="react-form-errors-intro">
                    {trans.t("Erreur lors de la sauvegarde")}<br />{FormUtils.getGlobalErrors(errors)}
                </p>
            }

            <div className={"row mb-2" + FormUtils.getCSSError(errors, 'label')}>
                <p className="col-3 col-form-label">{trans.t("Nom")}</p>
                <div className="col-9">
                    <input type="text" className="form-control input-sm" name="label"
                           value={values.label} required autoFocus onChange={onChange}
                    />
                    <FormUtils.ErrorText errors={errors} field='label' />
                </div>
            </div>

            <div className={"row mb-2" + FormUtils.getCSSError(errors, 'field_type')}>
                <p className="col-3 col-form-label">{trans.t("Type de donnée")}</p>
                <div className="col-9">
                    <select className="form-select" onChange={onChange} name="field_type"
                            value={values.field_type}
                    >
                        {FIELD_TYPES.map(
                            x => <option value={x[0]} key={x[0]}>{x[1]}</option>
                        )}
                    </select>
                    <FormUtils.ErrorText errors={errors} field='field_type' />
                    {values.field_type == 'number' && (
                        <small>
                            <i className="fa fa-info-circle"></i>
                            <em> {trans.t("Les listes ne peuvent pas être triées par champs personnalisés de ce type")}</em>
                        </small>
                    )}
                </div>
            </div>

            {(values.field_type === 'choices' || values.field_type === 'multiple-choices') &&
            <div className={"row mb-2" + FormUtils.getCSSError(errors, 'choices')}>
                <p className="col-3 col-form-label">{trans.t("Choix possibles")}</p>
                <div className="col-9">
                    <CustomFieldChoiceList choices={values.choices} onChange={onChoicesChange}/>
                    <FormUtils.ErrorText errors={errors} field='choices' />
                    {choicesUpdated &&
                        <p className="text-warning-emphasis">
                            {trans.t(
                                "En cas de modification de la liste, les choix supprimés ou modifiés seront perdus"
                                + " si des fiches utilisent encore le choix concerné."
                            )}
                        </p>
                    }
                </div>
            </div>
            }

            {!props.excludeAlwaysDisplayedField &&
                <div className={"row" + FormUtils.getCSSError(errors, 'always_displayed')}>
                    <p className="col-3 col-form-label">{trans.t("Toujours affiché")}</p>
                    <div className="col-9">
                        <div className="form-switch lh-lg">
                            <input
                                type="checkbox" className="form-check-input" name="always_displayed"
                                checked={values.always_displayed} onChange={onChange}
                            />
                        </div>
                        <FormUtils.ErrorText errors={errors} field='always_displayed' />
                    </div>
                </div>
            }

            {props.proxyChoices &&
            <div className={"row" + FormUtils.getCSSError(errors, 'proxy_restriction')}>
                <p className="col-3 col-form-label">{trans.t("Applicable sur")}</p>
                <div className="col-9">
                    {props.proxyChoices.map(([key, label]) => (
                        <label className="d-block" key={key}>
                            <input
                                type="checkbox" value={key} onChange={onProxyRestrictionChange}
                                checked={values.proxy_restriction.indexOf(key) !== -1}
                            /> &nbsp;{label}
                        </label>
                    ))}
                    <FormUtils.ErrorText errors={errors} field='proxy_restriction'/>
                </div>
            </div>
            }

            {props.sectionChoices &&
                <div className={"row" + FormUtils.getCSSError(errors, 'section')}>
                    <p className="col-3 col-form-label">{trans.t("Section")}</p>
                    <div className="col-9">
                        <select className="form-select" onChange={onChange} name="section"
                                value={values.section}
                        >
                            {props.sectionChoices.map(
                                ([key, label]) => <option value={key} key={key}>{label}</option>
                            )}
                        </select>
                        <FormUtils.ErrorText errors={errors} field='section' />
                    </div>
                </div>
            }

            {displayOccurrences &&
                <div className="row">
                    <p className="col-3 col-form-label">{trans.t("Utilisations")}</p>
                    <div className="col-9 col-form-label">
                    {loadingOccurrences ? 
                        <i className="fa fa-spin fa-spinner"></i>
                    :
                        <div>{occurrences.map((o, index) => (
                            <span key={o.model_name}>
                                <a href={o.redirect_url}>
                                    {o.count} {o.model_name}
                                </a>
                                {index+1 !== occurrences.length && <span> • </span>}
                            </span>
                        ))}</div>
                    }
                    </div>
                </div>
            }
            </Modal.Body>

            <Modal.Footer>
                <a className="btn btn-outline-secondary" onClick={() => props.onModalClose(null)}>{trans.t("Annuler")}</a>&nbsp;
                <button className="btn btn-primary" type="submit">{trans.t("Sauvegarder")}</button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default CustomFieldModalForm;
