import React, {useReducer, useEffect} from 'react';
import moment from 'moment';

import {
    CalendarInput,
    SelectInput,
    SELECT_INPUT_STYLES,
    TimeInput
} from 'orfeo_common/Widgets.jsx';

/** List of operators that doesn't require value from the user */
const UNARY_OPERATORS = ['empty', '~empty', 'future', 'past', 'has_key', '~has_key'];

// Get the filter data when changing the field to filter or the operator
const getFilterData = (filter_definition, filter_data) => {
    if(filter_definition == null){
        return;
    }
    if(filter_definition.type === 'bool' && !filter_definition.allow_blank) {
        filter_data['value'] = true;
    }

    if(filter_definition.type === 'gps'){
        filter_data['operator'] = 'lt';
    }

    return filter_data
}

/**
 * Operators list depends on the field type. For example, numeric comparaison
 * are not available for CharField... `get_operators` returns the subset of
 * operators available for the type given.
 */
const getFilterOperators = function(filter_definition, operators) {
    let field_type = filter_definition.type;

    /** Hardcorded cases, with no variations **/
    var specialTypes = {
        'array': ['iexact', 'empty', '~empty'],
        'number': ['iexact', '~iexact', 'lte', 'gte', 'empty', '~empty'],
        'multiple-choices': ['in', '~in', 'iexact', 'empty', '~empty'],
        'json': ['iexact', '~iexact', 'lte', 'gte', 'empty', '~empty'],
        'time': ['iexact', '~iexact', 'lte', 'gte', 'empty', '~empty'],
    };

    if(field_type in specialTypes) {
        return operators.filter(
            option => specialTypes[field_type].indexOf(option.key) !== -1
        );
    }
    let operators_keys = [];
    if(field_type == 'text') {
        operators_keys = [
            'iexact', '~iexact', 'icontains', '~icontains', 'istartswith', 'iendswith'
        ];
    }
    else if(field_type.indexOf('date') == 0) {
        operators_keys = ['iexact', '~iexact', 'lte', 'gte', 'in_lte', 'gte_ago'];
        if(filter_definition.allow_relative_to_now !== false)
            operators_keys.push('past', 'future')
    }
    else if(['past_date', 'past_datetime'].indexOf(field_type) !== -1 ) {
        operators_keys = ['iexact', '~iexact', 'lte', 'gte', 'gte_ago'];
        if(filter_definition.allow_relative_to_now !== false)
            operators_keys.push('past')
    }
    else if(field_type == 'number') {
        operators_keys = ['iexact', '~iexact', 'lte', 'gte'];
    }
    else if(['fk', 'm2m'].indexOf(field_type) !== -1) {
        operators_keys = ['in', '~in'];
    }
    else if(field_type === 'choices') {
        operators_keys = ['in', '~in', 'iexact'];
    }
    else if(field_type == 'bool') {
        operators_keys = ['iexact'];
    }
    else if(field_type == 'duration') {
        operators_keys = ['iexact', '~iexact', 'lte', 'gte', 'empty', '~empty']
    }

    if(!!filter_definition['excluded_operators'])
        operators_keys = operators_keys.filter(x => !filter_definition['excluded_operators'].includes(x))

    if(filter_definition.allow_blank !== false)
        operators_keys.push('empty', '~empty')

    // Returns operators list ordered according to the keys requested
    return operators_keys.map(
        key => operators.find(x => x.key == key)
    );
}


const initialFiltersState = {
    _uid: 0,
    filters: []
}

function filtersReducer(state, action) {
    switch(action.type) {
        case 'add':
            let data = action.data || {};
            const new_uid = state._uid + 1;
            data['_UID'] = new_uid;
            return Object.assign(
                {}, state,
                {
                    _uid: new_uid,
                    filters: [...state.filters, data]
                }
            );
        case 'remove':
            return Object.assign(
                {}, state,
                {filters: state.filters.filter((el, i) => i !== action.index)}
            );
        case 'update':
            return Object.assign(
                {}, state,
                {
                    filters: state.filters.map(el => {
                        if(el['_UID'] === action.data['_UID']){
                            return action.data;
                        }
                        return el;
                    })
                }
            );
        default:
            throw new Error('Unimplemented action type in FiltersReducer : ' + action);
    }
}

function FilterDefaultOperator(props) {

    function onOperatorChange(ev) {
        let filter_data  = Object.assign(
            props.filter_data, {
                operator: ev.target.value,
                value: ''
            }
        );
        props.onChange(getFilterData(props.filter_definition, filter_data));
    }

    function onBoolChange(ev) {
        // translate string to boolean
        let new_val = {
            'true': true, 'false': false, 'null': null
        }[ev.target.value] || false;
        props.onChange(Object.assign(
            props.filter_data, {value: new_val}
        ));
    }

    function onValueChange(ev) {
        const value = ev.target ? ev.target.value : ev;
        props.onChange(Object.assign(
            props.filter_data,
            {value: value}
        ));
    }

    function getField() {
        let filter_definition = props.filter_definition;
        let filter_data = props.filter_data;

        // No matter the field type, these operator does not take any value
        if(UNARY_OPERATORS.indexOf(filter_data.operator) > -1) {
            return null;
        }

        switch(filter_definition.type) {
            case 'text':
            case 'json':
                return (
                    <input
                        type="text" className="form-control"
                        onChange={onValueChange} value={filter_data.value || ''}
                        disabled={props.disabled}
                    />
                )
            case 'number':
                return (
                    <input
                        type="number" className="form-control" step="0.01"
                        onChange={onValueChange} value={filter_data.value || ''}
                        disabled={props.disabled}
                    />
                );
            case 'bool':
                // We can put boolean as option value in a select tag. So we convert our selection into
                // a string (true -> "true") and vice-versa in the onBoolChange handler.
                return (
                    <select
                        className="form-select"
                        onChange={onBoolChange} value={filter_data.value !== 'undefined' ? String(filter_data.value) : 'true'}
                        disabled={props.disabled}
                    >
                        {filter_definition.allow_blank &&
                            <option value="null">{trans.t("Non indiqué")}</option>
                        }
                        <option value="true">{trans.t("Oui")}</option>
                        <option value="false">{trans.t("Non")}</option>
                    </select>
                );
            case 'date':
            case 'datetime':
            case 'past_date':
            case 'past_datetime':
                // Number input
                if(['in_lte', 'gte_ago'].indexOf(filter_data.operator) !== -1){
                    return(
                    <div className="input-group">
                        <input
                            type="number" className="form-control" step="1"
                            onChange={onValueChange} value={filter_data.value || ''}
                            disabled={props.disabled}
                        />
                        <div className="input-group-text">
                            {trans.t("jours")}
                        </div>
                    </div>
                    )
                }
                return (
                        <CalendarInput
                            defaultValue={filter_data.value || ''} key={filter_data.operator}
                            onChange={val => props.onChange(Object.assign(
                                props.filter_data,
                                {value: val ? val.format('YYYY-MM-DD') : null}
                            ))}
                            maxEndDate={['past_date', 'past_datetime'].indexOf(filter_definition.type) !== -1 ? moment() : null}
                            disabled={props.disabled}
                        />
                    );
            case 'choices':
            case 'multiple-choices':
                const isMultiple = ['in', '~in'].includes(filter_data.operator);
                return (
                    <SelectInput
                        options={
                            filter_definition.choices
                                .map(x => ({'pk': x[0], 'name': x[1]}))
                        }
                        multiple={isMultiple}
                        value={filter_data.component_value || filter_data.value}
                        onChange={data => {
                            props.onChange(
                                Object.assign(props.filter_data, {
                                    component_value: data,
                                    value: isMultiple ? data.map(x => x.pk) : data.pk,
                                })
                            )
                        }}
                        disabled={props.disabled}
                    />
                );
            case 'fk':
            case 'm2m':
                if(
                    ['iexact', '~iexact', 'in', '~in'].indexOf(filter_data.operator) !== -1
                ) {
                    let backend_url = window.location.pathname + 'filter_values/?key=' + filter_definition.key;
                    if(!!filter_definition.requires) {
                        backend_url += '&' + filter_definition.requires.filter(
                            x => !!props.getFilterValue(x)
                        ).map(
                            x => `${x}=${props.getFilterValue(x)}`
                        ).join('&')
                    }

                    const isMultiple = ['in', '~in'].includes(filter_data.operator);
                    return (
                        <SelectInput
                            backendURL={backend_url}
                            multiple={isMultiple}
                            value={filter_data.component_value || filter_data.value}
                            disableClientFiltering
                            onChange={data => {
                                props.onChange(
                                    Object.assign(props.filter_data, {
                                        component_value: data,
                                        value: isMultiple ? data.map(x => x.pk) : data[0].pk,
                                    })
                                )
                            }}
                            disabled={props.disabled}
                        />
                    );
                }

                return (
                    <input
                        type="text" className="form-control"
                        onChange={onValueChange} value={filter_data.value || ''}
                        disabled={props.disabled}
                    />
                );
            case 'duration':
                return (
                    <input
                        type="text" className="form-control"
                        maxLength="7" placeholder="Format (hh:mm)"
                        value={filter_data.value || ''} disabled={props.disabled}
                        onChange={onValueChange}
                    />
                );

            case 'time':
                return (
                    <TimeInput
                        placeholder="HH:mm"
                        className="form-control"
                        value={filter_data.value || ''} disabled={props.disabled}
                        onChange={(value) => onValueChange(value )}
                    />
                );

            default:
                return null;
        }
    }

    let field = getField();

    return (
        <div className="row">
            <div className={field == null ? "col-xs-12" : "col-xs-5"}>
                <select
                    className="form-select"
                    onChange={onOperatorChange}
                    value={props.filter_data.operator || ''}
                    disabled={props.disabled}
                >
                    {getFilterOperators(props.filter_definition, props.operators).map(
                        op => <option value={op.key} key={op.key}>{op.label}</option>
                    )}
                </select>
            </div>
            {field &&
                <div className="col-xs-7" key={props.filter_definition.key}>
                    {field}
                </div>
            }
        </div>
    );

}


function FilterGPSOperator(props){
    function onEntityChange(item) {
        if(!item){
            props.onChange(Object.assign(
                props.filter_data, {
                    value: '',
                    latitude: null,
                    longitude: null
                }
            ));
        } else {
            $.getJSON(
                '/entities/json/get_entity_coordinates?id=' + item['pk'],
                json => props.onChange(Object.assign(
                    props.filter_data, {
                        value: item,
                        latitude: json['lat'],
                        longitude: json['lng']
                    }
                ))
            );
        }
    }

    function onDistanceChange(ev) {
        props.onChange(Object.assign(
            props.filter_data,
            {distance: ev.target.value}
        ));
    }

    return (
        <div className="gps-filter row g-1">
            <div className="col-xs-3 form-group text-center">
                <select
                    className="form-select" value={props.filter_data.operator}
                    onChange={ev => props.onChange(Object.assign(
                        props.filter_data,
                        {operator: ev.target.value}
                    ))}
                    disabled={props.disabled}
                >
                    <option value="lt">{trans.t("à moins de")}</option>
                    <option value="gt">{trans.t("à plus de")}</option>
                </select>
            </div>
            <div className="col-xs-3 text-center">
                <div className="input-group">
                    <input
                        type="text" className="form-control text-end"
                        onChange={onDistanceChange} value={props.filter_data.distance || 50}
                        disabled={props.disabled}
                    />
                    <div className="input-group-text"><small>km de</small></div>
                </div>
            </div>
            <div className="col-xs-6">
                <SelectInput
                    backendURL="/backend/structure/with_address/" initialLoad={false}
                    value={props.filter_data.value} onChange={onEntityChange}
                    disabled={props.disabled}
                />
            </div>
        </div>
    )
}


/** Overriden style of react-select for the field selection **/
const FILTER_SELECT_STYLES = {
    ...SELECT_INPUT_STYLES,
    // Make the options menu a little wider than the select itself
    menu: base => ({...base, width: "110%"}),
    // Increase default height of the options menu
    menuList: base => ({...base, maxHeight: "480px"}),
    groupHeading: base => ({
        ...base,
        'fontWeight': 'bold',
        'color': '#333',
        'backgroundColor': '#f8f8f8',
        'padding': '6px 12px',
        'margin': '0',
    }),
    group: base => ({...base, padding: "0"}),
    // Reduce the padding of each selectable option to make the list more dense
    option: base => ({
        ...base,
        fontSize: '0.95em',
        padding: '6px 20px',
    }),
}

/**
 * Set and update the definition of a filter. Each filter is composed of two parts:
 *  - selection of the field
 *  - extra parameters, that depends of the field selected.
 *
 * In most cases, extra parameters consists of an operator (=, <, >) and a value. Some cases
 * implement a custom parameter handler, for specific filtering (e.g. geolocalisation)
 */
function FilterLine(props) {
    function getFilterDefinition(key) {
        if(!key)
            return null;

        for(let group of props.filterable_fields) {
            let matches = group['fields'].filter(f => f['key'] == key);
            if(matches.length > 0)
                return matches[0];
        }
        return null;
    }

    function onConjonctionChange(ev) {
        const new_data = Object.assign(
            props.filter_data,
            {'conjonction': ev.target.value}
        );
        props.dispatch({type: 'update', data: new_data});
    }

    function getConjonctionColumn(){
        if(props.index === 0){
            return <td className="table-dynamic-list-first-col"></td>;
        }

        return (
            <td>
                <select
                    className='form-select'
                    onChange={onConjonctionChange}
                    value={props.filter_data.conjonction}
                    disabled={props.disabled}
                >
                    <option value="and">{trans.t("ET")}</option>
                    <option value="or">{trans.t("OU")}</option>
                </select>
            </td>
        );
    }

    function onFieldChange(data) {
        let filter_definition = getFilterDefinition(data['key']);
        // Get first operator available
        let operators = getFilterOperators(filter_definition, props.operators);
        let filter_data = Object.assign(
            props.filter_data, {
                key: data['key'],
                // Custom filter like GPS will define their own operator through getFilterData anyway
                operator: operators.length > 0 ? operators[0]['key'] : null,
                value: '',
                component_value: null
            }
        );
        filter_data = getFilterData(filter_definition, filter_data);
        props.dispatch({type: 'update', data: filter_data});
    }

    const current_filter = getFilterDefinition(props.filter_data.key)
    let extra;
    if(!current_filter){
        extra = null;
    }
    else if(current_filter['type'] === "gps") {
        extra = (
            <FilterGPSOperator
                filter_definition={current_filter}
                filter_data={props.filter_data}
                onChange={data => props.dispatch({type: 'update', data})}
                disabled={props.disabled}
            />
        );
    }
    else {
        extra = (
            <FilterDefaultOperator
                operators={props.operators}
                filter_data={props.filter_data}
                filter_definition={current_filter}
                getFilterValue={props.getFilterValue}
                onChange={data => props.dispatch({type: 'update', data})}
                disabled={props.disabled}
            />
        );
    }

    // Construct the react-select value based on the key selected
    let currentSelectValue = null;
    for(let group of props.filterable_fields) {
        currentSelectValue = group['fields'].find(x => x.key == props.filter_data.key);
        // Once it has been found we can break the loop, the key should be unique
        if(!!currentSelectValue) {
            break;
        }
    }

    return (
        <tr>
            {getConjonctionColumn()}

            <td style={{'width': '290px'}}>
                <SelectInput
                    placeholder={trans.t("Sélectionner un champ")}
                    value={currentSelectValue}
                    onChange={onFieldChange}
                    disabled={props.disabled}
                    required
                    autoFocus={props.isLast}
                    optionValueKey="key" labelKey="verbose_name"
                    styles={FILTER_SELECT_STYLES}
                    options={
                        props.filterable_fields.map(group => ({
                            'label': group['label'],
                            'options': group['fields'].map(
                                field => ({
                                    'key': field.key,
                                    'verbose_name': field.verbose_name,
                                    'isDisabled': field.requires && !props.isFieldRequirementsMet(field)
                                })
                            )
                        }))
                    }
                />
            </td>
            <td>
                {extra}
            </td>
            <td>
                {props.index > 0 && !props.disabled &&
                    <button
                        className="btn btn-link text-muted"
                        onClick={() => props.dispatch({type: 'remove', index: props.index })}
                    >
                        <i className="fa fa-trash"></i>
                    </button>
                }
            </td>
        </tr>
    )
}


function FilterModal(props){
    const [state, dispatch] = useReducer(filtersReducer, initialFiltersState);

    useEffect(() => {
        if(props.initials.length > 0) {
            for(let i = 0, l = props.initials.length; i < l; i++){
                dispatch({type: 'add', data: props.initials[i]});
            }
        } else {
            dispatch({
                type: 'add',
                data: {
                    'key': '',
                    'value': '',
                }
            });
        }
    }, []);

    function add_filter() {
        dispatch({type: 'add', data: {'conjonction': 'and'}});
    }

    // Check that all parent fields required for the current field are specified
    // before being able to use the status field
    function isFieldRequirementsMet(field) {
        let keys_used = state.filters.filter(x => !!x.value).map(x => x.key);
        // all keys should appeared in `keys_used` so the result should be empty
        return field.requires.filter(x => keys_used.indexOf(x) === -1).length < field.requires.length;
    }

    function getFilterValue(key){
        let res = state.filters.filter(x => x['key'] === key);
        if(res.length === 0){
            return null;
        }
        return res[0].value;
    }

    return (
        <div className="form-group">
            <table className="table" id="filter-editor-table">
                <tbody>
                {state.filters.map(
                    (filter, index) => (
                        <FilterLine
                            key={filter['key'] + '-' + index}
                            index={index}
                            isLast={index == state.filters.length - 1}
                            filter_data={filter}
                            filterable_fields={props.filterable_fields}
                            operators={props.operators}
                            dispatch={dispatch}
                            isFieldRequirementsMet={isFieldRequirementsMet}
                            getFilterValue={getFilterValue}
                            disabled={props.disabled}
                        />
                    )
                )}
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td colSpan="4">
                            {!props.disabled && <a href="#" onClick={add_filter}>{trans.t("Ajouter un critère")}</a>}
                        </td>
                    </tr>
                </tfoot>
            </table>

            {!props.disabled &&
                <input
                    name="filters" type="hidden"
                    value={JSON.stringify(
                        // Represent filter, removing potential component_value
                        state.filters.map(x => {
                            if(!!x.component_value) {
                                let newX = Object.assign({}, x);
                                delete newX['component_value']
                                return newX;
                            }
                            return x;
                        })
                    )}
                />
            }
        </div>
    );
}

export default FilterModal;
