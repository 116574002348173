import React, { useContext, useMemo, useRef, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import TaskGroupList from 'orfeo/activities/tasks/jsx/board/TaskGroupList';
import { openTaskCreationModal } from 'orfeo/activities/tasks/jsx/board/TaskModalForm';
import FiltersAction from 'orfeo/activities/tasks/jsx/board/FiltersAction';
import { Button, PrimaryButton } from 'orfeo_common/components/forms/Buttons';
import { Searchbar } from "orfeo_common/components/forms/Inputs"
import FiltersSelect from 'orfeo_common/components/forms/FiltersSelect';
import SelectionMode, {SelectionModeContext} from 'orfeo_common/components/SelectionMode'
import { useApi, useDebounce, useSessionState } from 'orfeo_common/hooks'
import { ErrorText } from 'orfeo_common/FormUtils';
import { getModalWrapper } from 'orfeo_common/react-base.jsx';
import Modal from 'orfeo_common/Modal'
import { differenceInDays, format, getDay, parseISO, startOfDay, startOfToday } from 'date-fns';
import classNames from 'classnames';
import { Binoculars, CheckCircle, CheckSquareOffset, ClockClockwise, Plus, Smiley, Trash } from '@phosphor-icons/react';



/**
 *  GLOBAL VARIABLES
 */

const MY_TASKS = 'mytasks'
const ALL_TASKS = 'alltasks'
const NOBODY_PK = -1
const NOBODY_OPTION = {display_name: trans.t('Personne'), pk: NOBODY_PK}

/**
 *  GLOBAL METHODS
 */

const openPostponeModal = (postponeTasks) => {
    const wrapper = createRoot(getModalWrapper());
    wrapper.render(
        <PostponeModal
        onConfirm={(date) => postponeTasks(date).then(() => wrapper.unmount())}
        onCancel={() => wrapper.unmount()}/>
    );
}

/**
 *  SUB-COMPONENTS
 */

const PostponeModal = props => {
    const {
        onConfirm,
        onCancel,
        errors,
    } = props
    const datepickerRef = useRef();

    /**
     *  LOGIC
     */

    useEffect(() => {
        const datepickerCurrentRef = datepickerRef.current

        $(datepickerCurrentRef).datepicker({
            weekStart: '1',
            language: window.LANGUAGE,
            defaultViewDate: startOfToday(),
            todayHighlight: true,
        })

        return () => $(datepickerCurrentRef).datepicker('destroy')
    }, []);

    const handleConfirm = () => {
        const pickedDate = $(datepickerRef.current).datepicker('getDate')
        onConfirm(pickedDate)
    }

    /**
     *  JSX
     */

    return <Modal show onHide={onCancel} modalCSSClass="modal-sm">
        <Modal.Header>
            {trans.t('Reporter les tâches')}
        </Modal.Header>
        <Modal.Body>
            <div ref={datepickerRef} className='mx-auto' style={{width: 'fit-content'}}></div>
            <ErrorText errors={errors} field='due_date'/>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onCancel}>{trans.t('Annuler')}</Button>
            <PrimaryButton onClick={handleConfirm}>{trans.t('Reporter')}</PrimaryButton>
        </Modal.Footer>
    </Modal>
}

const FiltersBlock = props => {
    const {
        onChangeFilters = () => {},
    } = props

    const [currentPresetKey, setPresetKey] = useSessionState("tasks-list-preset", window.location.hash?.slice(1) || MY_TASKS)
    const FILTERS_PRESETS = {
        [MY_TASKS] : {
            search_term : "",
            done : false,
            assigned_to__choices : [USER_PROFILE, NOBODY_OPTION],
            assigned_to__init : [],
            assigned_to__fallback : [USER_PROFILE.pk, NOBODY_PK]
        },
        [ALL_TASKS] : {
            search_term : "",
            done : false,
            assigned_to__choices : [...PROFILES, NOBODY_OPTION],
            assigned_to__init : [],
            created_by__choices : [...PROFILES, NOBODY_OPTION],
            created_by__init : [],
        }
    }

    const currentPreset = FILTERS_PRESETS[currentPresetKey]
    const {isGlobalSelectOn} = useContext(SelectionModeContext)

    const getDefaultFilters = presetKey => {
        let defaultFilters = {}
        
        const preset = FILTERS_PRESETS[presetKey]

        if (preset.done !== undefined) defaultFilters.done = preset.done
        if (preset.search_term !== undefined) defaultFilters.search_term = preset.search_term
        if (preset.assigned_to__init !== undefined) defaultFilters.assigned_to = preset.assigned_to__init
        if (preset.created_by__init !== undefined) defaultFilters.created_by = preset.created_by__init

        return defaultFilters
    }

    const [filters, setFilters] = useSessionState("tasks-list-filters", getDefaultFilters(currentPresetKey))

    const onChangeFiltersDebounced = useDebounce(onChangeFilters, 250)

    /**
     *  LOGIC
     */

    /**
     * Cette méthode prend en paramètre un ensemble de filtres brut dont ne sont gardés que les valeurs
     * significatives pour construire un ensemble de filtres idoine où des valeurs de remplacement peuvent
     * pallier certains champs vides. L'objet résultant est un ensemble de filtres mis au propre et une liste
     * des filtres où une valeur a été renseignée à la main
     */
    function getComputedFilters(rawFilters, presetKey = currentPresetKey) {
        const preset = FILTERS_PRESETS[presetKey]
        let computedFilters = {}
        let dirtiness = false

        // contains a map of computed filters key and their validator function
        const filtersCheck = {
            "done" : null,
            "search_term" : val => val.length > 0,
            "assigned_to" : val => val.length > 0,
            "created_by" : val => val.length > 0,
        }

        for (const [filterKey, validator] of Object.entries(filtersCheck)) {
            const filterValue = rawFilters[filterKey]

            if (filterValue !== undefined && (validator === null || validator(filterValue))) {
                computedFilters[filterKey] = filterValue
                dirtiness = true
            } else if (preset[`${filterKey}__fallback`] !== undefined) {
                computedFilters[filterKey] = preset[`${filterKey}__fallback`]
            }
        }

        return [computedFilters, dirtiness]
    }

    const switchFiltersPreset = presetKey => {
        if (presetKey == currentPresetKey) return
        setPresetKey(presetKey)

        const newFilters = getDefaultFilters(presetKey)

        setFilters(newFilters)
        onChangeFilters(...getComputedFilters(newFilters, presetKey))
    }

    const changeFilter = (filterKey, newValue) => {
        if (filters[filterKey] == newValue) return

        const newFilters = {
            ...filters,
            [filterKey]: newValue
        }

        setFilters(newFilters)
        return newFilters
    }

    const handleCreatedByChange = (selection) => {
        const newFilters = changeFilter('created_by', selection)
        
        onChangeFilters(...getComputedFilters(newFilters))
    }

    const handleAssignedToChange = (selection) => {
        const newFilters = changeFilter('assigned_to', selection)
        
        onChangeFilters(...getComputedFilters(newFilters))
    }

    const handleSearchTermChange = (term) => {
        const newFilters = changeFilter('search_term', term)

        onChangeFiltersDebounced(...getComputedFilters(newFilters))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {onChangeFilters(...getComputedFilters(filters))}, [])
    useEffect(() => {window.location.hash = currentPresetKey}, [currentPresetKey]);

    const labelForOption = option => option.pk == USER_PROFILE.pk ? trans.t('Moi') : option.display_name

    /**
     *  JSX
     */

    return <>
        <ul className="nav nav-pills column-gap-4 fw-bold oo-tab-group" role="tablist">
            <li className="nav-item py-0" role="presentation">
                <button className={classNames('nav-link py-2', {active: currentPresetKey == MY_TASKS})}
                    onClick={() => switchFiltersPreset(MY_TASKS)}
                    data-bs-toggle="pill" type="button" role="tab" aria-selected="true"
                    disabled={isGlobalSelectOn}
                    >
                    {trans.t("Mes tâches")}
                </button>
            </li>
            <li className="nav-item py-0" role="presentation">
                <button className={classNames('nav-link py-2', {active: currentPresetKey == ALL_TASKS})}
                    onClick={() => switchFiltersPreset(ALL_TASKS)}
                    data-bs-toggle="pill" type="button" role="tab" aria-selected="false"
                    disabled={isGlobalSelectOn}
                    >
                    {trans.t("Toutes les tâches")}
                </button>
            </li>
        </ul>

        <div key={currentPresetKey} className='d-flex flex-wrap gap-2 fs-6 align-items-center'> 
            <Searchbar
                value={filters.search_term}
                onChange={term => handleSearchTermChange(term)}
                disabled={isGlobalSelectOn}
                />
            <div className='m-2 vr' />

            {currentPreset.created_by__choices &&
                <FiltersAction
                    name={trans.t('Créée par')}
                    initialSelection={filters['created_by']}
                    optionsData={currentPreset.created_by__choices}
                    quickOptionsIds={[USER_PROFILE.pk, NOBODY_PK]}
                    getOptionLabel={labelForOption}
                    getOptionId={option => option.pk}
                    onChange={selection => handleCreatedByChange(selection)}
                    optionsTypeName={trans.t('pers.')}
                    disabled={isGlobalSelectOn}
                />
            }

            {currentPreset.assigned_to__choices &&
                <FiltersAction
                    name={trans.t('Assignée à')}
                    initialSelection={filters['assigned_to']}
                    optionsData={currentPreset.assigned_to__choices}
                    quickOptionsIds={[USER_PROFILE.pk, NOBODY_PK]}
                    getOptionLabel={labelForOption}
                    getOptionId={option => option.pk}
                    onChange={selection => handleAssignedToChange(selection)}
                    optionsTypeName={trans.t('pers.')}
                    disabled={isGlobalSelectOn}
                />
            }


        </div>
    </>
}

const TasksBlock = props => {
    const {
        tasks = [],
        loading = true,
        onTasksDelete,
        onTasksUpdate,
    } = props

    /**
     *  LOGIC
     */

    const getTaskGroupObject = (label, filter) => ({
        filter: filter,
        label: label,
        tasks: []
    })

    let tasksGroups = useMemo(() => {
        const today = startOfToday()

        let _tasksGroups = [
            getTaskGroupObject(trans.t('En retard'), delta => delta < 0),
            getTaskGroupObject(trans.t('Aujourd\'hui'), delta => delta == 0),
            getTaskGroupObject(trans.t('Cette semaine'), delta => delta < (7 - (getDay(today)+6) % 7)),
            getTaskGroupObject(trans.t('Plus tard'), () => true),
        ]

        tasks.forEach(task => {
            let daysOffset = differenceInDays(startOfDay(task.due_date), today)
            for (let group of _tasksGroups) {
                if (group.filter(daysOffset)) {
                    group.tasks.push(task)
                    break
                }
            }
        })

        _tasksGroups = _tasksGroups.filter(group => group.tasks.length > 0)

        return _tasksGroups
    }, [tasks])

    /**
     *  JSX
     */

    if (loading) {
        return (
            <div className='w-100 text-center p-4'>
                <div className="spinner-grow text-primary" role="status">
                    <span className="visually-hidden">{trans.t('Chargement...')}</span>
                </div>
            </div>
        )
    }

    return (
        tasksGroups.map((group, idx) => (
            <TaskGroupList key={idx}
                name={group.label} tasks={group.tasks}
                onTasksChange={tasks => onTasksUpdate(tasks)}
                onTasksDelete={ids => onTasksDelete(ids)}
                />
        ))
    )
}

/**
 *  MAIN
 */

const TasksListManager = () => {
    const [isLoading, setLoading] = useState(true)
    const [isFiltersDirty, setIsFiltersDirty] = useState(false)
    const [tasks, setTasks] = useState([])
    const {fetchRead, fetchUpdate, fetchDelete} = useApi('/backend/task/')
    const {isGlobalSelectOn, enableGlobalSelect, disableGlobalSelect, selection} = useContext(SelectionModeContext)
    const hasNoResults = tasks.length == 0 && !isLoading

    /**
     * LOGIC
     */

    const getCleanTask = task => ({
        ...task,
        due_date: parseISO(task.due_date),
    })

    const getTasks = (filters) => {
        setLoading(true)

        fetchRead(filters).then(data => {
            setLoading(false)
            setTasks(data.map(getCleanTask))
        })
    }

    const handleFiltersChanged = (filters, dirtiness) => {
        setIsFiltersDirty(dirtiness)
        getTasks(filters)
    }

    const handleTaskCreation = (data) => {
        let newTask = getCleanTask(data)
        let newTasks = [...tasks, newTask]

        const insertAt = tasks.findIndex(task => !task.is_pinned)
        newTasks = [
            ...tasks.slice(0, insertAt),
            newTask,
            ...tasks.slice(insertAt)
        ]

        setTasks(newTasks)
    }

    const handleTasksDeletion = ids => {
        setTasks(tasks.filter(task => (
            !ids.includes(task.pk)
        )))
    }

    /**
     * JSX
     */

    const handleTasksUpdate = (updatedTasks) => {
        let newTasks = tasks.map(task => {
            const updatedTask = updatedTasks.find(newTask => newTask.pk === task.pk)
            return updatedTask ? getCleanTask(updatedTask) : task
        }).sort((task, other) => {
            if (task.is_pinned === other.is_pinned) {
                return task.due_date - other.due_date
            }
            return task.is_pinned ? -1 : 1;
        })

        setTasks(newTasks)
    }

    const postponeTasks = (date) => {
        date = format(date, 'yyyy-MM-dd')
        return fetchUpdate(selection, {due_date: date})
            .then(data => {
                disableGlobalSelect()
                handleTasksUpdate(data)
            })
    }

    const completeTasks = () => {
        fetchUpdate(selection, {done: true})
            .then(data => {
                disableGlobalSelect()
                handleTasksUpdate(data)
            })
    }

    const deleteTasks = () => {
        if(confirm(trans.t('Êtes-vous sûr de vouloir supprimer {{nbTasks}} tâches ?', {nbTasks: selection.length}))) {
            fetchDelete(selection)
                .then(() => {
                    disableGlobalSelect()
                    handleTasksDeletion(selection)
                })
        }
    }

    /**
     * JSX
     */

    return (
        <div className='d-flex flex-column row-gap-4'>
            <div className='d-flex gap-2 align-content-center'>
                <h3 className='flex-grow-1 lh-base m-0'>{trans.t('Tâches')}</h3>

                {!isGlobalSelectOn && tasks.length > 0 &&
                    <button className='btn btn-outline-primary border-0 d-inline-flex align-items-center column-gap-1' onClick={() => enableGlobalSelect()}>
                        <CheckSquareOffset size='16px' weight='bold'/>
                        <span className='ms-1'>{trans.t('Sélectionner')}</span>
                    </button>
                }

                <PrimaryButton disabled={isGlobalSelectOn} onClick={() => openTaskCreationModal(handleTaskCreation)}>
                    <Plus size="16px" weight="bold"/>
                    {trans.t('Ajouter une tâche')}
                </PrimaryButton>
            </div>

            <FiltersBlock
                onChangeFilters={(filters, dirtiness) => handleFiltersChanged(filters, dirtiness)}
            />


            {tasks.length > 0 &&
                <TasksBlock tasks={tasks} loading={isLoading}
                onTasksDelete={ids => handleTasksDeletion(ids)}
                onTasksUpdate={tasks => handleTasksUpdate(tasks)}/>
            }

            {!isFiltersDirty && hasNoResults &&
                <div className='w-100 d-flex align-items-center flex-column gap-3'>
                    <Smiley weight='duotone' size='52px'/>
                    <p className='m-0 fw-bold fs-4 text-secondary'>
                        {trans.t('Aucune tâche à gérer, bravo !')}
                    </p>
                    <PrimaryButton onClick={() => openTaskCreationModal(handleTaskCreation)}>
                        {trans.t('Ajouter une tâche')}
                    </PrimaryButton>
                </div>
            }

            {isFiltersDirty && hasNoResults &&
                <div className='w-100 d-flex align-items-center flex-column gap-3'>
                    <Binoculars weight='duotone' size='52px'/>
                    <p className='m-0 fw-bold fs-4 text-secondary'>
                        {trans.t('Aucun résultat')}
                    </p>
                </div>
            }

            <SelectionMode.Toast>
                <button disabled={selection.length == 0} className='btn btn-dark px-2 py-1 d-inline-flex align-items-center gap-1'
                    onClick={() => {completeTasks()}}>
                    <CheckCircle />
                    <span>{trans.t('Marquer comme terminée')}</span>
                </button>
                <button disabled={selection.length == 0} className='btn btn-dark px-2 py-1 d-inline-flex align-items-center gap-1'
                    onClick={() => {openPostponeModal(postponeTasks)}}>
                    <ClockClockwise />
                    <span>{trans.t('Reporter')}</span>
                </button>
                <button disabled={selection.length == 0} className='btn btn-dark px-2 py-1 d-inline-flex align-items-center gap-1'
                onClick={() => deleteTasks()}>
                    <Trash />
                    <span>{trans.t('Supprimer')}</span>
                </button>
            </SelectionMode.Toast>
        </div>
    )
}



/**
 * TasksListManager is exported with a SelectionMode encapsulation, providing it and its children
 * a context necessary to ensures a generic selection feature
 */

export default function TasksListManagerWrapped () {
    return <SelectionMode><TasksListManager /></SelectionMode>
}
