import React, { useState, useEffect, useRef } from 'react';

import { fetchCsrfWrapper } from 'orfeo_common/react-base.jsx';

const TYPES_DESIGN = {
    "info": {'color': '#31C440', 'icon': 'icon-info'},
    "news": {'color': '#AB8DFF', 'icon': 'icon-bullhorn'},
    "alert": {'color': '#FFC400', 'icon': 'icon-alert'},
    "error": {'color': '#FF507A', 'icon': 'icon-error'},
}

const SESSION_KEY = 'orfeo_read_snackbars'

const Snackbar = props => {
    const obj = props.snackbar;
    const design = TYPES_DESIGN[obj.type];
    const isExternalLink = (obj.link && !obj.link.includes(window.location.host));

    const read = () => {
        // Some notifications aren't stored in the back-end.
        // Mark the them as read in the front session storage only
        if(obj.stored === false) {
            window.sessionStorage.setItem(
                SESSION_KEY,
                (window.sessionStorage.getItem(SESSION_KEY) || []).concat(
                    [obj.id]
                )
            )
            return Promise.resolve();
        }
        let url = `/notifications/snackbar/${obj.id}/read/`
        if(obj.custom){
            url += '?custom=1';
        }
        return fetchCsrfWrapper(
            url, {'method': 'POST'},
        ).catch(
            err => console.error(err)
        )
    }


    const close = () => {
        read().then(() => props.removeSnackbar(props.snackbar));
    }

    const onLinkClick = ev => {
        ev.preventDefault();
        read().then(() => {
            // For external link, open in a new window, because the user will get hiccups
            // if he wants to get back to Orfeo after going on Zendesk link (it will enter
            // in an infinite loop of redirection due to the SSO)
            window.open(obj.link, isExternalLink ? '_blank': '_self');
        });
    }

    return (
        <div className="snackbar-container">
            <div className="snackbar-icon-container">
                <div className={design.icon} />
            </div>
            <div className="snackbar-content-container">
                <div className="snackbar-title">
                    {obj.title}
                </div>
                {!!obj.body && <div className="snackbar-body">
                    {obj.body}
                </div>}
                {!!obj.link && 
                    <div className="snackbar-link">
                        <a onClick={onLinkClick} style={{color: design.color}}>
                            {obj.link_text} {isExternalLink &&
                                <i className="fa-solid fa-arrow-up-right-from-square"></i>
                            }
                        </a>
                    </div>
                }
            </div>
            <div className="snackbar-exit-container">
                <button type="button" className="btn-close" onClick={close}></button>
            </div>
        </div>
    );
}

const SnackbarManager = props => {
    const [displayedSnackbar, setDisplayedSnackbar] = useState(props.snackbars);
    const containerRef = useRef();

    useEffect(() => {
        // Only display the snack after a second.
        const timer = setTimeout(() => {
            containerRef.current.classList.add('show');
        }, 1000);

        const sessionReadSnackbars = window.sessionStorage.getItem(SESSION_KEY);
        if(sessionReadSnackbars && sessionReadSnackbars.length > 1) {
            setDisplayedSnackbar(displayedSnackbar.filter(
                x => !sessionReadSnackbars.includes(x.id)
            ))
        }

        return () => clearTimeout(timer);
    }, []);

    const removeSnackbar = snackbar => {
        setDisplayedSnackbar(displayedSnackbar.filter(x => x.id !== snackbar.id));
    }

    return (
        <div className="snackbars-container" ref={containerRef}>
            {displayedSnackbar.slice(0, 2).map(snackbar => (
                <Snackbar
                    key={`${snackbar.id}${snackbar.custom ? "-custom" : ""}`} snackbar={snackbar}
                    removeSnackbar={removeSnackbar}
                />
            ))}
        </div>
    )
}


export default SnackbarManager;
