import { useEventListener } from 'orfeo_common/hooks'
import React, { useId, useRef } from "react"
import { CaretDown } from '@phosphor-icons/react'
import classNames from 'classnames';



/**
 * Provides a generic component to display a collapsible menu. Use it as such :
 * 
 * <CollapsibleMenu>
 *   <CollapsibleMenu.Header>
 *     -> Menu Header content which will be the toggling part of the collapsible
 *   </CollapsibleMenu.Header>
 * 
 *   <CollapsibleMenu.HeaderSecondary>
 *     -> Optional component on the Menu Header far right whose content won't toggle the collapsible
 *   </CollapsibleMenu.HeaderSecondary>
 * 
 *   <CollapsibleMenu.Body>
 *     -> Menu Body content
 *   </CollapsibleMenu.Body>
 * </CollapsibleMenu>
 *
 * @param onToggle callback called when the menu toggling is changed (before animation)
 * @param onToggled callback called when the menu toggling has been changed (after animation)
 * @param open initial opening state
 */

const CollapsibleMenu = (props) => {
    const {
        children,
        onToggle = () => {},
        onToggled = () => {},
        isOpen = false
    } = props

    const id = useId()
    const ref = useRef()

    /**
     *  LOGIC
     */

    useEventListener('hide.bs.collapse', _ => onToggle(false), ref)
    useEventListener('show.bs.collapse', _ => onToggle(true), ref)

    useEventListener('hidden.bs.collapse', _ => onToggled(false), ref)
    useEventListener('shown.bs.collapse', _ => onToggled(true), ref)

    const header = React.Children.toArray(children).find(child => child.type === CollapsibleMenu.Header);
    const headerSecondary = React.Children.toArray(children).find(child => child.type === CollapsibleMenu.HeaderSecondary);
    const body = React.Children.toArray(children).find(child => child.type === CollapsibleMenu.Body);

    /**
     *  JSX
     */

    return <div className='d-flex flex-column p-4 gap-3 oo-collapsible-menu'>
        <div className='d-flex flex-nowrap'>
            <div role="button" data-bs-toggle="collapse" data-bs-target={`#${id}`} aria-expanded={isOpen} aria-controls={id} className={classNames('flex-0 flex-grow-1 d-flex align-items-center gap-3', {collapsed: !isOpen})}>
                <CaretDown className='oo-collapsible-icon'/>
                {header && header}
            </div>

            {headerSecondary &&
                <div className='flex-shrink-1'>
                    {headerSecondary}
                </div>
            }
        </div>

        <div className={classNames("collapse", {show: isOpen})} id={id} ref={ref}>
            {body}
        </div>
    </div>
}

CollapsibleMenu.Header = ({children}) => (<>{children}</>)
CollapsibleMenu.HeaderSecondary = ({children}) => (<>{children}</>)
CollapsibleMenu.Body = ({children}) => (<>{children}</>)

export default CollapsibleMenu