import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';

// Time (msec) the submit button of a form is disabled to avoid double/triple submit
const SUBMIT_BTN_FREEZE_TIME = 600;
const EDITING_KEY = 'editing';

/* Helper to check if the current client is in read-only mode or not */
const isReadOnly = () => !!window['READONLY'] || !!window['OBJ_ARCHIVED']

/* Helper to check if the current client is admin or not */
const isAdmin = () => !!window['ADMIN'];

/**
    Custom React hook that handle the change of values of inputs automatically
    and allow close/submit of form easily
**/
function useForm(initialValues, submitCallback, cancelCallback, allowSubmitTroughKeyUp) {
    const [values, setValues] = useState(initialValues || {});
    const [pending, setPending] = useState(false);

    const triggerSaveCallback = form_node => {
        // Avoid multiple submit by early returning the callback.
        if(pending)
            return;

        // Block the submit button to avoid any double validation
        let buttons = form_node.querySelectorAll('button[type="submit"]');
        for(let btn of buttons) {
            btn.disabled = true;
        }

        function releaseForm() {
            for(let btn of buttons) {
                btn.disabled = false;
            }
            setPending(false);
        }

        setPending(true);
        let promise = submitCallback();

        // If promise is given, release the submit as soon as possible
        if(promise !== undefined) {
            promise.finally(releaseForm)
        }
        // ... in any case, release the form after a freeze time so the user can re-submit (in case of unexpected errors)
        setTimeout(releaseForm, SUBMIT_BTN_FREEZE_TIME)
    }

    const onSubmit = ev => {
        if(ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        triggerSaveCallback(ev.target)
    }

    const onKeyUp = ev => {
        if(ev.key == 'Escape')
            cancelCallback();

        if(allowSubmitTroughKeyUp && ev.key == 'Enter') {
            // Apply automatic submit on textareas if the user is also pressing the CTRL key
            // otherwise it would not be possible to break lines
            if(ev.target.nodeName == "TEXTAREA" && !ev.ctrlKey)
                return;

            ev.stopPropagation();
            ev.preventDefault();

            let form = ev.target.closest('form');
            if(!form) {
                // some React components included in a table can't have a form englobing all
                // fields. The form might be a child of the `<tr>` being the ev.currentTarget
                form = ev.currentTarget.querySelector('form');
            }

            triggerSaveCallback(form)
        }
    }

    /** onChange might accept two types of data:
        - an HTML event, fired by an HTML input with a name & a value
        - a two parameters couple, which correspond to the key and the new value
    */
    const onChange = useCallback((arg1, arg2) => {
        setValues(values => {
            let dataModified = {};
            if(arg1 && arg1.hasOwnProperty('target')) {
                arg1.persist();
                // Use "checked" only for checkbox, otherwise fetch value attr
                dataModified[arg1.target.name] = arg1.target[arg1.target.type == "checkbox" ? "checked" : "value"];
            }
            else if (typeof arg2 == "function") {
                dataModified[arg1] = arg2(values[arg1])
            }
            else {
                dataModified[arg1] = arg2;
            }
            return Object.assign({}, values, dataModified)
        })
    }, [])

    return {values, onChange, onKeyUp, onSubmit}
}

function onKeyUpFactory(cancelCallback, submitCallback) {
    const func = ev => {
        if(ev.key == 'Escape')
            cancelCallback();
        if(ev.key == 'Enter') {
            ev.stopPropagation();
            submitCallback(ev);
        }
    }
    return func;
}

const _hasTextSelected = () => {
    // If the user has selected text inside the block, do not set editing on click:
    // he probably wants to copy the text! Changing the state might might make him
    // lose the selection.
    let text;
    if (window.getSelection) {
        text = window.getSelection().toString();
    } else if (document.selection && document.selection.type != "Control") {
        text = document.selection.createRange().text;
    }
    return text.length > 2;
}

function useFormEditing(initialValue, localReadyOnlyCallback) {
    const [isEditing, setIsEditing] = useState(initialValue || false);
    const toggler = useCallback(given_value => {
        if(
            // Read-only app-wide
            !isReadOnly()
            // Read-only for the given component
            && (!localReadyOnlyCallback || !localReadyOnlyCallback())
        ) {
            setIsEditing(value => {
                let new_value = !value;
                // If a value is given, we check if it's not undefined or a React event, that
                // could be pass by using onClick={setEditing} without arrow function wrapper
                // In a future version we should restrict given_value to a boolean value
                // for consistency check
                if(given_value !== undefined && given_value?.nativeEvent === undefined)
                    new_value = given_value

                // Prevent opening form when text has been selected, potentially to copy it
                if(new_value === true && _hasTextSelected())
                    new_value = false;

                return new_value;
            })
        }
    });

    return [isEditing, toggler];
}

function useFormErrors(defaultErrors) {
    const [errors, setErrors] = useState(defaultErrors || {});
    const setErrorsFromXHR = rawData => setErrors(getErrorsDict(rawData))

    return [errors, setErrorsFromXHR]
}


function setEditing(val) {
    // In read only mode, the user should not be able to edit anything
    if(isReadOnly())
        return false;

    if(!!val && _hasTextSelected()) {
        return false;
    }

    this.setState({[EDITING_KEY]: val});
    return true;
}

function getCSSError(errors, fields) {
    if(!Array.isArray(fields))
        fields = [fields];

    for(let field of fields)
        if(field in errors)
            return ' has-error';
    return '';
}

function getGlobalErrors(errors) {
    if(errors['__all__'] !== undefined) {
        if(!Array.isArray(errors['__all__']))
            return errors['__all__'];

        return errors['__all__'].join(', ');
    }
    else if(errors['non_field_errors'] !== undefined) {
        return errors['non_field_errors'].join(', ');
    }
    return '';
}

function getErrorsDict(errors) {
    // Sometimes, our sever might be down OR the user connection might be disconnected
    // In those cases, empty responses are returned in XHR queries, thus, we interpret
    // them as "connection failure". Display a generic msg to the user then.
    if(!errors)
        return {'__all__': [trans.t("Une erreur inconnue s'est produite.")]}

    if(Array.isArray(errors))
        errors = errors[0];

    if(typeof errors === 'string')
        errors = {'__all__': [errors]};

    if(errors['response'] && errors['response'] instanceof Object) {
        delete errors['response'];
    }

    return errors;
}

function ErrorText(props) {
    if(!props.errors || !props.field)
        return "";

    let text;
    if(props.errors[props.field] !== undefined) {
        // Specific handling for sub-serializer fields, that can issue a list of errors
        if(props.subfield && props.index !== undefined) {
            let line_data = props.errors[props.field][props.index];
            if(line_data && line_data[props.subfield])
                text = line_data[props.subfield];
        }
        else if(props.errors[props.field].length > 0) {
            text = props.errors[props.field];
        }
    }
    if(!!text) {
        if(text instanceof Array) {
            text = text.join(', ');
        }
        return <p className="text-danger my-0">{text}</p>;
    }

    return "";
}


/* Handle a single lateral form instance at once */
const CLOSE_EVENT_NAME = 'orfeo.close-lateral-form'
const openLateralForm = component => {
    let container = document.getElementById('content');
    let wrapper = container.querySelector('div.lateral-right-form');

    // If the wrapper already exist, unmount any existing form already paint in
    if(wrapper) {
        closeLateralForm();
    }
    else {
        // First form opening, build a div to host our lateral form
        wrapper = document.createElement('div');
        wrapper.classList.add('lateral-right-form')
    }

    container.appendChild(wrapper);

    // Attach listener to allow component to close the lateral form
    wrapper.addEventListener(CLOSE_EVENT_NAME, () => {
        ReactDOM.unmountComponentAtNode(wrapper);
        container.removeChild(wrapper);
    }, {once: true});

    ReactDOM.render(component, wrapper);
};


const closeLateralForm = () => {
    let element = document.querySelector('#content div.lateral-right-form');
    if(element)
        element.dispatchEvent(new Event(CLOSE_EVENT_NAME));

    return;
}


/**
 * Format an input with a bootstrap5 floating label properly placed. Beware to
 * set a placeholder on the child input to make it work
 */
const FloatingLabel = props => {
    // Automatically assign an empty placeholder if none given
    let children = props.children;
    if(!children.placeholder) {
        children = React.Children.map(
            children,
            child => React.cloneElement(child, {placeholder: ''})
        )
    }

    return (
        <div className={"form-floating " + props.className || ''}>
            {children}
            <label htmlFor="floatingInput">{props.label}</label>
        </div>
    );
};

export {
    getCSSError,
    getGlobalErrors,
    getErrorsDict,
    ErrorText,
    FloatingLabel,

    isReadOnly,
    isAdmin,
    setEditing,
    onKeyUpFactory,

    openLateralForm,
    closeLateralForm,

    // React hook variant
    useForm,
    useFormEditing,
    useFormErrors,
};
