/**
 * Manage list of contacts linked to any objects using the LinkedContactBase model.
 *
 * Even though this component is generic, we set it in this folder as it is
 * related to the entities app. However, it should be include in a generic bundle,
 * so it can be use by any part of the application.
 */
'use strict';

import React from 'react';
import classNames from 'classnames';

import {
    AjaxNotification,
    ConditionalLink,
    GenericListStoreFactory,
    hasPerm,
} from 'orfeo_common/react-base.jsx';
import { EntityComplexTypeahead } from 'orfeo_common/Widgets.jsx';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { getContactInfoRepresentation } from 'orfeo/entities/jsx/classes/Utils.jsx';
import Tooltip from 'orfeo_common/Tooltip.jsx';

const LinkedContactStore = GenericListStoreFactory('pk', 'order_index');

class LinkedContactForm extends React.Component {

    constructor(props) {
        super(props);

        this.onKeyUp = this.onKeyUp.bind(this);
        this.onSave = this.onSave.bind(this);

        let obj = this.props.obj || {};
        this.state = {
            'entity': (obj.structure_person && obj.structure_person.person) || obj.entity || null,
            'structure_person': obj.structure_person || null,
            'notes': obj.notes || '',
            'errors': {},
        };
        if(this.props.roles)
            this.state['role'] = obj.role || this.props.roles[0][0];
        if(this.props.publishable){
            this.state['published'] = !!obj.published
        }
    }

    onKeyUp(ev) {
        if(ev.key == 'Escape')
            this.props.onCancel();
    }

    onSave(ev) {
        ev.preventDefault();

        let post_data = {
            'role': this.state.role,
            'notes': this.state.notes,
        }
        if(this.state.structure_person != null)
            post_data['structure_person.pk'] = this.state.structure_person.pk;
        else
            post_data['entity.pk'] = (this.state.entity || {}).pk;

        if(this.props.publishable){
            post_data['published'] = this.state['published'];
        }

        let url = this.props.backend_url, method = 'POST';
        if(this.props.obj != null) {
            url += this.props.obj.pk + '/';
            method = 'PATCH';
        }
        else {
            post_data['parent'] = this.props.parent_id;
        }

        let notif_id = AjaxNotification.show(trans.t('Sauvegarde...'));
        $.ajax({
            url: url, method: method, data: post_data
        }).success(
            (data, status) => this.props.onSuccess(data)
        ).error(
            (data, status) => this.setState({'errors': FormUtils.getErrorsDict(data.responseJSON)})
        ).always(
            () => AjaxNotification.hide(notif_id)
        )
    }

    onEntityChange(data) {
        this.setState({
            'entity': data,
            'structure_person': (data || {}).structure_person || null
        })
    }

    render () {
        let obj = this.props.obj || {};

        // The layout of the form depend of the presence (or not) of the roles input.
        let entity_div = (
            <div className="col-md-6">
                <EntityComplexTypeahead
                    entity={this.state.entity}
                    onChange={this.onEntityChange.bind(this)}
                    show_add_entity
                />
                <FormUtils.ErrorText errors={this.state.errors} field="entity" />
            </div>
        )
        let notes_input = (
            <input
                className="form-control input-sm" placeholder={trans.t("Notes")}
                type="text" value={this.state.notes}
                onChange={ev => this.setState({'notes': ev.target.value})}
            />
        )
        return (
            <form className="form react-inline-form" onSubmit={this.onSave} onKeyUp={this.onKeyUp}>
                {Object.keys(this.state.errors).length > 0 &&
                    <p className="react-form-errors-intro">{trans.t("Erreur à la sauvegarde.")} {FormUtils.getGlobalErrors(this.state.errors)}</p>
                }

                {this.props.roles ? [
                        <div className="row mb-2" key='line1'>
                            <div className="col-md-6">
                                <select
                                    className="form-select input-sm" value={this.state.role}
                                    onChange={ev => this.setState({'role': ev.target.value})}
                                >
                                    {this.props.roles.map(
                                        ([key, label]) => <option value={key} key={key}>{label}</option>
                                    )}
                                </select>
                            </div>
                            {entity_div}
                        </div>,
                        <div className="row" key='line2'>
                            <div className="col-md-12">{notes_input}</div>
                        </div>
                    ]
                :
                    <div className="row">
                        {entity_div}
                        <div className="col-md-6">{notes_input}</div>
                    </div>
                }

                {this.props.publishable && 
                    <p>
                        <Tooltip title={trans.t("Le contact sera visible par les participants depuis l'accès invité et l'application Orfeo Mobile")}>
                            <label>
                                <input
                                    type="checkbox" checked={this.state.published}
                                    onChange={ev => this.setState({'published': ev.target.checked})}
                                />&nbsp;
                                {trans.t("Contact visible par les participants")}
                            </label>
                        </Tooltip>
                    </p>
                }


                <div className="actions text-end">
                    <a className="btn btn-outline-secondary" onClick={this.props.onCancel}>{trans.t("Annuler")}</a>&nbsp;
                    <button className="btn btn-primary">{trans.t("Sauvegarder")}</button>
                </div>
            </form>
        );
    }
}


const LinkedContactLine = props => {
    const isReadOnly = FormUtils.isReadOnly() || props.isReadOnly;
    const [editing, setEditing] = FormUtils.useFormEditing(false, () => isReadOnly);

    const onDelete = ev => {
        ev.stopPropagation();
        if(confirm(trans.t('Êtes-vous sûr de vouloir retirer cette ligne ?'))) {
            $.ajax({
                url: props.backend_url + props.obj.pk + '/', method: 'DELETE'
            }).then(
                () => LinkedContactStore.delete(props.obj.pk)
            )
        }
    }

    if(editing)
        return (
            <tr>
                <td colSpan="5">
                    <LinkedContactForm
                        backend_url={props.backend_url}
                        roles={props.roles}
                        obj={props.obj}
                        onSuccess={data => {
                            LinkedContactStore.update(data)
                            setEditing(false);
                        }}
                        onCancel={() => setEditing(false)}
                        publishable={props.publishable}
                    />
                </td>
            </tr>
        );

    let sp = props.obj.structure_person;
    const contact_infos = props.obj.entity ? props.obj.entity.entity_contact_infos : props.obj.structure_person.structure_contact_infos;
    return (
        <tr className={!isReadOnly ? "inline-editable": ""} onClick={setEditing} data-id={props.obj.pk}>
            {!isReadOnly &&
            <td className="text-center" style={{'width': '30px'}} onClick={ev => ev.stopPropagation()}>
                <span className="reorder-icon">
                    <i className="fa fa-bars"></i>
                </span>
            </td>}
            {(!!props.publishable && ACCOUNT_TYPE !== 'guest') &&
                <td className="text-center" style={{width: '30px'}}>
                {props.obj.published ? 
                    <Tooltip title={trans.t("Ce contact est visible par les participants")}>
                        <i className="fa fa-eye" style={{color: "green"}}/>
                    </Tooltip>
                :
                    <Tooltip title={trans.t("Ce contact n'est pas visible par les participants")}>
                        <i className="fa fa-eye-slash" />
                    </Tooltip>
                }
                </td>

            }
            {props.roles &&
                <td>{props.roles.filter(item => item[0] == props.obj.role)[0][1]}</td>
            }
            <td>
                {props.obj.entity ?
                    <ConditionalLink
                        href={props.obj.entity.absolute_url} onClick={ev => ev.stopPropagation()}
                        condition={ACCOUNT_TYPE == 'standard'}
                    >
                        {props.obj.entity.name}
                    </ConditionalLink>
                :
                    <ConditionalLink
                        href={sp.person.absolute_url} onClick={ev => ev.stopPropagation()}
                        condition={ACCOUNT_TYPE == 'standard'}
                    >
                        {sp.person.name}&nbsp;
                        <small>({sp.structure.name}{sp.title && (", " + sp.title)})</small>
                    </ConditionalLink>
                }
                {contact_infos.length > 0 &&
                    <div className="contact-infos-list">
                        {contact_infos.map(ci => getContactInfoRepresentation(ci))}
                    </div>
                }
            </td>
            <td>{props.obj.notes}</td>
            {!isReadOnly &&
            <td style={{'width': '32px'}}>
                <a onClick={onDelete} style={{"display": "inline-block", "padding": "2px 10px"}}>
                    <i className="fa fa-trash"></i>
                </a>
            </td>}
        </tr>
    )
}


class LinkedContactManager extends React.Component {

    constructor(props) {
        super(props);

        this.setEditing = FormUtils.setEditing.bind(this);

        LinkedContactStore.addOnListChange(lst => this.setState({'links': lst}))
        this.state = {
            'links': LinkedContactStore.getList(),
            'loaded': false,
            'editing': false
        };
    }

    componentDidMount() {
        $.ajax({
            url: `${this.props.backend_url}?parent=${this.props.parent_id}`, method: 'GET'
        }).success(
            (data, status) => this.setState({'loaded': true}, () => LinkedContactStore.setList(data))
        );
    }

    componentDidUpdate() {
        if(this.state.links.length == 0 || this.state.sortable_initialized)
            return

        if(!this.props.isReadOnly){
            // Allow the re-ordering of the list
            var dom = $('tbody', this.refs.list);
            dom.sortable({
              start: function (event, ui) {
                if(this.state.editing)
                    return false;

                document.body.style.cursor = 'move';
              }.bind(this),
              stop: function (event, ui) {
                var order = dom.sortable("toArray", {attribute: "data-id"});
                dom.sortable("cancel");

                document.body.style.cursor = 'default';
                this.handleReorder(order, ui.item.data('id'));
              }.bind(this)
            });
        }
        this.setState({'sortable_initialized': true});
    }

    handleReorder(newOrder, pkMoved) {
        // Don't make any requests if the pkMoved is in the place than before
        var old_position = -1;
        for(var i = 0, l = this.state.links.length; i < l; i++) {
            if(this.state.links[i].pk == pkMoved) {
                old_position = i;
                break;
            }
        }
        var new_position = newOrder.indexOf(pkMoved.toString());
        if(new_position == old_position) {
            return;
        }

        function updateList(newOrder) {
            // Move the element in the list and then update status
            var lines = LinkedContactStore.getList();
            for(var i = 0; i < lines.length; i++) {
                lines[i].order_index = newOrder.indexOf(lines[i].pk.toString())
            }
            LinkedContactStore.setList(lines);
        }

        // Run Ajax update
        var notif_id = AjaxNotification.show(trans.t('Sauvegarde...'));
        $.ajax({
            url: `${this.props.backend_url}change_order/`, method: "PATCH", dataType: "json",
            data: {'new_order': JSON.stringify(newOrder), 'parent': this.props.parent_id}
        }).success(
            (data, status) => updateList(newOrder)
        ).error(
            (data, status) => alert(trans.t('Erreur lors de l\'enregistrement'))
        ).always(
            () => AjaxNotification.hide(notif_id)
        );
    }

    render() {
        let add_block;
        const publishable = (
            this.props.parentType === "project"
            && (
                hasPerm('production.production_distribution_access')
                || hasPerm('production.project_roadmap_access')
            )
        );
        if(this.state.editing) {
            add_block = (
                <LinkedContactForm
                    obj={null}
                    backend_url={this.props.backend_url}
                    parent_id={this.props.parent_id}
                    roles={this.props.roles}
                    onSuccess={data => {
                        this.setState({'editing': false});
                        LinkedContactStore.add(data)
                    }}
                    onCancel={() => this.setEditing(false)}
                    publishable={publishable}
                />
            );
        }

        return (
            <div className="information-block">
                <h4>
                    {this.props.block_title}
                    {(!FormUtils.isReadOnly() && !this.props.isReadOnly && !this.state.editing) &&
                    <a className="pointer add-link action-link" onClick={this.setEditing}>{trans.t("Ajouter")}</a>}
                </h4>

                {this.state.links.length > 0 &&
                <table className="table table-condensed" ref="list">
                    <tbody>
                        {this.state.links.map(
                            link => (
                                <LinkedContactLine
                                    obj={link} key={link.pk}
                                    backend_url={this.props.backend_url}
                                    parent_id={this.props.parent_id}
                                    isReadOnly={this.props.isReadOnly}
                                    roles={this.props.roles}
                                    publishable={publishable}
                                />
                            )
                        )}
                    </tbody>
                </table>}
                {!this.state.loaded &&
                    <p className="text-muted">
                        <i className="fa faspin fa-spinner"></i> {trans.t("Chargement des éléments")}
                    </p>
                }
                {add_block}
            </div>
        )
    }
}

export default LinkedContactManager;
