import React, { useMemo, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import * as FormUtils from "orfeo_common/FormUtils.jsx";
import {
    AjaxNotification,
    floatFormat,
    GenericListStoreFactory,
    getModalWrapper,
    useListOrdering
} from "orfeo_common/react-base";
import CustomFieldModalForm from './CustomFieldModalForm.jsx';
import { CustomFieldStore } from './Utils.jsx';

// Get value from list of 2-tuples [key, value] according to key given
const _getValueFromKey = (lst, key) => {
    let res = lst.find(t => t[0] == key)
    return res ? res[1] : "";
}

const CustomField = props => {
    const obj = props.obj;

    const onModalOpen = () => {
        let wrapper = getModalWrapper();
        ReactDOM.render(
            <CustomFieldModalForm
                obj={obj}
                proxyChoices={props.proxyChoices}
                sectionChoices={props.sectionChoices}
                excludeAlwaysDisplayedField={props.excludeAlwaysDisplayedField}
                onModalClose={data => {
                    ReactDOM.unmountComponentAtNode(wrapper);
                    if(data)
                        store.update(data)
                }}
            />
        , wrapper);
    }

    let choices_str;
    if(obj.choices && obj.choices.length > 0) {
        let nb_choices = obj.choices.length;
        if(nb_choices > 8){
            choices_str = trans.t(
                "{{displayed_choices}} et {{other_choices_count}} autres",
                {
                    displayed_choices: obj.choices.slice(0, 6).join(', '),
                    other_choices_count: nb_choices - 6
                }
            );
        }
        else
            choices_str = obj.choices.join(', ');
    }

    return (
        <tr data-id={obj.pk} className="inline-editable" onClick={() => onModalOpen()}>
            <td onClick={ev => ev.stopPropagation()} className="action-checkbox text-center">
                <input name="_selected_action" className="action-select" type="checkbox" value={obj.pk} />
            </td>
            <td style={{width: '22px'}} className="reorder-icon" onClick={ev => ev.stopPropagation()}>
                <i className="fa fa-bars" />
            </td>
            <td>{obj.label}</td>
            <td>{FIELD_TYPES.filter(x => x[0] == obj.field_type)[0][1]}</td>
            {!props.excludeAlwaysDisplayedField && <td className="text-center">
                <i className={"svg-icon-" + (obj.always_displayed ? "yes" : "no")}></i>
            </td>}
            {props.proxyChoices &&
                <td>
                    {obj.proxy_restriction ?
                        obj.proxy_restriction.map(x => _getValueFromKey(props.proxyChoices, x)).join(', ')
                    :
                    "-"
                    }
                </td>
            }
            <td>{choices_str}</td>

            {props.sectionChoices &&
                <td>
                    {obj.section && _getValueFromKey(props.sectionChoices, obj.section)}
                </td>
            }
            <td>
                {floatFormat(obj.nb_occurrences)}
            </td>
            <td>
                {obj.creation_str}
            </td>
            <td onClick={ev => ev.stopPropagation()} className="text-center">
                <a href={obj.delete_url}>
                    <i className="fa fa-trash" />
                </a>
            </td>
        </tr>
    );
}


const CustomFieldsList = props => {
    const [customFields, setCustomFields] = useState([]);

    useEffect(() => {
        if(props.sectionChoices) {
            let sectionsKeys = props.sectionChoices.map(x => x[0]);
            // Handle section ordering
            CustomFieldStore.sort_function = (x, y) => {
                let sectionX = sectionsKeys.indexOf(x.section),
                    sectionY = sectionsKeys.indexOf(y.section);
                if(sectionX != sectionY)
                    return sectionX - sectionY;

                return x.order_index - y.order_index;
            }
        }

        CustomFieldStore.addOnListChange(lst => {
            setCustomFields(lst);
            CustomFieldStore.sortList();

            setTimeout(
                () => document.dispatchEvent(new CustomEvent('Orfeo.loadActions')),
                10
            )
        });
        CustomFieldStore.setList(props.customFields);
    }, []);

    const handleNewOrder = newOrder => {
        newOrder = newOrder.filter(id => id !== '');
        const customFields = CustomFieldStore.getList();
        for (let i = 0; i < customFields.length; i++){
            customFields[i].order_index = newOrder.indexOf(customFields[i].pk.toString());
        }
        CustomFieldStore.setList(customFields);
    };

    const listRef = useListOrdering(
        '/backend/customfield/change_order/',
        null,
        handleNewOrder,
        true,
    );

    return (
        <table className="table">
            <thead>
                <tr>
                    <th className="action-checkbox-column text-center" style={{width: '22px'}}>
                        <input
                            type="checkbox"
                            className="action-select"
                            onChange={ev => {
                                // Manually trigger checkbox as their no internal state there
                                listRef.current.querySelectorAll('.action-select').forEach(node => {
                                    node.checked = ev.target.checked;
                                })
                            }}
                        />
                    </th>
                    <th style={{width: '22px'}}></th>
                    <th className="column-name">{trans.t("Nom")}</th>
                    <th className="column-name">{trans.t("Type")}</th>
                    {!props.excludeAlwaysDisplayedField && 
                        <th className="column-name">{trans.t("Toujours affiché")}</th>
                    }
                    {props.proxyChoices &&
                        <th className="column-name">{trans.t("Applicable sur")}</th>
                    }
                    <th className="column-name">{trans.t("Choix")}</th>
                    {props.sectionChoices &&
                        <th className="column-name">{trans.t("Section")}</th>
                    }
                    <th className="column-name">
                        {trans.t("Nb utilisations")} 
                    </th>
                    <th className="column-name">
                        {trans.t("Création")}
                    </th>
                    <th className="col-md-1"></th>
                </tr>
            </thead>
            <tbody ref={listRef}>
            {customFields.map((field, idx) => (
                <CustomField
                    obj={field}
                    proxyChoices={props.proxyChoices}
                    sectionChoices={props.sectionChoices}
                    excludeAlwaysDisplayedField={props.excludeAlwaysDisplayedField}
                    key={"custom-field-" + idx}
                />
            ))}
            </tbody>
        </table>
    );
};

export default CustomFieldsList;
