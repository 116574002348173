import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import moment from 'moment';

import Tooltip from 'orfeo_common/Tooltip.jsx';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { getNoteContent } from 'orfeo_common/react-notes.jsx';
import { fetchCsrfWrapper } from 'orfeo_common/react-base.jsx';

/**
 * Button used to open the notifications center.
 */
const NotificationsCenterButton = props => {
    const container = document.querySelector('#notification-center-opener');

    const [containerEmptied, setContainerEmptied] = useState(false);

    useEffect(() => {
        container.innerHTML = '';
        setContainerEmptied(true);
    }, []);

    if(!containerEmptied){
        return null;
    }
    return (
        ReactDOM.createPortal(
            <div onClick={props.onClick}>
                <i className={classNames('fa-regular fa-bell', {'icon-badge': props.hasNotification})}></i>
            </div>,
            container
        )
    )
}

const NotificationTask = props => {
    const task = props.obj.task;

    const onCheck = ev => {
        if(!task.done){
            fetchCsrfWrapper(
                `/backend/notification/${props.obj.pk}/complete_task/`,
                {'method': 'POST'},
                {}
            ).then(
                data => props.onTaskCompleted(data.task)
            ).catch(
                data => alert(data)
            );
        }
    }

    return (
        <div className="notification-task-container">
            <div>
                {props.withCheckbox && <input 
                    type="checkbox" style={{marginRight: "0.5em"}}
                    checked={task.done} readOnly={props.readOnly}
                    onChange={onCheck} onClick={ev => ev.stopPropagation()}
                />}

                {task.title}
            </div>

            <div style={{whiteSpace: 'nowrap'}}>
                <i className="fa fa-calendar" style={{marginRight: '0.5em'}}></i>
                {moment(task.due_date).format("DD/MM/YYYY")}
            </div>
        </div>
    );
} 

const NotificationItem = props => {
    const obj = props.notification;

    const date = moment(obj.creation_date);
    let dateString = "";
    if(date.isSame(moment(), 'day')){
        dateString = trans.t("Aujourd'hui")
    } else if(date.isSame(moment().subtract('1', 'days'), 'day')){
        dateString = trans.t('Hier')
    } else {
        dateString = date.format("ddd DD MMM")
    }

    dateString = trans.t("{{date}} à {{hour}}", {date: dateString, hour: date.format("HH:mm")});

    const onClick = ev => {
        fetchCsrfWrapper(
            `/backend/notification/${obj.pk}/`,
            {'method': 'PATCH', body: {'read': true}},
            {noPendingMessage: true,}
        ).then(() => {
            window.location.replace(obj.link)
        })
    }

    const getBody = () => {
        if(obj.task){
            return <NotificationTask
                obj={obj}
                withCheckbox={['task_assignment', 'task_expiration'].includes(obj.type)}
                onTaskCompleted={props.onTaskCompleted}
            />
        }
        const [safeBody, _] = getNoteContent(obj.body); 
        return (
            <div className="quote">
                <div dangerouslySetInnerHTML={{__html: safeBody}} />
            </div>
        );
    }

    return (
        <div 
            className={classNames("notification-center-item", {"read": obj.read, "with-link": !!obj.link})}
            onClick={onClick}
        >
            <div className="notification-center-item-title">
                {obj.title}
            </div>
            <div className="notification-center-item-date">
                {dateString}
            </div>
            <div className="notification-center-item-body">
                {getBody()}
            </div>
        </div>
    )
}


function _getInitialTab(notifications){
    if(notifications.some(x => x.section === 'mentions' && x.read === false)){
        return 'mentions';
    }

    if(notifications.some(x => x.section === 'actions' && x.read === false)){
        return 'actions';
    }

    return 'mentions';
}


const NotificationsCenter = props => {
    const [opened, setOpened] = useState(false);
    const [activeTab, setActiveTab] = useState(() => _getInitialTab(props.notifications));
    const [notifications, setNotifications] = useState(props.notifications);

    const displayedNotifications = notifications.filter(x => x.section === activeTab);

    function closeOnUnfocus(ev){
        if(!ev.target.closest('#notifications-center') && opened){
            setOpened(false);
        }
    }

    useEffect(() => {
        if(opened) {
            document.addEventListener('click', closeOnUnfocus);   
        } else {
            document.removeEventListener('click', closeOnUnfocus);
        }

        return () => document.removeEventListener('click', closeOnUnfocus)
    }, [opened]);

    const markAllAsRead = () => {
        fetchCsrfWrapper(
            "/backend/notification/mark_as_read/",
            {'method': 'POST', body: {'section': activeTab}},
            {},
        ).then(() => {
            setNotifications(notifications.map(
                x => ({...x, read: x.section === activeTab ? true : x.read})
            ))
        }).catch(
            data => alert("Erreur inconnue")
        )
    }

    const onTaskCompleted = (task) => {
        setNotifications(notifications.map(x => {
            if(x.task && x.task.pk === task.pk){
                x.task = task;
                x.read = true;
            }

            return x;
        }))
    }

    return (
        <div>
            <NotificationsCenterButton
                hasNotification={notifications.some(x => !x.read)}
                onClick={ev => {
                    setOpened(!opened);
                    // prevent propagation otherwise center would auto-close once closeOnUnfocus is set
                    ev.stopPropagation();
                }}
            />

            {opened && <div id="notifications-center">
                <div className="notifications-center-header">
                    <div className="notifications-center-title">
                        {trans.t("Notifications")}
                    </div>
                    <a className="notifications-center-mark-as-read" onClick={markAllAsRead}>
                        {trans.t("Marquer comme lues")}
                    </a>
                </div>
                <div className="notifications-center-filters">
                    <div 
                        className={classNames("notification-center-tab", {'active': activeTab === "mentions"})}
                        onClick={() => setActiveTab('mentions')}
                    >
                        {trans.t("Mentions")}
                        &nbsp;<span>
                            {notifications.filter(x => !x.read && x.section === "mentions").length}
                        </span>
                    </div>

                    <div
                        className={classNames("notification-center-tab", {'active': activeTab === "actions"})}
                        onClick={() => setActiveTab('actions')}
                    >
                        {trans.t("Actions")}
                        &nbsp;<span>
                            {notifications.filter(x => !x.read && x.section === "actions").length}
                        </span>
                    </div>

                    <Tooltip title={trans.t("Configurer les notifications reçues")} className="notification-center-config-icon">
                        <a href={props.configUrl} target="_blank" className="notification-center-config-icon">
                            <i className="fa fa-xl fa-sliders"></i>
                        </a>
                    </Tooltip>
                </div>

                <div className="notification-center-list">
                    {displayedNotifications.length === 0 ? 
                        <div className="notification-center-empty">
                            {trans.t("Vous n'avez pas de notification pour le moment.")}
                        </div>
                    :
                        <React.Fragment>
                            {displayedNotifications.map((notification) => (
                                <NotificationItem
                                    notification={notification}
                                    key={notification.pk}
                                    onTaskCompleted={onTaskCompleted}
                                />
                            ))}
                        </React.Fragment>
                    }
                </div>
            </div>}
        </div>
    );
}

export default NotificationsCenter;
