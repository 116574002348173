import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import moment from 'moment';

import AjaxNotification from './AjaxNotification';
import { get_pretty_dates } from './utils/dates.jsx';
import {
    buildQueryParams,
    fetchWrapper
} from './utils/http.jsx';
import { floatFormat } from './utils/formats.jsx';
import { capitalize, pluralize } from './utils/text.jsx';
import {
    compareArrays, flatten, groupByArray, partition, symmetricDifference,
} from './utils/arrays.jsx';

const hasPerm = (perm, user_perm) => {
    return (
        // The organization has the main permission
        (window['PERMISSIONS']['organization'] || []).indexOf(perm) !== -1
        // the user also have a dedicated permission (or none is given)
        && (!user_perm || hasUserPerm(user_perm))
    )
};
const hasUserPerm = (perm, account_level) => {
    return window['PERMISSIONS']['is_admin'] || (window['PERMISSIONS']['profile'] || []).indexOf(perm) !== -1
};


const ColorBlock = (props) => {
    var color_square = (
        <i className="color-block" style={{'background': props.color.code}}></i>
    );
    if(props.show_label) {
        return <span>{color_square}&nbsp;{props.color.name}</span>;
    }

    return color_square;

}
ColorBlock.defaultProps = {'show_label': true}


/**
 * Generic list manager
 */
const GenericListStoreFactory = function(identifier, sort_key) {
    return {
        _list: [],
        last_update: 0,
        identifier: identifier || 'pk',
        sort_function: null,
        sort_key: sort_key || null,

        // Returns the whole list in a new list reference
        getList:    function() { return (this._list ? this._list.slice() : []); },
        // Returns true if there's at least one item in the list
        hasItems:   function() { return this._list.length > 0; },
        // Returns the number of items in the list
        count:      function() { return (this._list ? this._list.length : 0); },
        // Returns true if the list has been updated by any manners since initialization
        hasChanged: function() { return (this.last_update != 0); },

        // Allow pre-processing of the item being added/update to the list
        processItem: null,
        // Allow initialization of data before any operation is done in the store
        onStoreInit: function () {},
        _initialized: false,

        setList: function (lst, ignore_listeners) {
            if(!this._initialized)
                this.onStoreInit();

            ignore_listeners = ignore_listeners || false;
            if(this.processItem)
                this._list = lst.map(function (item) {
                    return this.processItem(item)
                }.bind(this));
            else
                this._list = lst;

            if(lst) {
                this.sortList();
                if(!ignore_listeners)
                    this.onListChange(!this._initialized ? 'init' : 'set');
            }
            this._initialized = true;
        },

        sortList: function () {
            if(this.sort_function != null) {
                this._list.sort(this.sort_function);
            }
            else if(this.sort_key != null) {
                // If the sort key starts with "-", make it descending
                let key = this.sort_key, desc = false;
                if(key.indexOf('-') == 0) {
                    key = key.substring(1)
                    desc = true;
                }
                this._list.sort(function(a, b) {
                    if(a[key] == b[key])
                        return 0;
                    if(desc)
                        return a[key] < b[key] ? 1 : -1;

                    return a[key] > b[key] ? 1 : -1;
                })
            }
        },

        get: function(pk, identifier) {
            identifier = identifier || this.identifier;
            for(let i = 0, l = this._list.length; i < l; i++) {
                if(this._list[i][identifier] == pk)
                    return this._list[i];
            }
            return null;
        },

        add: function(items) {
            if(!Array.isArray(items))
                items = [items];

            if(this.processItem)
                items = items.map(x => this.processItem(x));

            this._list = this._list.concat(items);

            this.sortList();
            this.onListChange('add');
        },

        update: function(items, force_add) {
            if(!Array.isArray(items)) {
                items = [items];
            }
            force_add = force_add || true;

            var new_list = this._list.slice();
            for(var i = 0, l = items.length; i < l; i++) {
                var item = items[i];
                if(this.processItem)
                    item = this.processItem(item);

                const oldItemIndex = new_list.findIndex(elem => elem[this.identifier] == item[this.identifier]);

                // Do not continue if the element has not been found
                if(oldItemIndex == -1 && !force_add)
                    return;

                if(oldItemIndex != -1) {
                    new_list[oldItemIndex] = item;
                } else if (force_add) {
                    new_list.push(item);
                }
            }
            this._list = new_list;
            this.sortList();
            this.onListChange('update');
        },

        delete: function(pks, key_name) {
            key_name = (typeof key_name === 'undefined') ? this.identifier : key_name;

            if(!Array.isArray(pks)) {
                pks = [pks];
            }

            var new_lst = [];
            var index, l;
            for(index = 0, l = this._list.length; index < l; index++) {
                if(pks.indexOf(this._list[index][key_name]) === -1)
                    new_lst.push(this._list[index]);
            }

            this._list = new_lst;
            this.onListChange('delete'); // No need to sort, when deleting, the remaining stays in order
        },

        _uniq: 1024,
        getUniqueId: function () {
            return this._uniq++;
        },

        _listeners: [],
        addOnListChange: function (listener) {
            this._listeners.push(listener);
        },

        removeListener: function (listener) {
            let idx = this._listeners.indexOf(listener);
            this._listeners.splice(idx, 1);
        },

        onListChange: function(action) {
            if(!this._initialized)
                this.onStoreInit();

            for(var i = 0, l = this._listeners.length; i < l; i++)
                this._listeners[i](this._list, action || null);

            this.last_update += 1;
        }
    };
};


/**
 * React hook handling returning a "ref" node allowing to attach re-ordering
 * mecanism, which takes in parameter a backend URL and parent info so we can
 * refresh the UI and send the new order to the database *
 */
const useListOrdering = function (backendURL, parent_data, onAjaxChange, fixTableWidth=false, handleCSSClass=null, options = {}) {
    const listRef = useRef();

    useEffect(() => {
        if (fixTableWidth) {
            options = Object.assign(options, { helper: function(e, ui) {
                // Keep table width when moving the line
                ui.children().each(function() {
                    $(this).width($(this).width());
                });
                return ui;
            }})
        }

        var dom = $(listRef.current);
        dom.sortable(Object.assign({
            handle: handleCSSClass || ".fa-bars",
            start: function (event, ui) {
                document.body.style.cursor = 'move';
            },
            update: function (event, ui) {
                const newOrder = dom.sortable("toArray", {attribute: "data-id"});
                dom.sortable("cancel");

                if(backendURL) {
                    // Run Ajax update
                    var notif_id = AjaxNotification.show(trans.t('Sauvegarde...'));
                    $.ajax({
                        url: backendURL, method: "PATCH", dataType: "json",
                        data: Object.assign({'new_order': JSON.stringify(newOrder)}, parent_data || {})
                    }).success(
                        (data, status) => onAjaxChange(newOrder)
                    ).error(
                        (data, status) => alert(trans.t("Erreur lors de l'enregistrement"))
                    ).always(
                        () => AjaxNotification.hide(notif_id)
                    );
                }
                else {
                    onAjaxChange(newOrder)
                }
            },
            stop: function (event, ui) {
                document.body.style.cursor = 'default';
            },
        }, options));
    })

    return listRef;
}

/**
 * React hook with same signature than useEffect BUT not executing on initial mount
 */
const useEffectAfterMount = (cb, dependencies) => {
  const mounted = useRef(true);

  useEffect(() => {
    if (!mounted.current) {
      return cb();
    }
    mounted.current = false;
  }, dependencies);
};


const BloodhoundFactory = function (backend_url, additional_opts) {
    var opts = {
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        limit: 10,
        remote: {
            url: backend_url,
            replace: function(url, uriEncodedQuery) {
                let sep = url.indexOf('?') === -1 ? '?' : '&';
                return url + sep + 'term=' + uriEncodedQuery;
            }
        }
    }

    if(additional_opts !== undefined) {
        for (var attrname in additional_opts) {
            if(attrname.indexOf('__') !== -1) {
                var parts = attrname.split('__');
                opts[parts[0]][parts[1]] = additional_opts[attrname];
            }
            else {
                opts[attrname] = additional_opts[attrname];
            }
        }
    }
    var sugg = new Bloodhound(opts);
    sugg.initialize();
    return sugg
};


const PlaceholderLoadingBars = ({nb_lines}) => {
    let lines = [];
    const min = 15, max = 95;
    for(let i = (nb_lines || 6); i > 0; i--) {
        let size = Math.floor(Math.random() * (max-min+1)+min);
        lines.push(
            <div className="placeholder-line-loading" key={i} style={{'width': size + '%'}}></div>
        )
    }
    return (
        <React.Fragment>
            {lines}
        </React.Fragment>
    );
}

const get_color_label = function (
    color,
    label,
    other_props,
    className
) {
    const extra_props = other_props ? other_props : {};
    if(color == null) {
        color = {'code': "#4986E7", 'fore_color': '#fff'}
    }
    if(typeof color == "string") {
        color = {
            'code': color,
            'fore_color': '#fff',
        }
    }

    className = "badge " + (className || "")
    return (
        <span
            {...extra_props}
            className={className}
            style={{'backgroundColor': color.code, 'color': color.fore_color || ''}}
        >{label}</span>
    )
}


/**
 * Make a deep copy of a Javascript object. Please prefer Object.assign
 * when possible.
 */
const deepCopy = function (oldObj) {
    var newObj = oldObj;
    if (oldObj && typeof oldObj === 'object') {
        newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
        for (var i in oldObj) {
            newObj[i] = deepCopy(oldObj[i]);
        }
    }
    return newObj;
}

/**
 * Helper to attach a React root component to a HTML element with initial
 * properties, only if the HTML element is present in the page.
 */
const attach_react_helper = function(component, node_name, args) {
    let node;
    let rendered = false;
    if(node_name instanceof HTMLElement)
        node = node_name
    else
        node = document.getElementById(node_name);

    function onTranslationLoad(translation){
        if(trans.i18n.resolvedLanguage in translation){
            render();
        }
    }

    function render(){
        const root = createRoot(node);
        let computed_args = args ? args(node) : {};
        root.render(React.createElement(component, computed_args));
        trans.i18n.off('loaded', onTranslationLoad);
    }


    if(node !== null) {
        if(!trans.i18n.isInitialized){
            // Wait for the translations to be loaded
            trans.i18n.on('loaded', onTranslationLoad);
        } else {
            render();
        }
    }
}


/**
 * Returns true if the user has an active selection when clicking in the UI.
 * This is useful if you want to allow users to select text in a HTML block
 * that may react to click events.
 */
const hasSelectedTextInBrowser = (threshold) => {
    threshold = threshold || 2;
    let text;
    if (window.getSelection) {
        text = window.getSelection().toString();
    }
    else if (document.selection && document.selection.type != "Control") {
        text = document.selection.createRange().text;
    }

    return text.length > threshold;
}

const getWindowURLParams = () => {
    let urlParams = {};
    // Parse url params, to check if there is a `q` param in it
    const pl     = /\+/g,  // Regex for replacing addition symbol with a space
          search = /([^&=]+)=?([^&]*)/g,
          decode = s => decodeURIComponent(s.replace(pl, " ")),
          query  = window.location.search.substring(1);

    let match;
    while(match = search.exec(query))
        urlParams[decode(match[1])] = decode(match[2]);

    return urlParams;
}

const getModalWrapper = () => {
    /*
     * TODO
     * We should use createRoot here but that would mean changing all
     * usages in one go so we should maybe create a getModalRoot and switch
     * usages over time
     */
     
    // Cleanup empty existing wrapper to avoid polluting the tree if any stayed before
    document.querySelectorAll('div[role="modal-wrapper"]:empty').forEach(e => e.remove());
    // Create a new wrapper and attach to the current DOM
    let wrapper = document.createElement('div');
    wrapper.role = "modal-wrapper";
    document.getElementsByTagName('body')[0].appendChild(wrapper);
    return wrapper;
}


/**
 * Display the children of the componennt surrounded by a <a href> tag if the condition
 * is fulfilled. This is useful when we want to display the same thing to several types
 * of users but only a part of them can access to the detail view
 */
const ConditionalLink = props => {
    const linkProps = Object.assign({}, props);
    delete linkProps['condition'];
    delete linkProps['children'];
    delete linkProps['fallbackTag'];

    if(props.condition && (linkProps.href || linkProps.onClick))
        return <a {...linkProps}>{props.children}</a>

    // When no link is displayed, give the possibility to surround the children by something else
    // (such as strong, em...) to emphasize the value with CSS
    if(props.fallbackTag)
        return React.createElement(props.fallbackTag, {}, props.children);

    return props.children;
}


function isObject(value){
    return (
        typeof value === 'object'
        && !Array.isArray(value)
        && value !== null
    );
}

// Alias for backward-compatibility
const fetchCsrfWrapper = fetchWrapper;


export {
    hasPerm,
    hasUserPerm,
    ColorBlock,
    ConditionalLink,
    AjaxNotification,
    GenericListStoreFactory,
    useEffectAfterMount,
    useListOrdering,
    BloodhoundFactory,
    PlaceholderLoadingBars,
    get_color_label,
    floatFormat,
    deepCopy,
    attach_react_helper,
    buildQueryParams,
    hasSelectedTextInBrowser,
    getWindowURLParams,
    getModalWrapper,
    fetchWrapper,
    fetchCsrfWrapper,
    isObject,

    // Legacy support, please use imports to utils/dates.jsx instead
    get_pretty_dates,

    // Legacy support, please use imports to utils/arrays.jsx instead
    compareArrays,
    flatten,
    groupByArray,
    partition,
    symmetricDifference,

    // Legacy support, please use imports to utils/text.jsx instead
    capitalize,
    pluralize,
}
