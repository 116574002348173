import React from "react";
import { createRoot } from 'react-dom/client';
import { useForm, useFormErrors, getGlobalErrors } from 'orfeo_common/FormUtils'
import Modal from 'orfeo_common/Modal'
import { format, startOfToday } from 'date-fns'
import { SelectInput, CalendarInput } from 'orfeo_common/Widgets.jsx';
import { TextInput, GenericInput} from "orfeo_common/components/forms/Inputs";
import { PrimaryButton, IconButton, Button } from "orfeo_common/components/forms/Buttons";
import { CheckCircle, PushPin, Trash, Lock } from "@phosphor-icons/react";
import { useApi } from 'orfeo_common/hooks'
import { getModalWrapper } from 'orfeo_common/react-base.jsx';



/**
 *
 * @param task the task object used to populate the form fields
 * @param onModalClose the callback to call to close the modal
 * @param onDelete the callback to run when the task is deleted
 * @param onChange the callback to run when the task is updated
 */
const TaskModalForm = props => {
    const {
        task = {title: '', assigned_to: USER_PROFILE, due_date: startOfToday()},
        onClose = () => {},
        onDelete = () => {},
        onChange = () => {}
    } = props
    const [errors, setErrors] = useFormErrors()
    const {fetchCreate, fetchUpdate, fetchDelete} = useApi('/backend/task/')
    const {content_type, object_id, object_title, ...otherFields} = task
    const task_object = {
        ...otherFields,
        associated_object: object_id ? {object_id, content_type, object_title} : null
    }

    const handleSave = () => {
        const fetchData = {
            'title': values.title,
            'due_date': format(values.due_date, 'yyyy-MM-dd'),
            'assigned_to': values.assigned_to,
            'is_private': values.is_private,
            'is_pinned': values.is_pinned,
            'done': values.done,
            'content_type': !values.pk && values.associated_object?.content_type?.pk,
            'object_id': !values.pk && values.associated_object?.object_id
        }

        const fetchMethod = values.pk ? fetchUpdate(values.pk, fetchData) : fetchCreate(fetchData)

        fetchMethod.then(
            data => {
                onChange(data)
                onClose()
            }
        ).catch(data => {
            setErrors(data)
        })
    }

    const {values, onChange: onFieldChange, onKeyUp, onSubmit} = useForm(
        task_object,
        handleSave,
        onClose
    )

    /**
     *  LOGIC
     */

    const handleDeletion = () => {
        if (confirm(trans.t('Voulez-vous supprimer la tâche {{name}} ?', {name: task.title})))
        {
            fetchDelete(values.pk)
            .then(() => onDelete(values.pk))
            .catch(errors => setErrors(errors))
        }
    }

    const getOptionLabel = option => {
        return <>
            {option.object_title}
            {option.object_date &&
                <i className="ms-1">({option.object_date})</i>
            }
        </>
    }

    const getAssociatedObjectsGroups = options => {
        const grouped = options.reduce((groups, option) => {
            const group = option.type_name
            if (!groups[group]) {
                groups[group] = {
                    label: group,
                    options: []
                };
            }
            groups[group].options.push(option);
            return groups;
        }, {});
        return Object.values(grouped);
    }

    /**
     *  JSX
     */

    return <Modal show={true} onHide={() => onClose()}>
        <form onSubmit={onSubmit} onKeyUp={onKeyUp}>
            <Modal.Header>
                {values.pk ? values.title : trans.t('Ajouter une tâche')}
            </Modal.Header>

            <Modal.Body>
                {getGlobalErrors(errors) != '' &&
                    <p className="react-form-errors-intro">
                        {getGlobalErrors(errors)}
                    </p>
                }

                <div className="d-grid gap-2 oo-task-modal-body">
                    <div>
                        <TextInput name="title"
                        label={trans.t('Tâche')}
                        errors={errors}
                        value={values.title}
                        onChange={val => onFieldChange('title', val)}
                        maxLength="300"
                        />
                    </div>

                    <div>
                        <GenericInput label={trans.t('Élément associé')} name='associated_object' errors={errors}>
                            <SelectInput
                            backendURL={"/backend/task/get_associable_objects/"}
                            placeholder={trans.t("Rechercher projet, contact...")}
                            optionValueKey="object_id"
                            disabled={values.pk !== undefined}
                            objectRepresentation={option => getOptionLabel(option)}
                            value={values.associated_object}
                            postProcessJSON={getAssociatedObjectsGroups}
                            onChange={val => {
                                onFieldChange('associated_object', val)
                            }}
                            />
                        </GenericInput>
                    </div>

                    <div>
                        <GenericInput label={trans.t('Échéance')} name='due_date' errors={errors}>
                            <CalendarInput
                            defaultValue={values.due_date}
                            onChange={val => onFieldChange('due_date', val.toDate())}
                            quickButtons={[
                                {colspan: 3, offset: 1, label: trans.t("Demain")},
                                {colspan: 2, offset: 7},
                                {offset: 14},
                                {offset: 30},
                            ]}
                            />
                        </GenericInput>
                    </div>

                    <div>
                        <GenericInput label={trans.t('Assignée à')} name='assigned_to' errors={errors}>
                            <SelectInput
                            backendURL={"/backend/user/?is_active=true&account_type=standard"}
                            placeholder={trans.t("Non attribuée")}
                            objectRepresentation={opt => opt.pk == USER_PROFILE.pk ? 'Moi' : opt.display_name}
                            onChange={val => onFieldChange('assigned_to', val)}
                            value={values.assigned_to}
                            />
                        </GenericInput>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* Edition Quick Actions */}
                {values.pk && <>
                    <IconButton icon={CheckCircle}
                        onClick={() => onFieldChange('done', !values.done)}
                        alt={values.done ? trans.t('Marquer comme non terminée') : trans.t('Marquer comme terminée')}
                        weight={values.done ? 'fill' : undefined}
                        color={values.done ? 'var(--bs-secondary)' : undefined}
                        />
                    <IconButton icon={Trash}
                        onClick={handleDeletion}
                        alt={trans.t('Supprimer')}
                        />
                </>}

                {/* Generic Quick Actions */}
                <IconButton icon={PushPin}
                    onClick={() => onFieldChange('is_pinned', !values.is_pinned)}
                    alt={values.is_pinned ? trans.t('Désépingler') : trans.t('Épingler')}
                    weight={values.is_pinned ? 'fill' : undefined}
                    color={values.is_pinned ? 'var(--bs-secondary)' : undefined}/>

                <IconButton icon={Lock}
                    onClick={() => onFieldChange('is_private', !values.is_private)}
                    alt={values.is_private ? trans.t('Rendre publique') : trans.t('Rendre privée')}
                    weight={values.is_private ? 'fill' : undefined}
                    color={values.is_private ? 'var(--bs-secondary)' : undefined}/>

                <Button onClick={() => onClose()}>{trans.t('Annuler')}</Button>
                <PrimaryButton type="submit">{values.pk ? trans.t('Enregistrer') : trans.t('Ajouter')}</PrimaryButton>
            </Modal.Footer>
        </form>
    </Modal>
}

const openTaskCreationModal = (handleTaskCreation) => {
    const wrapper = createRoot(getModalWrapper());
    wrapper.render(
        <TaskModalForm
        onClose={() => {wrapper.unmount()}}
        onChange={data => handleTaskCreation(data)}
        />
    );
}

const openTaskEditionModal = (task, onDelete, onChange) => {
    const wrapper = createRoot(getModalWrapper());
    wrapper.render(
        <TaskModalForm task={task}
        onClose={() => {wrapper.unmount()}}
        onDelete={data => onDelete(data)}
        onChange={data => onChange(data)}
        />
    );
}



export default TaskModalForm;
export { openTaskCreationModal, openTaskEditionModal}
