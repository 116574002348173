import React, { useEffect, useState } from 'react';

import { ConditionalLink } from 'orfeo_common/react-base.jsx';

const getContactInfoRepresentation = (info, emailCallback) => {
    if(info.type == "phone") {
        return (
            <a key={info.value} href={"tel:"+info.value} rel="external" onClick={ev => ev.stopPropagation()}>
                <i className="fa fa-fw fa-phone"></i>&nbsp;{info.value}
            </a>
        );
    }
    else if(info.type == "mail") {
        return (
            <a key={info.value} href={"mailto:"+info.value} rel="external" onClick={ev => emailCallback ? emailCallback(info.value, ev) : ev.stopPropagation()}>
                {info.no_emailing ?
                    <span className="no-emailing-icon" title={trans.t("N'accepte pas la prospection sur cette adresse")}>
                        <i className="fa fa-fw fa-envelope"></i>
                        <i className="fa fa-times"></i>
                    </span>
                    : <i className="fa fa-fw fa-envelope"></i>
                }&nbsp;{info.value}
            </a>
        );
    }
}


const hasAddress = obj => !!(obj.address1 || obj.address2 || obj.city || obj.zipcode || obj.country);

const getAddressBlock = (obj, prefix, without_country) => {
    if(!obj)
        return '';

    prefix = prefix || '';

    return (
        <div>
            {obj[prefix + 'address1'] && <span>{obj[prefix + 'address1']}<br /></span>}
            {obj[prefix + 'address2'] && <span>{obj[prefix + 'address2']}<br /></span>}
            {obj[prefix + 'zipcode']} {obj[prefix + 'city']} {obj[prefix + 'cedex'] || ''}
            {obj[prefix + 'country'] && !without_country && " ("+obj[prefix + 'country'].name+")"}
        </div>
    );
}

const getInlineAddress = (obj, prefix) => {
    if(!obj)
        return '';

    prefix = prefix || '';

    return (
        <span>
            {obj[prefix + 'address1'] && <span>{obj[prefix + 'address1']} </span>}
            {obj[prefix + 'address2'] && <span>{obj[prefix + 'address2']} </span>}
            {obj[prefix + 'zipcode']} {obj[prefix + 'city']}
        </span>
    )
}

// Returns the profession label according to the gender of the entity
const getProfessionName = entity => {
    if(!entity.profession)
        return null;

    if(
        entity.civility && entity.civility.is_feminine
        && entity.profession && entity.profession.feminine_name
    )
        return entity.profession.feminine_name
    return entity.profession.name
}


/**
 * Display an entity with a link to it (if the current user has rights to access) and load
 * contact infos if needed/possible
 */
const EntityExternalDisplay = ({entity, relatedStructure, relatedContact}) => {
    let [contactInfos, setContactInfos] = useState([])
    useEffect(() => {
        if(entity) {
            let structure, contact;
            if(relatedStructure) {
                structure = relatedStructure.pk;
                contact = entity.pk;
            }
            else if(relatedContact) {
                structure = entity.pk;
                contact = relatedContact.pk;
            }
            if(contact) {
                $.ajax({
                    'url': '/backend/entity/' + contact + '/contact_infos_with_preference/',
                    'method': 'GET',
                    'data': {'structure': structure}
                }).success(
                    (data, status) => {
                        if(data['contact_infos'].length > 0) {
                            let infos = data['contact_infos'];
                            // Sort information by type -> telephone then mail
                            infos.sort((x, y) => x.type == 'phone' ? -1 : 1);
                            setContactInfos(infos);
                        }
                    }
                );
            }
        }
    }, [entity, relatedStructure, relatedContact]);

    if(!entity)
        return null;

    return (
        <div>
            <ConditionalLink
                href={entity.absolute_url}
                condition={ACCOUNT_TYPE == 'standard'}
                onClick={ev => ev.stopPropagation()}
            >
                {entity.name}
            </ConditionalLink>
            {contactInfos &&
            <div className="text-muted" className="contact-infos-list">
                {contactInfos.map(contactInfo => (
                    <div key={contactInfo.pk} className="contact-info">
                        {getContactInfoRepresentation(contactInfo)}
                    </div>
                ))}
            </div>
            }
        </div>
    )
}

export {
    EntityExternalDisplay,
    hasAddress,
    getContactInfoRepresentation,
    getAddressBlock,
    getInlineAddress,
    getProfessionName,
};
