import React, { useContext } from "react";
import { CaretRight, CheckCircle, Lock, PushPin, Trash } from "@phosphor-icons/react";
import UserProfilePhoto from "orfeo/accounts/jsx/utils/UserProfilePhoto";
import { openTaskEditionModal } from 'orfeo/activities/tasks/jsx/board/TaskModalForm'
import { IconButton } from 'orfeo_common/components/forms/Buttons'
import { Checkbox } from "orfeo_common/components/forms/Inputs";
import { SelectionModeContext } from "orfeo_common/components/SelectionMode";
import { getRelativeDate, getLocalizedDate } from "orfeo_common/utils/dates";
import Tooltip from 'orfeo_common/Tooltip'
import { useApi, useHover } from "orfeo_common/hooks";
import { startOfToday } from "date-fns";
import classNames from "classnames";



/**
 * Opens a task edition modal on root level of the DOM
 */


const TaskItem = props => {
    const {
        task,
        onChange = () => {},
        onDelete = () => {},
    } = props

    const {fetchUpdate, fetchDelete} = useApi('/backend/task/')
    const {isGlobalSelectOn, selectionIncludes, addToGlobalSelection, removeFromGlobalSelection} = useContext(SelectionModeContext)
    const [hoverRef, hovering] = useHover()

    const isCreatorAvatarDisplayed = task.created_by && (task.created_by.pk !== task.assigned_to?.pk)
    const relativeDate = getRelativeDate(task.due_date)
    const dateTooltip = getLocalizedDate(task.due_date, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })

    let related_entity = task.content_type?.model === 'contact'
        ? task.structure_person?.structure
        : task.structure_person?.person

    /**
     *  LOGIC
     */

    const toggleTaskCompletion = () => {
        fetchUpdate(task.pk, {done: !task.done})
        .then(response => {
            onChange(response)
        })
    }

    const toggleTaskPinned = () => {
        fetchUpdate(task.pk, {is_pinned: !task.is_pinned})
        .then(response => {
            onChange(response)
        })
    }
    
    const toggleTaskPrivate = () => {
        fetchUpdate(task.pk, {is_private: !task.is_private})
        .then(response => {
            onChange(response)
        })
    }

    const deleteTask = () => {
        if (confirm(trans.t('Voulez-vous supprimer la tâche {{name}} ?', {name: task.title})))
        {
            fetchDelete(task.pk)
            .then(() => {
                onDelete(task.pk)
            })
        }
    }

    const handleRowClick = event => {
        if (event.target.closest('[data-ignore-rowclick="true"]')) {
            return
        }

        if (isGlobalSelectOn) {
            selectionIncludes([task.pk]) ?
                removeFromGlobalSelection([task.pk])
                : addToGlobalSelection([task.pk])
        } else {
            openTaskEditionModal(task, onDelete, onChange)
        }
    }

    const cantBePrivate = task.assigned_to?.pk !== USER_PROFILE.pk && task.created_by?.pk !== USER_PROFILE.pk

    /**
     *  JSX
     */

    return (
        <div className="oo-task-row-container">            
            <div className="oo-taskitem oo-tasks-group-row text-nowrap align-items-center" onClick={ev => handleRowClick(ev)}>
                {/* Main Action */}
                <div ref={hoverRef} data-ignore-rowclick={!isGlobalSelectOn} className="rounded-circle">
                    {isGlobalSelectOn ?
                        <Checkbox checked={selectionIncludes([task.pk])}/>
                    :
                        <IconButton icon={CheckCircle} className='rounded-circle p-0'
                        alt={task.done ? trans.t('Marquer comme non terminée') : trans.t('Marquer comme terminée')}
                        onClick={() => toggleTaskCompletion()}
                        weight={task.done || hovering ? 'fill' : undefined}
                        color={task.done || hovering ? 'var(--bs-primary)' : undefined}
                        />
                    }
                </div>

                {/* Task title */}
                <div className="text-truncate">
                    <span className={classNames("fw-bold overflow-hidden strike-through", {'active': task.done})}>
                        {task.title}
                    </span>
                </div>

                {/* Associated element */}
                <div className="d-flex align-items-center mr-auto">
                    {task.object_id ?
                        <a href={`/tasks/redirect/${task.pk}/`}
                        className={classNames("text-truncate", {disabled: isGlobalSelectOn})}
                        data-ignore-rowclick={!isGlobalSelectOn}
                        aria-disabled={isGlobalSelectOn} tabIndex={isGlobalSelectOn ? -1 : undefined}
                        >
                            {task.object_title}
                        </a>
                    :
                        <span>
                            {"-"}
                        </span>
                    }
                    
                    {related_entity && <>
                        <CaretRight  color="var(--gray-400)"/>
                        <a href={related_entity.absolute_url}
                        className={classNames({disabled: isGlobalSelectOn})}
                        data-ignore-rowclick={!isGlobalSelectOn}
                        aria-disabled={isGlobalSelectOn} tabIndex={isGlobalSelectOn ? -1 : undefined}
                        >
                            {related_entity.name}
                        </a>
                    </>}
                </div>

                {/* User assigned */}
                <div className="d-flex flex-nowrap align-items-center" style={{lineHeight: 0}}>
                    {isCreatorAvatarDisplayed && <>
                        <UserProfilePhoto profile={task.created_by}
                            tooltip={trans.t("Créée le {{date}} par {{author}}", {
                                date: getLocalizedDate(task.creation_date, {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: '2-digit',
                                }),
                                author: task.created_by.display_name
                            })} />
                        <i className="mx-1 my-auto"><CaretRight color="var(--gray-400)"/></i>
                    </>}
                    <UserProfilePhoto profile={task.assigned_to} />
                </div>
                
                {/* Due Date */}
                <div className="overflow-hidden">
                    <Tooltip title={dateTooltip}
                    placement='left'>
                        <span className={classNames({'text-danger': task.due_date < startOfToday()})}>{relativeDate}</span>
                    </Tooltip>
                </div>

                {/* Quick Actions */}
                <div className="text-end" data-ignore-rowclick={!isGlobalSelectOn}>
                    <IconButton icon={Lock}
                        alt={task.is_private ? trans.t("Rendre publique") : trans.t("Rendre privée")}
                        onClick={toggleTaskPrivate}
                        disabled={isGlobalSelectOn || cantBePrivate}
                        weight={task.is_private ? 'fill' : undefined}
                        className={classNames('oo-quickaction', {'selected': task.is_private})}
                        />
                    <IconButton icon={PushPin}
                        alt={task.is_pinned ? trans.t("Désépingler") : trans.t("Épingler")}
                        onClick={toggleTaskPinned}
                        disabled={isGlobalSelectOn}
                        weight={task.is_pinned ? 'fill' : undefined}
                        className={classNames('oo-quickaction', {'selected': task.is_pinned})}
                        />
                    <IconButton icon={Trash}
                        alt={trans.t("Supprimer")}
                        className='oo-quickaction danger'
                        disabled={isGlobalSelectOn}
                        onClick={() => deleteTask()}
                        />
                </div>
            </div>
        </div>
    )
}



export default TaskItem;
