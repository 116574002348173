import React, {useState, useRef, useEffect} from 'react';
import SearchStore from './SearchStore.jsx';

import { get_color_label } from 'orfeo_common/react-base.jsx';

const SearchBar = props => {
    const [term, setTerm] = useState('');
    const [results, setResults] = useState({});
    const [showResult, setShowResult] = useState(false);
    const searchInputRef = useRef(null);

    useEffect(() => {
        SearchStore.addOnChangeListener(data => {
            setResults(data['results']);
        });
    }, []);

    const onClick = ev => {
        const searchInput = searchInputRef.current;
        if(
            document.activeElement !== searchInput
            || ev.target !== searchInput
        ) {
            searchInput.focus();
            ev.preventDefault();
        }

        setShowResult(true);
    }

    const onBlur = ev => {
        setTerm('');
        setShowResult(false);
    }

    const onChange = ev => {
        const newTerm = ev.target.value;
        if(newTerm !== term) {
            setTerm(newTerm);
            if(newTerm.length > 2) {
                SearchStore.search(newTerm);
                setShowResult(true);
            } else {
                setShowResult(false);
            }
        }
    }

    const onSubmit = ev => {
        ev.preventDefault();

        const term = searchInputRef.current.value;
        SearchStore.search(term);
    }

    return (
        <div onMouseDown={onClick}>
            <form className="form-search" onSubmit={onSubmit}>
                <input
                    className="menu-search-input" placeholder={trans.t("Recherche")}
                    autoComplete="off" onBlur={onBlur}
                    ref={searchInputRef} value={term} onChange={onChange}
                />
                <i className="fa fa-search" aria-hidden="true"></i>
            </form>

            {showResult &&
                <SearchInlineResultsList
                    term={term} setTerm={setTerm}
                    results={results}
                />
            }
        </div>
    );
};

const SearchInlineResultsList = props => {
    const results = props.results;
    const [hover, setHover] = useState(null);

    useEffect(() => {
        SearchStore.addOnChangeListener(onListChange);
        window.addEventListener('keydown', onKeyDown);

        return () => {
            SearchStore.removeChangeListener(onListChange);
            window.removeEventListener('keydown', onKeyDown);
            SearchStore.clearResults();
        }
    }, []);

    const onListChange = () => {
        for(let i = 0, l = SUPPORTED_INDEXES.length; i < l; i++) {
            const indexKey = SUPPORTED_INDEXES[i][0];
            if(results[indexKey] && results[indexKey].length > 0) {
                setHover(results[indexKey][0]);
                break;
            }
        }
    }

    const onKeyDown = ev => {
        if(['Escape', 'Enter', 'ArrowUp', 'ArrowDown'].indexOf(ev.key) === -1)
            return;

        if(ev.key === 'Enter' && hover) {
            window.location = hover.absolute_url;
            ev.preventDefault();
            return;
        }

        if(ev.key === 'Escape' && hover) {
            props.setTerm('');
            SearchStore.clearResults();
            return;
        }

        if(!SearchStore.getState().isQueryCompleted)
            return;

        let flatResults = [];
        for(let i = 0, l = SUPPORTED_INDEXES.length; i < l; i++) {
            const indexKey = SUPPORTED_INDEXES[i][0];
            if(results[indexKey])
                flatResults = flatResults.concat(results[indexKey].length);
        }

        if(flatResults.length === 0)
            return;

        let currentIdx = -1;
        if(hover) {
            for(let i = 0, l = flatResults.length; i < l; i++) {
                currentIdx = i;
                break;
            }
        }

        let idx;
        if(currentIdx === -1) {
            idx = (ev.key === 'ArrowDown') ? 0 : flatResults.length - 1;
        } else {
            idx = (currentIdx + (ev.key === 'ArrowDown' ? 1 : -1)) % flatResults.length;
        }
        setHover(flatResults[idx]);
    }

    if(!SearchStore.getState().isQueryCompleted)
        return <div></div>;


    let count = 0;
    for(let idx in results) {
        count += results[idx].length;
    }

    if(count === 0) {
        return (
            <div className="search-inline-results text-center" style={{'padding': '20x'}}>
                <em>{trans.t("Aucun résultat")}</em>
            </div>
        );
    }

    const renderElasticsearchResult = (index, result) => {
        let label;
        if(result.highlight && result.highlight._abstract_title)
            label = result.highlight._abstract_title[0];
        else
            label = result._abstract_title;

        const isSelected = (hover && hover._absolute_url === result._absolute_url);
        return (
            <li className={isSelected ? 'hover' : ''} key={index + result._id}>
                {(index === 'production' && result.field_status) &&
                    <small className="additionnal-info">
                        {get_color_label(
                            result.field_status['color'],
                            result.field_status['name'],
                        )}<br />
                        {result.field_pretty_dates}
                    </small>
                }
                <a href={result._absolute_url} dangerouslySetInnerHTML={{__html: label}}></a>
            </li>
        );
    }

    return (
        <div className="search-inline-results">
            {SUPPORTED_INDEXES.map(entry => {
                let index = entry[0], label = entry[1];
                if(!results[index] || results[index].length === 0)
                    return;

                return (
                    <section key={index}>
                        <h4>
                            <a href={SEARCH_URL + '?q=' + props.term + '&index=' + index}>
                                {label}
                                <span className="float-end">{trans.t("Voir tout")} <i className="fa fa-angle-right"></i></span>
                            </a>
                        </h4>
                        <ul onMouseEnter={() => setHover(null)}>
                            {results[index].map(result => renderElasticsearchResult(index, result))}
                        </ul>
                    </section>
                )
            })}
        </div>
    );
};

export default SearchBar;
