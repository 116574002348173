/**
 * Display messages received by the current user and split into two
 * categories: inbox and messages marked as done.
 * Each message is listed with its metainfo and the user can see more
 * details by clicking on a line, which fires a XHR query to retrieve
 * full details of the regarding e-mail.
 *
 * This view allows user to track incoming conversations and easily
 * see what's new in its e-mail inbox and related to its Orfeo
 * directory.
 */
'use strict';
import React, { useEffect, useState, useRef } from 'react';
var createReactClass = require('create-react-class');
import ReactDOM from 'react-dom';
import moment from 'moment';
import { pluralize } from 'orfeo_common/react-base.jsx';

import { humanShortDate } from './utils.jsx';
import EmailStore from './EmailStore.jsx';
import EmailViewer from './EmailViewer.jsx';


const EmailBodyExpanded = props => {
    const wrapper = useRef();

    useEffect(() => {
        let onEscapeHandler = ev => {
            if(ev.key == 'Escape')
                props.onSwitchView();
        }

        // Capture Escape key to close the e-mail just expanded (also unmount the event properly)
        window.addEventListener('keydown', onEscapeHandler)
        return () => {
            window.removeEventListener('keydown', onEscapeHandler)
        }
    }, []);

    useEffect(() => {
        // If the header is not at the top of the page AND the message content overflows from the screen,
        // scroll the user just before the headers so it eases the reading of the e-mail
        if(props.email_details) {
            let rect = ReactDOM.findDOMNode(wrapper.current).getBoundingClientRect();
            let screenHeight = (window.innerHeight || document.documentElement.clientHeight);
            if((rect.top * 100 / screenHeight) > 20 && rect.bottom > screenHeight) {
                window.scrollTo(0, rect.top - 100);
            }
        }
    }, [props.email_details]);

    return (
        <div className="item-expanded" ref={wrapper}>
            <h4 onClick={props.onSwitchView}>
                {props.email.subject}
                <a role="button" className="float-end mark-as-done" onClick={props.onCompleted}>
                    <i className="fa fa-check"></i>
                </a>
                <small className="float-end text-muted" style={{'marginRight': '15px', 'paddingTop': '5px'}}>
                    {moment(props.email.date).locale('fr').format('LLL')}
                </small>
            </h4>

            {props.email_details ?
                <EmailViewer
                    email={props.email_details}
                    hasEmailAccount={true}
                    onMessageTrashed={() => EmailStore.delete(props.email.message_id)}
                    onHeaderClick={props.onSwitchView}
                />
                :
                <p className="empty-list-placeholder text-muted">
                    <i className="fa fa-spin fa-spinner"></i> {trans.t("Chargement du message")}
                </p>
            }
        </div>
    );
}

const EmailBodyCollapsed = props => {
    const sender = props.email.email_from;
    const entity = props.entity || {};
    const stopPropagation = ev => ev.stopPropagation();
    const msg_date = moment(props.email.date).locale('fr');

    return (
        <div className="item" onClick={props.onSwitchView}>
            <div className="col col-action" onClick={stopPropagation}>
                <input
                    type="checkbox" value={props.email.message_id}
                    checked={props.checked} onChange={props.onChecked}
                />
            </div>
            <div className="col col-date" title={msg_date.format('LLLL')}>
                {humanShortDate(msg_date)}
            </div>
            <div className="col col-expe">
                {props.entity &&
                    <a href={props.entity.absolute_url} onClick={stopPropagation}>{props.entity.name}</a>
                }<br />

                <small className="text-muted" title={sender.email}>{
                    (sender.full_name && sender.full_name != entity.name) ?
                        sender.full_name
                    :
                        sender.email
                }</small>
            </div>
            <div className="col col-subject">
                {props.email.subject}<br />
                <small className="text-muted">{props.email.text_body}</small>
            </div>
            <div className="col col-done-marker">
                <a role="button" className="mark-as-done" onClick={props.onCompleted}>
                    <i className="fa fa-check"></i>
                </a>
            </div>
        </div>
    );
}

/**
 *  Component displaying a single e-mail in the table, expanded or not.
 **/
const EmailLine = props => {
    const wrapper = useRef();
    const [expanded, setExpanded] = useState(false);
    const [fullMessage, setFullMessage] = useState(null);

    // Collapse/Expand the message being displayed
    const onClickExpanded = () => {
        // Run the XHR query if the e-mail has not been expanded before
        if(!expanded && !fullMessage) {
            $.ajax({
                url: "/mails/inbox/details/", method: "GET",
                data: {'message_id': [props.message.message_id]}, dataType: "json",
            }).success(
                (data, status) => setFullMessage(data['message'])
            ).error(
                (data, status) => alert(trans.t('Erreur lors de la récupération du message'))
            );
        }
        setExpanded(!expanded)
    }

    const switchStatus = ev => {
        ev.stopPropagation();

        var msg_id = props.message.message_id;
        var operation = props.parent.state.mode == 'inbox' ? 'mark-done' : 'mark-not-done';

        $.ajax({
            url: `/mails/inbox/${operation}/`, method: "POST",
            data: {'message_id': [msg_id]}, dataType: "json",
        }).success(
            (data, status) => {
            // Run a fade out and actually delete the email from the list a little
            // while after to smooth the exit of the line
            $(ReactDOM.findDOMNode(wrapper.current)).fadeOut(500);
            setTimeout(() => EmailStore.delete(msg_id), 500);

            // Remove the message from the selection
            var idx = props.parent.state.selection.indexOf(msg_id);
            if(idx !== -1) {
                var selection = props.parent.state.selection.slice();
                selection.splice(idx, 1);
                props.parent.setState({'selection': selection});
            }
            // Increase/Decrease the number of message in the inbox
            var total_inbox = props.parent.state.total_inbox + (operation == 'mark-done' ? -1 : 1);
            props.parent.setState({'total_inbox': total_inbox});

        }).error(
            (data, status) => alert(trans.t('Erreur lors de la récupération des messages'))
        );
    }

    // If the user clicked on the line, we expand the line to display the entire message
    // (loaded through a XHR query)
    return (
        <div ref={wrapper}>
            {expanded ?
                <EmailBodyExpanded
                    email={props.message}
                    entity={props.entity}
                    email_details={fullMessage}
                    onSwitchView={onClickExpanded}
                    onCompleted={switchStatus}
                />
            :
                <EmailBodyCollapsed
                    email={props.message}
                    entity={props.entity}
                    onSwitchView={onClickExpanded}
                    onCompleted={switchStatus}
                    checked={props.parent.state.selection.indexOf(props.message.message_id) !== -1}
                    onChecked={props.parent.onMessageSelection}
                />
            }
        </div>
    );
}


var EmailInbox = createReactClass({

    getInitialState: function () {
        return {
            'mode': 'inbox',
            'selection': [],
            'messages': [],
            'request_pending': null,
            'next_offset': 0,
            'has_more': true
        };
    },

    _loadMessages: function (clear_list) {
        var xhr = $.ajax({
            url: "/mails/inbox/fetch/", method: "GET",
            data: {'offset': this.state.next_offset, 'mode': this.state.mode}, dataType: "json",
        }).success(function(data, status, headers, config) {
            if(clear_list)
                EmailStore.setList(data['messages']);
            else
                EmailStore.update(data['messages']);

            this.setState({
                'has_more': data['has_more'],
                'next_offset': data['next_offset'],
                'request_pending': null,
                'total_inbox': data['total_inbox']
            });
        }.bind(this)).error(function(data, status, headers, config) {
            alert(trans.t('Erreur lors de la récupération des messages'));
        });

        this.setState({'request_pending': xhr});
    },

    componentDidMount: function() {
        EmailStore.addOnListChange(
            messages => this.setState({'messages': messages})
        )

        this._loadMessages(true);
        window.addEventListener('scroll', this.handleScroll);
    },

    componentWillUnmount: function() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    handleScroll: function(event) {
        // If there is no more messages to load or a query is already running, skip the check
        if(!this.state.has_more || this.state.request_pending)
            return;

        var loading_position = this.refs.loading_line.getBoundingClientRect();
        var screenHeight = (window.innerHeight || document.documentElement.clientHeight)
        // If the loading message begins to be in the viewport, load additionnal messages
        if(loading_position.top < screenHeight)
            this._loadMessages(false);
    },

    onChangeMode: function () {
        this.setState(
            {'mode': this.state.mode == 'inbox' ? 'done' : 'inbox', 'next_offset': 0, 'selection': []},
            () => this._loadMessages(true) // reload message list as callback
        );
    },

    // Set the selection to all messages if all are not yet selected, otherwise empty int
    onAllSelectionClick: function () {
        if(this.state.selection.length == this.state.messages.length)
            this.setState({'selection': []});
        else
            this.setState({'selection': this.state.messages.map(msg => msg.message_id)});
    },

    // Append the message to the selection if not yet selected. Otherwise, discard the uid from the selection
    onMessageSelection: function (ev) {
        var selection = this.state.selection.slice();
        if(ev.target.checked)
            selection.push(ev.target.value);
        else
            selection.splice(selection.indexOf(ev.target.value), 1);

        this.setState({'selection': selection});
    },

    massSwitchStatus: function (ev) {
        var selection = this.state.selection.slice();
        var operation = this.state.mode == 'inbox' ? 'mark-done' : 'mark-not-done';

        $.ajax({
            url: "/mails/inbox/" + operation +"/", method: "POST",
            data: {'message_id': selection}, dataType: "json",
        }).success(function(data, status, headers, config) {
            EmailStore.delete(selection);
            var delta_inbox = selection.length * (operation == 'mark-done' ? 1 : -1);
            this.setState({'selection': [], 'total_inbox': this.state.total_inbox - delta_inbox});
        }.bind(this)).error(
            (data, status) => alert(trans.t('Erreur lors de la récupération des messages'))
        );
    },

    render: function () {
        var nb_selected = this.state.selection.length;

        return (
        <div className="email-inbox">
            <p className="float-end text-end">
                <span className="text-muted">
                    {nb_selected > 1 ? trans.t("{{nb}} sélectionnés", {nb: nb_selected}) : trans.t("{{nb}} sélectionné", {nb: nb_selected})}
                </span><br />

                <a className={"pointer" + (nb_selected == 0 ? ' text-muted' : '')} onClick={this.massSwitchStatus}>
                    <i className="fa fa-check"></i>&nbsp;
                    {this.state.mode == 'inbox' ? trans.t("Marquer comme terminé") : trans.t("Marquer comme non terminé")}
                </a>
            </p>

            <div className="header-boxes">
                <div className={"box" + (this.state.mode == 'inbox' ? ' selected': '')}
                     onClick={this.onChangeMode}>
                    <i className="fa fa-inbox text-muted"></i> {trans.t("Boite de réception")}
                    <span className="badge bg-primary">{this.state.total_inbox}</span>
                </div>
                <div className={"box" + (this.state.mode == 'done' ? ' selected': '')}
                     onClick={this.onChangeMode}>
                    <i className="fa fa-check text-muted"></i> {trans.t("Terminé")}
                </div>
            </div>

            <div className={"inbox-list mode-" + this.state.mode}>
                <div className="item-header">
                    <div className="col col-action">
                        <input type="checkbox" onChange={this.onAllSelectionClick}
                               checked={this.state.messages.length > 0 && nb_selected == this.state.messages.length} />
                    </div>
                    <div className="col col-date">{trans.t("Date")}</div>
                    <div className="col col-expe">{trans.t("Expéditeur")}</div>
                    <div className="col col-subject">{trans.t("Sujet")}</div>
                    <div className="col col-done-marker"></div>
                </div>

                {(this.state.messages.length > 0 || this.state.request_pending != null) ?
                    this.state.messages.map(
                        msg_info => (
                            <EmailLine
                                message={msg_info['message']}
                                entity={msg_info['entity']}
                                key={msg_info['message_id'] + '/' + this.state.selection.indexOf(msg_info['message_id'])}
                                parent={this}
                            />
                        )
                    )
                    :

                    <div className="empty-line">
                        {this.state.mode == 'inbox' ?
                            <p className="empty-list-placeholder">
                                <i className="fa fa-inbox"></i> {trans.t("Aucun e-mail à traiter")}
                            </p>
                        :
                            <p className="empty-list-placeholder">
                                <i className="fa fa-check"></i> {trans.t("Aucun e-mail marqué comme traité pour le moment")}
                            </p>
                        }
                    </div>
                }

                {this.state.has_more &&
                <div className="empty-line empty-list-placeholder" ref="loading_line">
                    <i className="fa fa-spin fa-spinner"></i> {trans.t("Chargement de plus d'e-mails")}
                </div>}
            </div>
        </div>
        );
    }
});

export default EmailInbox;
