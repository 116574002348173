import React, { useEffect, useState } from 'react';

import {ColorBlock} from 'orfeo_common/react-base.jsx';

const STATUS_CSS_CLASSES = {
    'enqueuded': 'info',
    'running': 'success',
    'ok': 'success',
    'error': 'danger',
    'paused': 'default'
}

const IcalListHeader = props => {
    return (
        <thead>
            <tr>
                <th>{trans.t("Nom")}</th>
                <th>{trans.t("Notes")}</th>
                <th>{trans.t("Actions")}</th>
            </tr>
        </thead>
    )
}

const IcalLine = props => {
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 5000)
        }
    }, [copied]);

    const copyLink = ev => {
        ev.stopPropagation();
        navigator.clipboard.writeText(props.ical.ical_link);
        setCopied(true);
    }

    let actions = [
        copied ? trans.t("Lien copié !") : <a className="action-link" role="button" onClick={copyLink}>{trans.t("Copier le lien")}</a>,
        <a className="action-link" href={props.ical.edit_url}>{trans.t("Modifier")}</a>,
        <a className="action-link" href={props.ical.revoke_url}>{trans.t("Révoquer")}</a>
    ]

    return (
        <tr key={'ical-' + props.ical.pk} data-id={props.ical.pk}>
            <td>
                {props.ical.color && <ColorBlock color={props.ical.color} show_label={false} />}
                {props.ical.title}
            </td>
            <td>{props.ical.notes}</td>
            <td>
                {actions.reduce((acc, elem) => [acc, ' | ', elem])}
            </td>
        </tr>
    );
}

const IcalListBody = props => {
    return (
        <tbody ref={props.listRef}>
        {props.ical_links.map(ical => (
            <IcalLine key={`ical-${ical.pk}`} ical={ical}/>
        ))}
        </tbody>
    )
}

const IcalListManager = props => {
    return (
        <div>
            <table className="table">
            <IcalListHeader type="link"/>
            <IcalListBody ical_links={props.ical_links} />
            </table>
            <p>
                <a href={props.ical_add_url} className="action-link">
                    {trans.t("Créer un lien de partage")}
                </a>
            </p>
        </div>
    );
}


export default IcalListManager;
