/**
 * State of the search app. Since there is no SearchManager, we centralize the notion
 * of state here. The `onChange` event should be call everytime there is a state modification.
 * The `onChange` function is indeed overloads by the behavior of the ReactList component
 * to allow update every time this state is updated.
**/
var SearchStore = {
    _state: {
        'results': {},
        'term': '',
        'isQueryCompleted': false,
    },
    /**
     * Functional part of the search.
     */
    search: function (query, limit) {
        limit = limit || 5;

        // Run the search and update context
        var results = {};
        this._state['term'] = query;
        $.ajax({
            url: '/search/elasticsearch/',
            data: {term: query, limit}
        }).success((data, status) => {
            this.updateResults(data.results);
        }).error((data, status) => {
            console.error(data)
        })
        
    },

    getState: function () {
        return this._state;
    },

    clearResults: function () {
        this._state['isQueryCompleted'] = false;
        this._state['term'] = '';
        this._state['results'] = {};
        this.onChange();
    },

    updateResults: function(results) {
        this._state['results'] = results;
        this._state['isQueryCompleted'] = true;
        this.onChange();
    },

    _listeners: [],
    addOnChangeListener: function (listener) {
        this._listeners.push(listener);
    },

    removeChangeListener: function(listener) {
        this._listeners.splice(this._listeners.indexOf(listener), 1);
    },

    onChange: function() {
        for(var i = 0, l = this._listeners.length; i < l; i++)
            this._listeners[i](this._state);
    }
}


export default SearchStore;
