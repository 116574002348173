/**
 * This file is used to host any custom made React hooks.
 * It is intended to be split into several files if its content becomes too large
 * Please provide jsdoc to your components.
 */
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { fetchWrapper } from 'orfeo_common/utils/http'


/**
 * Handles toggling of a Boolean true/false value
 *
 * Returns the current state, a function to toggle the state,
 * and a function to set the state manually
 *
 * @param {any} initValue the initial value of your boolean
 */
const useToggle = (initValue = true) => {
    const [value, setValue] = useState(!!initValue)

    const toggle = useCallback(() => {
        setValue(x => !x)
    }, [])

    return [value, toggle, setValue]
}

/**
 * Executes a callback when the component is unmounted
 *
 * @param {*} callback the callback to execute when your component is unmounted
 */
const useUnmount = (callback) => {
    const callbackRef = useRef(callback)

    callbackRef.current = callback

    useEffect(
        () => () => {callbackRef.current()}
    , [])
}

/**
 * Returns a debounced version of a callback function
 *
 * @param {*} callback the callback function to be debounced
 * @param {number} delay the debounce delay in milliseconds you want to set your callback to
 */
const useDebounce = (callback, delay = 500) => {
    const handlerRef = useRef()
    const debouncedCallback = useCallback((...args) => {
        if (handlerRef.current) {
            clearTimeout(handlerRef.current)
        }
        handlerRef.current = setTimeout(() => {
            callback(...args)
        }, delay)
    }, [callback, delay])

    useUnmount(() => {
        if (handlerRef.current) {
            clearTimeout(handlerRef.current)
        }
    })

    return debouncedCallback
}

/**
 * Manages a list state, it returns the state and a few control functions
 *
 * @param {[*]} defaultList the initial list state
 */
const useList = (defaultList = []) => {
    const [list, setList] = useState(defaultList)

    const set = newList => {
        setList([...newList])
    }

    const push = item => {
        setList([...list, item])
    }

    const removeAt = index => {
        setList([
            ...list.slice(0, index),
            ...list.slice(index+1)
        ])
    }

    const insertAt = (index, item) => {
        setList([
            ...list.slice(0, index),
            item,
            ...list.slice(index)
        ])
    }

    const updateAt = (index, item) => {
        setList([
            ...list.slice(0, index),
            item,
            ...list.slice(index+1)
        ])
    }

    const clear = () => {
        setList([])
    }

    return [list, {
        set,
        push,
        removeAt,
        insertAt,
        updateAt,
        clear
    }]
}

/**
 * Executes a callback whenever a click is made outside of a DOM element
 *
 * @param {*} callback the callback to execute
 * @returns a ref to identify the DOM element
 */
const useClickOutside = (callback) => {
    const ref = useRef()

    useEffect(() => {
        const handleClick = ev => {
            if (ref.current && !ref.current.contains(ev.target)) {
                callback()
            }
        }

        document.addEventListener('click', handleClick, true)
        return () => document.removeEventListener('click', handleClick)
    }, [callback])

    return ref
}

/**
 * Hook that maps useLayoutEffect in the browser and useEffect is server-side rendering
 */
const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

/**
 * Attaches an event listener to a DOM element or the window
 * @param {*} eventName name of the event to listen for
 * @param {*} handler handler function when the event is fired
 * @param {*} element DOM element to attach the listener to (optional)
 * @param {*} options options object for the event listener (optional)
 */
const useEventListener = (eventName, handler, element, options) => {
    const savedHandler = useRef(handler)

    useIsomorphicLayoutEffect(() => {
        savedHandler.current = handler
    }, [handler])

    useEffect(() => {
        const targetElement = element?.current ?? window

        if (!(targetElement && targetElement.addEventListener)) return

        const listener = event => {
            savedHandler.current(event)
        }

        targetElement.addEventListener(eventName, listener, options)

        return () => {
            targetElement.removeEventListener(eventName, listener, options)
        }
    }, [eventName, element, options])
}

/**
 * Returns a ref to identify a DOM element and a value indicating if the user
 * is hovering it
 * @returns {[ref, boolean]} A ref to identify an element and whether it's hovered or not
 */
const useHover = () => {
    const ref = useRef()
    const [hovering, setHovering] = useState(false)

    const handleMouseEnter = () => {
        setHovering(true)
    }
    const handleMouseLeave = () => {
        setHovering(false)
    }

    useEventListener('mouseenter', handleMouseEnter, ref)
    useEventListener('mouseleave', handleMouseLeave, ref)

    return [ref, hovering]
}

const useApi = (url) => {
    url = url.endsWith('/') ? url : `${url}/`

    const fetchCreate = (data) => {
        return fetchWrapper(
            url,
            {method: 'POST', body: data},
            {noPendingMessage: true}
        )
    }

    const fetchRead = (params) => {
        return fetchWrapper(
            url,
            {method: 'GET', body: params},
            {noPendingMessage: true}
        )
    }

    const fetchBulkUpdate = (pks, data) => {
        return fetchWrapper(
            `${url}bulk_update/`,
            {method: 'PATCH', body: { pks: pks.join(','), ...data}},
            {noPendingMessage: true}
        )
    }

    const fetchUpdate = (pks, data) => {
        if (Array.isArray(pks)) {
            return fetchBulkUpdate(pks, data)
        }

        return fetchWrapper(
            `${url}${pks}/`,
            {method: 'PATCH', body: data},
            {noPendingMessage: true}
        )
    }

    const fetchBulkDelete = (pks) => {
        return fetchWrapper(
            `${url}bulk_deletion/`,
            {method: 'DELETE', body: {pks: pks.join(',')}}
        )
    }

    const fetchDelete = (pks) => {
        if (Array.isArray(pks)) {
            return fetchBulkDelete(pks)
        }

        return fetchWrapper(
            `${url}${pks}/`,
            {method: 'DELETE'}
        )
    }

    return {
        fetchCreate,
        fetchRead,
        fetchUpdate,
        fetchDelete
    }
}


const useSessionState = (sessionKey, defaultValue, isUserRestricted = true) => {
    if (isUserRestricted) {
        sessionKey += '_' + USER_PROFILE.pk
    }

    function getInitialState(){
        const sessionValue = window.sessionStorage.getItem(sessionKey);

        return sessionValue ? JSON.parse(sessionValue) : defaultValue;
    }

    const [state, _setState] = useState(getInitialState);

    const setState = newState => {
        _setState(newState);
        window.sessionStorage.setItem(sessionKey, JSON.stringify(newState));
    }

    return [state, setState];
}

/**
 * Implementation of useEffect that avoid launching the callback
 * on initial render
 */
const useEffectRendered = (fn, dependencies) => {
    const isMountedRef = useRef(false);

    useEffect(() => {
        if(isMountedRef.current){
            return fn();
        }
        isMountedRef.current = true;
    }, dependencies)
}


export {
    useToggle,
    useUnmount,
    useDebounce,
    useList,
    useClickOutside,
    useIsomorphicLayoutEffect,
    useHover,
    useEventListener,
    useApi,
    useSessionState,
    useEffectRendered
}
