import React, { useState, useContext } from 'react'
import TaskItem from "./TaskItem";
import { Checkbox } from 'orfeo_common/components/forms/Inputs'
import CollapsibleMenu from 'orfeo_common/components/CollapsibleMenu';
import { SelectionModeContext } from 'orfeo_common/components/SelectionMode';

const INITIAL_SHOWN_TASKS_COUNT = 6



const TaskGroupList = props => {
    const {
        name = '',
        tasks = [],
        onTasksChange = () => {},
        onTasksDelete = () => {},
    } = props
    
    const [selectedTaskIds, setSelectedTaskIds] = useState([])
    const [showedMore, setShowedMore] = useState(false)
    const {
        isGlobalSelectOn,
        addToGlobalSelection,
        removeFromGlobalSelection,
        selectionIncludes
    } = useContext(SelectionModeContext);
    
    const badgeText = `${tasks.length < 100 ? tasks.length : '99+'}`
    const tasksShown = tasks.length > INITIAL_SHOWN_TASKS_COUNT && !showedMore ? tasks.slice(0, INITIAL_SHOWN_TASKS_COUNT) : tasks

    /**
     *  LOGIC
     */

    const handleBulkTaskSelect = (checked) => {
        if (checked) {
            addToGlobalSelection(tasks.map(task => task.pk))
        } else {
            removeFromGlobalSelection(tasks.map(task => task.pk))
        }
    }

    const handleTaskUpdate = task => {
        onTasksChange([task])
    }

    const handleTaskDeletion = taskId => {
        setSelectedTaskIds(selectedTaskIds.filter(id => id !== taskId))
        onTasksDelete([taskId])
    }

    const handleMenuToggling = toggled => {
        if (!toggled) setShowedMore(false)
    }

    /**
     *  JSX
     */

    return <CollapsibleMenu key={tasks.length} isOpen={true} onToggled={toggled => handleMenuToggling(toggled)}>
        <CollapsibleMenu.Header>
            <div>
                <h5 className='d-inline m-0'>
                    {name}
                </h5>
                <span className='badge rounded align-bottom bg-light text-dark ms-2'>
                    {badgeText}
                </span>
            </div>
        </CollapsibleMenu.Header>

        <CollapsibleMenu.Body>
            <div className='oo-task-list-container'>
                <div className='oo-task-group-header'>
                    <header className='oo-tasks-group-row'>
                        <div>
                            {isGlobalSelectOn &&
                                <Checkbox
                                checked={selectionIncludes(tasks.map(task => task.pk))} 
                                onChecked={checked => handleBulkTaskSelect(checked)}
                                />
                            }
                        </div>
                        <span>{trans.t('Tâche')}</span>
                        <span>{trans.t('Élément associé')}</span>
                        <span>{trans.t('Assignée à')}</span>
                        <span>{trans.t('Échéance')}</span>
                    </header>
                </div>
                
                {tasksShown.map(task =>
                    <div key={task.pk}>
                        <TaskItem task={task}
                            onChange={task => handleTaskUpdate(task)}
                            onDelete={() => handleTaskDeletion(task.pk)}
                            />
                    </div>
                )}

                <div className='mt-3'>
                    {tasksShown.length < tasks.length &&
                        <button className='btn btn-sm border-0 fw-bold btn-outline-primary' onClick={() => setShowedMore(true)}>
                            {trans.t('Afficher plus')}
                        </button>
                    }
                    {tasksShown.length == tasks.length && tasks.length > INITIAL_SHOWN_TASKS_COUNT &&
                        <button className='btn btn-sm border-0 fw-bold btn-outline-primary' onClick={() => setShowedMore(false)}>
                            {trans.t('Afficher moins')}
                        </button>
                    }
                </div>
            </div>
        </CollapsibleMenu.Body>
    </CollapsibleMenu>
}

export default TaskGroupList;
