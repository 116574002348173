import React, { useReducer, useState } from 'react';

import {ColorPicker} from 'orfeo_common/Fields';
import { ProxyRestrictionField } from 'orfeo_common/Widgets.jsx';


function reducer(state, action) {
    return Object.assign(
        {},
        state,
        {[action.field]: action.value}
    );
}


const EntityTagForm = (props) => {
    const [state, dispatch] = useReducer(reducer, props.obj);
    return (
        <div className="form-horizontal">
            {!!state.pk ?
                <h2>{trans.t("Édition du mot-clé « {{tag}} »", {tag: state.name})}</h2>
            :
                <h2>{trans.t("Création d'un mot-clé")}</h2>
            }

            <div className="row mb-2">
                <label className="col-3 control-label">{trans.t("Nom")}</label>
                <div className="col-9">
                    <input
                        type="text" name="name" className="form-control" autoFocus required
                        value={state.name || ''} onChange={ev => dispatch({'field': 'name', 'value': ev.target.value})}
                    />
                </div>
            </div>

            <div className="row mb-2">
                <label className="col-3 control-label">{trans.t("Couleur")}</label>
                <div className="col-9">
                    <input type="hidden" name="color" value={state.color ? state.color.pk : null} />
                    <ColorPicker
                        value={state.color}
                        required
                        onChange={color => dispatch({'field': 'color', 'value': color})}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <label className="col-3 control-label">{trans.t("Affiché dans les listes")}</label>
                <div className="col-9">
                    <div className="form-switch">
                        <input
                            type="checkbox"
                            name="shown_in_list"
                            className="form-check-input"
                            checked={state.shown_in_list}
                            onChange={ev => dispatch({'field': 'shown_in_list', 'value': ev.target.checked})}
                        />
                    </div>
                </div>
            </div>
            <ProxyRestrictionField
                proxy_restriction={state.proxy_restriction}
                proxy_choices={PROXY_CHOICES}
                onChange={lst => dispatch({'field': 'proxy_restriction', 'value': lst})}
            />
        </div>
    );
}

export default EntityTagForm;
