'use strict';

import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import { fetchCsrfWrapper, getModalWrapper } from 'orfeo_common/react-base.jsx';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import Modal from 'orfeo_common/Modal.jsx';

const AddRulesetForm = props => {
    const handleSave = () => {
        fetchCsrfWrapper(
            '/backend/ruleset/',
            {method: 'POST', body: values}
        ).then(data => {
            window.location = data.absolute_url
        })
        .catch(errors => setErrors(errors))
    }
    const [errors, setErrors] = useState({});
    const {values, onChange, onKeyUp, onSubmit} = FormUtils.useForm(
        {},
        handleSave,
        props.onClose
    );
    return <Modal show>
        <form onSubmit={onSubmit} onKeyUp={onKeyUp}>
            <Modal.Header>Ajouter un accord</Modal.Header>
            <Modal.Body>
                {Object.keys(errors).length > 0 &&
                    <p className="react-form-errors-intro">
                        {trans.t("Erreur lors de la sauvegarde")}<br />{FormUtils.getGlobalErrors(errors)}
                    </p>
                }
                <div className={"row mb-1" + FormUtils.getCSSError(errors, 'title')}>
                    <p className="col-xs-4">{trans.t("Nom")}</p>
                    <div className="form-group col-xs-8">
                        <input
                            type="text" className="form-control input-sm" maxLength="255"
                            name="name" value={values.name || ''} onChange={onChange}
                        />
                        <FormUtils.ErrorText field='name' errors={errors} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <a className="btn btn-outline-secondary" onClick={() => props.onClose()}>{trans.t("Annuler")}</a>
                <button className="btn btn-primary" role="submit">{trans.t("Créer")}</button>
            </Modal.Footer>
        </form>
    </Modal>
}

const onOpenRulesetForm = () => {
    let wrapper = getModalWrapper();
    const closeModal = () => {};
    ReactDOM.render(
        <AddRulesetForm onClose={closeModal} />,
        wrapper
    );
}

export default onOpenRulesetForm